import { userConstants } from '../_constants'
import { userService } from '../_services'
import { esigningActions, tenantActions } from './'

export const userActions = {
  getUser,
  setUser,
  updateUser,
}

function getUser(sessionToken) {
  return (dispatch) => {
    dispatch(request())
    return userService
      .getUser(sessionToken)
      .then(async (data) => {
        dispatch(success(data.user))
        if (!data.user.reset_password_required) {
          await dispatch(esigningActions.getEsigningRequests(data.user.id, true))
        }
        dispatch(tenantActions.setTenant(data.tenant))
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: userConstants.GET_USER_REQUEST }
  }
  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.GET_USER_FAILURE, error }
  }
}

function setUser(user) {
  return async (dispatch) => {
    dispatch(success(user))
    if (!user.reset_password_required) {
      await dispatch(esigningActions.getEsigningRequests(user.id, true))
    }
  }

  function success(user) {
    return { type: userConstants.GET_USER_SUCCESS, user }
  }
}

function updateUser(attr, val, userId) {
  return (dispatch) => {
    dispatch(request(attr, val))
    return userService
      .updateUser(attr, val, userId)
      .then((user) => dispatch(success(user)))
      .catch((error) => dispatch(failure(error)))
  }

  function request() {
    return { type: userConstants.UPDATE_USER_REQUEST, attr, val }
  }
  function success(user) {
    return { type: userConstants.UPDATE_USER_SUCCESS, user }
  }
  function failure(error) {
    return { type: userConstants.UPDATE_USER_FAILURE, error }
  }
}
