import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
const LegacyInvoiceSchedules = lazy(() => import('legacy/_views/LenderViews/NewCollateralPage'));
const InvoiceSchedules = () => {
    /*
     * Should eventually be replaced by new Xen 1.0 Invoice Schedules table page
     * we pass location to get around redux location store until we can remove the legacy page
     */
    return _jsx(LegacyInvoiceSchedules, { location: { pathname: '' } });
};
export default InvoiceSchedules;
