import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled, RestrictedAccess } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
import { useConfigurationContext } from 'xen/providers';
const Prospects = lazy(() => import('../pages/prospects'));
const Prospect = lazy(() => import('../pages/prospect'));
export const PROSPECTS_PATHS = {
    root: '/prospects',
    prospect: '/prospects/:id',
};
export const ProspectsRoutes = () => {
    const { tenant: { originate: { enabled: originateEnabled }, }, } = useConfigurationContext();
    const { xenProspectDetails } = useFeatureFlags();
    return [
        _jsx(Route, { exact: true, path: PROSPECTS_PATHS.root, children: () => {
                if (!originateEnabled)
                    return _jsx(FeatureNotEnabled, { feature: "Originate" });
                return (_jsx(RestrictedAccess, { userTypes: ['lender'], children: _jsx(Prospects, {}) }));
            } }, PROSPECTS_PATHS.root),
        _jsx(Route, { exact: true, path: PROSPECTS_PATHS.prospect, children: () => {
                if (!xenProspectDetails)
                    return _jsx(FeatureNotEnabled, { feature: "Xen Prospect Details" });
                return (_jsx(RestrictedAccess, { userTypes: ['client', 'lender', 'prospect'], children: _jsx(Prospect, {}) }));
            } }, PROSPECTS_PATHS.prospect),
    ];
};
