import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import download from 'downloadjs'

//Components
import { ReportCustomizer } from '../../../../../_components/modals/reports/report_customizer'
import { DualInput } from '../../../../../_components/DualInput/DualInput'
import ClientSideReports from '../../../../../_components/_reports'
import { ReportBlockerModal } from '../../../../../_components/modals/reports/report_blocker/ReportBlockerModal'

// Hooks
import { useFlags } from '../../../../../_reduxHooks/flags.hook'
import { useModal } from '../../../../../_reduxHooks/modal.hook'
import { useNotifications } from '../../../../../_reduxHooks/notifications.hook'
import { useUser } from '../../../../../_reduxHooks/user.hook'

// Helpers
import { isFeatureEnabled, isProductEnabled } from '../../../../../_helpers/flags'
import { history } from '../../../../../_helpers'

// Services
import { getReport, getReports, getABLReports, getReportByPath } from '../../../../../_services'
import { LENDER_ROUTES } from '../../../../../_constants/routes'
import { getReportFileName } from '../../../../../_helpers/reports-helper'
import { useDispatch } from 'react-redux'
import { reportsUpdateActions } from '../../../../../_actions'

const S_ReportSelector = styled.div`
  select {
    outline: none;
  }
`

const initialReports = [
  {
    name: 'AR Aging Report',
    type: 'ar_aging',
  },
  {
    name: 'Applied Payments Report',
    type: 'applied_payments',
  },
  {
    name: 'Invoice Chargeback Report',
    type: 'invoice_chargebacks',
  },
]

export const ReportSelector = ({ business, reportSet = 'factoring' }) => {
  const [reports, setReports] = useState(initialReports)
  const [isLoading, setIsLoading] = useState(true)
  const [downloadingReport, setDownloadingReport] = useState(false)
  const [selectedReport, setSelectedReport] = useState('')
  const { featureFlags } = useFlags()
  const { openModal, closeModal } = useModal()
  const { showNotification } = useNotifications()
  const { user } = useUser()
  const dispatch = useDispatch()

  const generatedReportsFeature = isFeatureEnabled('generated_reports', featureFlags)
  const pathProvided = isFeatureEnabled('transactions_v2', featureFlags)

  useEffect(() => {
    if (selectedReport && selectedReport === 'redirect') {
      history.push(LENDER_ROUTES.generated_reports)
    }
  }, [selectedReport])

  const showBlockerModal = () => {
    openModal(ReportBlockerModal, {}, { modalTitle: 'Your Report is Being Generated', titleAlign: 'center' })
  }

  const downloadReport = (reportName = 'Generate Custom Report', isLedger, path, params, report) => {
    // Set as loading
    if (!params || !params.length) {
      setDownloadingReport(true)
    }

    const handleReportSuccess = (resp) => {
      // If no content
      if (resp.status === 204) {
        showNotification({
          type: `warning`,
          message: 'No data available to generate this report',
        })
        closeModal()
      } else {
        download(resp.data, getReportFileName(Date.now(), reportName, report.mime_type))
        closeModal()
      }
    }

    const handleReportFinally = () => {
      setDownloadingReport(false)
    }

    const handleDownloadReport = (
      downloadFn,
      setLoadingReport = () => {},
      setReportError = () => {},
      setErrorMessage = () => {}
    ) => {
      if (!generatedReportsFeature) {
        downloadFn
          .then(handleReportSuccess)
          .catch((e) => {
            setReportError(true)
            setErrorMessage(e)
          })
          .finally(() => {
            setLoadingReport(false)
            handleReportFinally()
          })
        return
      }

      const timeoutFlag = 'timeout'
      const duration = 15000

      let waitForReport = new Promise((resolve) => {
        let timeout = setTimeout(() => {
          clearTimeout(timeout)
          resolve(timeoutFlag)
        }, duration)
      })

      let race = Promise.race([waitForReport, downloadFn])

      race
        .then((e) => {
          if (e === timeoutFlag) {
            setLoadingReport(false)
            dispatch(reportsUpdateActions.setReportsShouldNotify(true))
            showBlockerModal()
          } else {
            return handleReportSuccess(e)
          }
        })
        .catch((e) => {
          setReportError(true)
          setErrorMessage(e)
        })
        .finally(() => {
          setLoadingReport(false)
          handleReportFinally()
        })
    }

    if (pathProvided) {
      if (params.length && (!business || (business.id && params.filter((p) => p.name != 'business_id').length))) {
        if (business && user.user_type != 'lender') {
          openModal(
            ReportCustomizer,
            {
              params: params.filter((p) => p.name != 'business_id'),
              path,
              handleReportSuccess,
              handleReportFinally,
              report,
              reportSet,
            },
            { modalTitle: reportName }
          )
        } else {
          openModal(
            ReportCustomizer,
            {
              params,
              path,
              handleReportSuccess,
              handleReportFinally,
              handleDownloadReport,
              report,
              reportSet,
            },
            { modalTitle: reportName }
          )
        }
      } else {
        if (!params.length) {
          handleDownloadReport(getReportByPath(path))
        } else {
          openModal(
            ReportCustomizer,
            {
              params,
              path,
              handleReportSuccess,
              handleReportFinally,
              handleDownloadReport,
              reportName,
              report,
              reportSet,
            },
            { modalTitle: reportName }
          )
        }
      }
    } else {
      handleDownloadReport(getReport(reportName, business, isLedger))
    }
  }

  useEffect(() => {
    if (!isLoading) return
    let fetchReports = reportSet == 'factoring' ? getReports : getABLReports
    fetchReports().then((r) => {
      // If flag not set will exclude the non standard version. Only for Marco Finance.
      let nfeToExclude = !isFeatureEnabled('use_non_standard_nfe', featureFlags)
        ? 'Daily Funds Employed'
        : 'Daily Net Funds Employed'

      const showAllEmployedReports = isFeatureEnabled('show_all_funds_employed_reports', featureFlags)
      if (showAllEmployedReports) {
        nfeToExclude = null
      }
      let newReportState = r.filter((report) => report.name !== nfeToExclude)
      let enabledClientSideReports = ClientSideReports.filter((report) => {
        let featureEnabled = false
        if (!report.requiresFeature) {
          featureEnabled = true
        } else {
          featureEnabled = isFeatureEnabled(report.requiresFeature, featureFlags)
        }
        let productEnabled = isProductEnabled(report.requiresProduct, featureFlags)
        return productEnabled && featureEnabled
      })

      newReportState = newReportState.concat(enabledClientSideReports)
      if (business) {
        newReportState = newReportState.filter((report) => report.scopes.includes('business'))
      }
      setReports(
        newReportState
          .map((report) => {
            if (business) {
              let bParamI = report.params.findIndex((p) => p.name == 'business_id')
              if (bParamI >= 0) {
                report.params[bParamI].value = business.id
              }
            }
            return report
          })
          .sort((a, b) => a.name.localeCompare(b.name))
      )
      setIsLoading(false)
    })
  }, [isLoading])

  if (!reports || !reports.length) {
    return null
  }

  const linkToReports = { display: 'Recently Generated Reports', value: 'redirect' }

  return (
    <S_ReportSelector>
      <DualInput
        left={{
          type: 'input',
          props: {
            type: 'select',
            disabled: downloadingReport,
            onChange: (val) => setSelectedReport(val),
            defaultValue: '',
            options: [
              { display: 'Select Report', value: '' },
              ...(generatedReportsFeature ? [linkToReports] : []),
            ].concat(reports.map((report, index) => ({ ...report, display: report.name, value: index }))),
          },
        }}
        primary="right"
        right={{
          type: 'button',
          props: {
            text: 'Generate Report',
            disabled: downloadingReport,
            onClick: () => {
              if (selectedReport === '') return

              pathProvided
                ? downloadReport(
                    reports[selectedReport].name,
                    reports[selectedReport].isLedger,
                    reports[selectedReport].path,
                    reports[selectedReport].params,
                    reports[selectedReport]
                  )
                : downloadReport(reports[selectedReport].name, reports[selectedReport].isLedger)
            },
          },
        }}
      />
    </S_ReportSelector>
  )
}
