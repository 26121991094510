import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformGetUsersRequestPayload, transformUserResponse } from 'xen/api/use-user/transform';
async function getUsersSearch({ queryParams = {}, payload = {} }) {
    try {
        const response = await request({
            // Even though this functions as a get the API expects a POST
            method: 'POST',
            url: '/users/search',
            data: transformGetUsersRequestPayload(payload),
            params: queryParams,
        });
        return response.map(transformUserResponse);
    }
    catch (error) {
        throw new Error('Could not get users search data');
    }
}
export const useGetUsersSearch = (params = {}) => {
    return useQuery({
        queryFn: () => getUsersSearch(params),
        queryKey: ['users-search', params],
    });
};
