import React, { useEffect } from 'react'

import { S_TopNavBar } from './styles'

import { getNavLinks } from './navigation'
import { NavLink, matchPath } from 'react-router-dom'
import { useFlags } from '../../_reduxHooks/flags.hook'
import { getAuthorizations } from '../../_services/authorization.service'

const isChildMatch = (subMenu, location) => {
  let isMatch = (subNav) =>
    subNav.some((subNavItem) => {
      const strictMatch = subNavItem.route === location.pathname
      let matchSingle = false

      if (subNavItem.singleRoute) {
        if (Array.isArray(subNavItem.singleRoute)) {
          subNavItem.singleRoute.forEach((route) => {
            let matchParams = matchPath(location.pathname, { path: route })
            matchSingle = matchParams ? matchParams : matchSingle
          })
        } else {
          matchSingle = matchPath(location.pathname, { path: subNavItem.singleRoute })
        }
      }

      return strictMatch || (matchSingle && matchSingle.isExact)
    })

  let match = false

  if (isMatch(subMenu)) {
    match = true
  } else {
    for (let subItem of subMenu) {
      if (subItem.subMenu && isMatch(subItem.subMenu)) {
        match = true
        break
      }
    }
  }

  return match
}

export const TopNavBar = ({ setSubNav, customColors, isUserLender, user }) => {
  const { featureFlags } = useFlags()
  const authResources = getAuthorizations(user)
  const navItems = getNavLinks(featureFlags, user, authResources)

  const handleActive = (subMenu) => (match, location) => {
    const childMatch = subMenu && isChildMatch(subMenu, location)

    if (!childMatch && !match) return false

    return true
  }

  useEffect(() => {
    navItems.forEach(({ route, subMenu }) => {
      const match = matchPath(location.pathname, { path: route })
      const childMatch = subMenu && isChildMatch(subMenu, location)

      if (!childMatch && !match) return

      setSubNav(subMenu)
    })
  }, [location.pathname])

  return (
    <S_TopNavBar customColors={customColors} isUserLender={isUserLender}>
      <ul>
        {navItems.map(({ route, label, subMenu }, i) => {
          return (
            <li key={`navitem-${i}`}>
              <NavLink activeClassName="_active" className="top-nav-link" isActive={handleActive(subMenu)} to={route}>
                {label}
              </NavLink>
            </li>
          )
        })}
      </ul>
    </S_TopNavBar>
  )
}
