import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
import { accountsQueryKey, transactionsQueryKey } from 'xen/_modules/linked-external-accounts/api/query-keys';
import { PLAID_API_BASE_URL, PLAID_ENTITY_TYPE } from 'xen/_modules/linked-external-accounts/constants';
async function deleteApplicationAccess(account) {
    try {
        await request({
            url: `${PLAID_API_BASE_URL}/application_accesses/disconnect`,
            method: 'DELETE',
            data: {
                institution_id: account.institution.id,
                entity_type: PLAID_ENTITY_TYPE,
            },
        });
        return account;
    }
    catch (_error) {
        throw Error('Error creating link token');
    }
}
export const useDeleteApplicationAccess = () => {
    return useMutation({
        mutationFn: (account) => deleteApplicationAccess(account),
        onSuccess: (account) => {
            queryClient.invalidateQueries({ queryKey: accountsQueryKey() });
            queryClient.invalidateQueries({ queryKey: transactionsQueryKey(account.id) });
        },
    });
};
