import { getFeesAr } from '../_services/lender.service'
import { useEffect, useState } from 'react'
import { shortMonth } from '../_helpers'

export const useFeesArChart = () => {
  const [feesAr, setFeesAr] = useState([])
  const [period, setPeriod] = useState(0)
  const [lastMonthData, setLastMonthData] = useState([])
  const [activeData, setActiveData] = useState([])
  const [loading, setLoading] = useState(false)

  const periodsOptions = [
    {
      label: 'Previous 12 Months',
      value: 1,
    },
    {
      label: 'Current 12 Months',
      value: 0,
    },
  ]

  const loadData = () => {
    setLoading(true)
    getFeesAr(period).then((fees_ar) => {
      const formattedData = fees_ar.map((fee_ar) => ({
        ...fee_ar,
        month_year: `${shortMonth(fee_ar.balance_month)} ${fee_ar.balance_year}`,
        outstanding_ar: Number(fee_ar.outstanding_ar),
        fees: Number(fee_ar.fees),
      }))
      setFeesAr(formattedData)
      setActiveData(formattedData[formattedData.length - 1])
      setLastMonthData(formattedData[formattedData.length - 1])
      setLoading(false)
    })
  }

  useEffect(loadData, [period])
  return {
    feesAr: feesAr,
    setPeriod: setPeriod,
    periodsOptions: periodsOptions,
    period,
    lastMonthData,
    setActiveData,
    activeData,
    loading,
  }
}
