import { jsx as _jsx } from "react/jsx-runtime";
import { useDeleteApplicationAccess } from 'xen/_modules/linked-external-accounts/api';
import { PlaidButton } from 'xen/_modules/linked-external-accounts/components';
export const PlaidDisconnect = ({ account }) => {
    const { isPending: loading, isError, mutate: deleteApplicationAccess } = useDeleteApplicationAccess();
    if (isError)
        return _jsx("div", { "data-testid": "error-message", children: "Error disconnecting accounts. Please try again." });
    const handleDisconnect = () => {
        deleteApplicationAccess(account);
    };
    return (_jsx(PlaidButton, { color: "red", loading: loading, onClick: handleDisconnect, text: `Disconnect ${account.institution.name}` }));
};
