import React from 'react'
import { Link } from 'react-router-dom'
import { FaCog } from 'react-icons/fa'

import { LENDER_ROUTES } from '../../../../_constants/routes'
import colors from '../../../../_constants/colors'

import { S_ReportBlockerModal } from './ReportBlockerModal.styles'

export const ReportBlockerModal = () => {
  return (
    <S_ReportBlockerModal>
      <FaCog className="blocker-modal-icon" color={colors.DEFAULT_SECONDARY} size={150} />

      <p className="blocker-modal-text">
        Check <Link to={LENDER_ROUTES.generated_reports}>Recently Generated Reports</Link>
        <br />
        in "Reports" dropdown menu
      </p>
    </S_ReportBlockerModal>
  )
}
