import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Heading } from 'xen-ui';
export const PageTitle = ({ title, ...props }) => {
    const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);
    useEffect(() => {
        document.title = `${capitalizedTitle} • Xen Platforms`;
    }, [capitalizedTitle]);
    return (_jsx(Heading, { ...props, level: 1, children: capitalizedTitle }));
};
