/** Returns string formatted like 'camelCaseString' */
const formatToCamelCase = (str) => {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/[^a-zA-Z0-9]+/g, ' ') // Replace non-alphanumeric characters with space
        .trim() // Remove leading and trailing spaces
        .toLowerCase() // Convert to lowercase
        .replace(/ (.)/g, (_match, char) => char.toUpperCase()); // Convert first character after space to uppercase and remove space
};
/** Returns string formatted like 'kebab-case-string' */
function formatToKebabCase(str) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/[^a-zA-Z0-9]+/g, ' ') // Replace non-alphanumeric characters with space
        .trim() // Remove leading and trailing spaces
        .toLowerCase() // Convert to lowercase
        .replace(/ +/g, '-'); // Replace spaces with hyphens
}
/** Returns string formatted like 'snake_case_string' */
function formatToSnakeCase(str) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/[^a-zA-Z0-9]+/g, ' ') // Replace non-alphanumeric characters with space
        .trim() // Remove leading and trailing spaces
        .toLowerCase() // Convert to lowercase
        .replace(/ +/g, '_'); // Replace spaces with underscores
}
/** Returns object with keys formatted to given case */
const formatKeysToCase = (caseType, obj) => {
    const newObj = {};
    const formatters = {
        camel: formatToCamelCase,
        kebab: formatToKebabCase,
        snake: formatToSnakeCase,
    };
    const formatter = formatters[caseType];
    Object.keys(obj).forEach((key) => {
        const formattedKey = formatter(key);
        const value = obj[key];
        if (value && typeof value === 'object' && !Array.isArray(value)) {
            newObj[formattedKey] = formatKeysToCase(caseType, value);
        }
        else if (value && Array.isArray(value)) {
            newObj[formattedKey] = value.map((item) => {
                if (typeof item === 'object' && item !== null) {
                    return formatKeysToCase(caseType, item);
                }
                return item;
            });
        }
        else {
            newObj[formattedKey] = value;
        }
    });
    return newObj;
};
export const formatCase = {
    camel: formatToCamelCase,
    kebab: formatToKebabCase,
    snake: formatToSnakeCase,
    objectKeys: formatKeysToCase,
};
