import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
import { transformCreateApplicationAccessResponse } from 'xen/_modules/linked-external-accounts/api/transform';
import { PLAID_API_BASE_URL, PLAID_ENTITY_TYPE } from 'xen/_modules/linked-external-accounts/constants';
async function createApplicationAccess({ publicToken, institutionName, institutionId, }) {
    try {
        const response = await request({
            url: `${PLAID_API_BASE_URL}/application_accesses`,
            method: 'POST',
            data: {
                entity_type: PLAID_ENTITY_TYPE,
                institution_name: institutionName,
                institution_id: institutionId,
                public_token: publicToken,
            },
        });
        return transformCreateApplicationAccessResponse(response);
    }
    catch (_error) {
        throw Error('Error creating link token');
    }
}
export const useCreateApplicationAccess = () => {
    return useMutation({
        mutationFn: (params) => createApplicationAccess(params),
        onSuccess: (data) => {
            queryClient.setQueryData(['plaid-application-access-token', data.id], data);
        },
    });
};
