import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ActionIcon, Group, Stack, XenIcon } from 'xen-ui';
import { PageTitle } from 'xen/components';
import { useXenHistory } from 'xen/hooks';
import { PaymentCard, useGetPayment } from 'xen/_modules/invoice-settlement';
export const UnappliedCashPaymentCard = ({ paymentId }) => {
    const { data: payment, isLoading: isPaymentLoading } = useGetPayment(paymentId);
    const { push } = useXenHistory();
    if (isPaymentLoading || !payment)
        return _jsx(PaymentCard.Skeleton, {});
    return (_jsxs(Stack, { children: [_jsxs(Group, { align: "flex-end", justify: "space-between", children: [_jsx(PageTitle, { title: `Unapplied Cash for Payment ${payment.checkNumber}` }), _jsx(ActionIcon, { "aria-label": "close payment", bg: "gray", onClick: () => push({ to: '/unapplied-cash' }), radius: "xl", children: _jsx(XenIcon.Close, { size: "1em" }) })] }), _jsx(PaymentCard, { payment: payment })] }));
};
