import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useGetNavigation } from 'xen/api/use-navigation';
import { FeatureNotEnabled } from 'xen/components';
const Invoices = lazy(() => import('../pages/invoices'));
export const INVOICES_PATHS = {
    root: '/invoices',
};
export const InvoicesRoutes = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    if (isLoading)
        return [];
    const featureEnabled = isSuccess && !!navigation?.invoices;
    return [
        _jsx(Route, { exact: true, path: INVOICES_PATHS.root, children: featureEnabled ? _jsx(Invoices, {}) : _jsx(FeatureNotEnabled, { feature: "Invoices page" }) }, INVOICES_PATHS.root),
    ];
};
