import styled from 'styled-components'
import colors from '../../../../_constants/colors'
import { cssBreakpoints } from '../../../../_constants/styles'

export const S_ReportBlockerModal = styled.div`
  text-align: center;
  width: 450px;
  max-width: 100%;

  .blocker-modal-icon {
    margin: 10px auto 25px auto;
    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      margin: 0 auto 10px auto;
      max-width: 100px;
      max-height: 100px;
    }
  }

  .blocker-modal-text {
    font-size: 20px;
    color: ${colors.DEFAULT_TEXT};
    a {
      color: ${colors.DEFAULT_SECONDARY};
    }
  }
`
