import React from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'
import { UTCDateToSystemString } from '../../../_helpers/dates'
import { formatCurrency } from '../../../_helpers/formatting'

export const ClientSummaryReportPdf = ({ summary }) => {
  const styles = StyleSheet.create({
    page: { fontSize: 12, padding: 8 },
    header: { width: '100%', textAlign: 'center', marginBottom: 48 },
    dataContainer: { display: 'flex', flexDirection: 'row' },
    dataColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: 370,
      marginLeft: 16,
      marginRight: 16,
    },
    firstDataSection: { marginBottom: 20 },
    dataSection: {},
    dataRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 4,
      marginBottom: 4,
    },
    sectionTitle: { textTransform: 'uppercase', fontSize: 18 },
    dataTitle: {},
    dataValue: {},
    businessName: { fontSize: 18, fontWeight: 'bold' },
    subTitle: { marginLeft: 16 },
  })
  return (
    <Document>
      <Page orientation={'landscape'} size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.businessName}>{summary.params.business.business_name}</Text>
          <Text>Summary Report</Text>
          <Text>
            Date&nbsp;
            {UTCDateToSystemString(
              summary.params.start_date,
              { testingEnv: false, mockStore: {} },
              true
            ).toLocaleDateString()}
            &nbsp;-&nbsp;
            {UTCDateToSystemString(
              summary.params.end_date,
              { testingEnv: false, mockStore: {} },
              true
            ).toLocaleDateString()}
          </Text>
        </View>
        <View style={styles.dataContainer}>
          <View style={styles.dataColumn}>
            <View style={styles.firstDataSection}>
              <Text style={styles.sectionTitle}>Outstanding</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Beginning Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.beginning_balance)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Total Purchases</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.total_purchases)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Total Payments</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.total_payments)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Ending Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.ending_balance)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Invoices With Fees</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.invoices_with_fees)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Ineligible</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.ineligible)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Non Funded Invoices</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.outstanding.non_funded_invoices)}</Text>
              </View>
            </View>
            <View style={styles.dataSection}>
              <Text style={styles.sectionTitle}>Cash Received</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Invoice Payment</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.payments_posted)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Paid Closed</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.paid_closed)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Short Pay</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.paid_short)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Partial Pay</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.partial_pay)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Over Pay</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.over_pay)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>New Non-Factored</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.new_non_factored)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Returned Payments</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.returned_payments)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Total</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.cash.total)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.dataColumn}>
            <View style={styles.dataSection}>
              <Text style={styles.sectionTitle}>Net Funds Employed</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Beginning Balance</Text>
                <Text style={styles.dataValue}> {formatCurrency(summary.net_funds_employed.beginning_nfe)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Funds Advanced</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.net_funds_employed.funds_advanced)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Payments</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.net_funds_employed.payments)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Ending Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.net_funds_employed.ending_nfe)}</Text>
              </View>
            </View>
            <View style={styles.dataSection}>
              <Text style={styles.sectionTitle}>Fees</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Earned For Period</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.earned_for_period)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Earned MTD</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.earned_mtd)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Accrued Fees</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.accrued_fees)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Total Fees MTD</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.total_fees_mtd)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Accrued BOM</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.less_accrued_beginning)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Net Fees MTD</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.fees.net_fees_mtd)}</Text>
              </View>
            </View>
            <View style={styles.dataSection}>
              <Text style={styles.sectionTitle}>Unapplied Cash</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.unapplied_cash.balance)}</Text>
              </View>
            </View>
          </View>
          <View style={styles.dataColumn}>
            <View style={styles.dataSection}>
              <Text style={styles.sectionTitle}>Reserve</Text>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Beginning Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.client_beginning)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Plus Non-Factored</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.plus_non_factored)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Plus Paid Closed</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.plus_paid_closed)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Plus Over Pay</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.plus_over_pay)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Short Pay</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_short_pay)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Chargebacks</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_chargeback)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Reserve Releases</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_reserve_releases)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Funding Requests</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_funding_requests)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Fees</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_fees)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Ending Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.client_ending)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Escrow Beginning Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.escrow_beginning)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Plus New Invoices</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.plus_new_invoices)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Less Closed Invoices</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.less_closed_invoices)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Escrow Ending Balance</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.escrow_ending)}</Text>
              </View>
              <View style={styles.dataRow}>
                <Text style={styles.dataTitle}>Total</Text>
                <Text style={styles.dataValue}>{formatCurrency(summary.reserve.total)}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}
