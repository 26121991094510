import { FINANCING_TYPE_FACTORING } from '../../../_constants/financingTypes'
import { getClientSummaryReport } from '../../../_services/lender.service'
import { ClientSummaryReport } from './ClientSummaryReport'

const ClientSummaryReportMeta = {
  clientSide: true,
  getReport: async (params, modalFunctions) => {
    let businessId = params.get('business_id')
    let reportData = await getClientSummaryReport(businessId, params.toString())
    modalFunctions.closeModal()
    modalFunctions.openModal(ClientSummaryReport, { summary: reportData })
  },
  enabled: true,
  name: 'Client Summary Report',
  path: '',
  params: [
    {
      name: 'business_id',
      required: true,
      type: 'business',
      display_name: 'Client',
      options: [],
    },
    {
      name: 'start_date',
      required: true,
      type: 'date',
      display_name: 'Start Date',
      options: [],
    },
    {
      name: 'end_date',
      required: true,
      type: 'date',
      display_name: 'End Date',
      options: [],
    },
  ],
  scopes: ['business'],
  requiresFeature: 'client_summary_report',
  requiresProduct: FINANCING_TYPE_FACTORING,
}

export { ClientSummaryReportMeta as default }
