import styled from 'styled-components'
import { darken } from 'polished'
import colors from '../../../_constants/colors'
export const S_ClientSummaryReport = styled.div`
  width: 1000px;
  position: relative;
  header {
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
  }
  .vertical-title {
    text-transform: uppercase;
    font-size: 18px;
  }
  .data-row {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .data-container {
    display: flex;
    .data-column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 16px;
      width: 370px;
    }

    .data-section:nth-child(1) {
      margin-bottom: 20px;
    }
  }

  .download-link {
    display: inline-block;
    width: max-content;
    height: auto;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease-in-out;

    border: 1px solid ${colors.DEFAULT_SECONDARY};
    background-color: ${colors.DEFAULT_SECONDARY};
    font-size: 16px;
    border-radius: 4px;
    color: white;
    text-align: center;
    padding: 9px 19px;
    min-width: 150px;
    letter-spacing: 0.3px;
    box-sizing: border-box;
    &:hover {
      border-color: ${darken(0.15, colors.DEFAULT_SECONDARY)};
      background-color: ${darken(0.15, colors.DEFAULT_SECONDARY)};
    }
    position: absolute;
    top: 0;
    right: 0;
  }
  .sub-title {
    margin-left: 16px;
  }
`
