import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformUserCreatePayload, transformUserResponse } from './transform';
async function createUser({ payload }) {
    const { financingType, user } = payload;
    try {
        const transformedPayload = {
            financing_type: financingType,
            user: transformUserCreatePayload(user),
        };
        const response = await request({
            method: 'POST',
            url: '/users',
            data: transformedPayload,
        });
        const data = transformUserResponse(response);
        return data;
    }
    catch (error) {
        throw new Error('Could not create user');
    }
}
export const useCreateUser = () => {
    return useMutation({
        mutationKey: ['create-user'],
        mutationFn: (params) => createUser(params),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (user) => {
            // user.name is not returned in this instance so we construct it with the available fields
            const { firstName, middleName, lastName } = user;
            notifications.success(`Created user ${[firstName, middleName, lastName].join(' ').trim()}`);
            queryClient.invalidateQueries({ queryKey: ['users-search'] });
        },
    });
};
