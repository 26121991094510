import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
const UnappliedCash = lazy(() => import('../pages/unapplied-cash'));
export const UNAPPLIED_CASH_PATHS = {
    root: '/unapplied-cash',
    payment: '/unapplied-cash/:paymentId',
};
export const UnappliedCashRoutes = () => {
    const { productFactoring: factoringEnabled } = useFeatureFlags();
    return [
        _jsx(Route, { exact: true, path: UNAPPLIED_CASH_PATHS.root, children: factoringEnabled ? _jsx(UnappliedCash, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, UNAPPLIED_CASH_PATHS.root),
        _jsx(Route, { path: UNAPPLIED_CASH_PATHS.payment, children: factoringEnabled ? _jsx(UnappliedCash, {}) : _jsx(FeatureNotEnabled, { feature: "Factoring" }) }, UNAPPLIED_CASH_PATHS.payment),
    ];
};
