import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { Select as XenSelect, } from 'xen-ui';
import { ErrorText } from '../../error-text';
export function FormSelect({ name, control, defaultValue, error, rules, shouldUnregister, onChange, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    return (_jsx(XenSelect, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e, value);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...field, ...props }));
}
