import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCreateLinkToken } from 'xen/_modules/linked-external-accounts/api';
import { PlaidButton, PlaidModal } from 'xen/_modules/linked-external-accounts/components';
export const PlaidLink = () => {
    const CONNECT_BUTTON_TEXT = 'Link a bank account';
    const { data: linkTokenResponse, isPending: loading, isError: isLinkTokenError, mutate: createLinkToken, } = useCreateLinkToken();
    const { linkToken } = linkTokenResponse || {};
    const handleOnClick = async () => {
        createLinkToken();
    };
    if (isLinkTokenError) {
        return _jsx("div", { children: "Error creating link token" });
    }
    return (_jsxs("div", { children: [_jsx(PlaidButton, { loading: loading, onClick: handleOnClick, text: CONNECT_BUTTON_TEXT }), linkToken && _jsx(PlaidModal, { linkToken: linkToken })] }));
};
