import { jsx as _jsx } from "react/jsx-runtime";
import { getFontSize, rem } from '@mantine/core';
// Find all available icons at https://tabler.io/icons
import { TbAlertTriangle, TbBell, TbBuildingBank, TbCalendar, TbCheck, TbChevronDown, TbChevronLeft, TbChevronRight, TbChevronUp, TbCircleMinus, TbClipboardList, TbCloudUpload, TbDownload, TbDownloadOff, TbExclamationMark, TbEye, TbFile, TbFiles, TbHelpCircleFilled, TbLink, TbListCheck, TbLogout, TbPencil, TbRotateClockwise2, TbSettings, TbSmartHome, TbTrash, TbUserDollar, TbUsers, TbUsersPlus, TbX, } from 'react-icons/tb';
const MANTINE_SIZES = ['xs', 'sm', 'md', 'lg', 'xl'];
const SIZE_MAP = {
    xs: '0.5rem',
    sm: '1rem',
    md: '2rem',
    lg: '3rem',
    xl: '4rem',
};
const withFontSize = (IconComponent) => {
    const IconComponentWithFontSize = ({ fontSize = 'inherit', size, ...props }) => {
        let iconSize;
        if (size && MANTINE_SIZES.includes(size)) {
            iconSize = SIZE_MAP[size];
        }
        else if (size) {
            iconSize = size;
        }
        else if (fontSize) {
            iconSize = getFontSize(fontSize);
        }
        return _jsx(IconComponent, { "data-testid": "icon", ...props, style: { width: iconSize, height: iconSize, ...props.style } });
    };
    IconComponentWithFontSize.displayName = 'IconComponentWithFontSize';
    return IconComponentWithFontSize;
};
// Add new icons here
const XenIconMap = {
    AlertTriangle: TbAlertTriangle,
    Bell: TbBell,
    BuildingBank: TbBuildingBank,
    Calendar: TbCalendar,
    CheckMark: TbCheck,
    ChevronDown: TbChevronDown,
    ChevronLeft: TbChevronLeft,
    ChevronRight: TbChevronRight,
    ChevronUp: TbChevronUp,
    ClipboardList: TbClipboardList,
    Close: TbX,
    CloudUpload: TbCloudUpload,
    Download: TbDownload,
    DownloadOff: TbDownloadOff,
    ExclamationMark: TbExclamationMark,
    Eye: TbEye,
    Gear: TbSettings,
    File: TbFile,
    Files: TbFiles,
    Link: TbLink,
    ListCheck: TbListCheck,
    Logout: TbLogout,
    MinusCircle: TbCircleMinus,
    Pencil: TbPencil,
    QuestionMarkCircle: TbHelpCircleFilled,
    RotateClockwise2: TbRotateClockwise2,
    SmartHome: TbSmartHome,
    Trash: TbTrash,
    UserDollar: TbUserDollar,
    UsersPlus: TbUsersPlus,
    Users: TbUsers,
};
const XenIcon = Object.entries(XenIconMap).reduce((acc, [name, Component]) => {
    acc[name] = withFontSize(Component);
    return acc;
}, {});
export { rem, XenIcon };
