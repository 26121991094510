import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Select } from 'xen-ui';
import { useGetAccounts } from 'xen/_modules/linked-external-accounts/api';
const accountDisplayName = (account) => {
    return `${account.institution.name}: ${account.name} - ${account.accountType}`;
};
const buildAccountData = (account) => {
    return { value: account.id, label: accountDisplayName(account) };
};
const buildAccountsData = (accounts) => {
    return accounts.map((account) => {
        return buildAccountData(account);
    });
};
export const PlaidAccounts = ({ onAccountSelected, refetchInterval = 0, }) => {
    const { data: accounts = [], isLoading, isError } = useGetAccounts(refetchInterval);
    const accountsData = buildAccountsData(accounts);
    const defaultAccount = accounts[0] || null;
    useEffect(() => {
        // By default, select the first account in the list
        onAccountSelected(defaultAccount);
    }, [accounts]);
    if (isError)
        return _jsx("div", { children: "Error getting accounts. Please try again." });
    if (isLoading)
        return _jsx("div", { children: "Loading..." });
    if (accounts?.length === 0)
        return _jsx("div", { "data-testid": "no-accounts" });
    const handleAccountSelected = (value) => {
        const account = accounts.find((account) => account.id === value) || null;
        onAccountSelected(account);
    };
    return (_jsx("div", { style: { width: '400px', marginTop: '20px' }, children: _jsx(Select, { allowDeselect: false, data: accountsData, defaultValue: defaultAccount?.id, label: "Your linked accounts", onOptionSubmit: handleAccountSelected, searchable: true }) }));
};
