import styled from 'styled-components'
import { ButtonComponent } from '../../Button'
import { S_TileTitle } from '../../TableTile/TileTitle.styles'
import { tileBorder } from '../../LenderOverviewTile.style'

const controlsWidth = 150
const controlsOffset = 10

export const S_ConcentrationChart = styled.div`
  position: relative;
  width: 100%;

  border: ${tileBorder};
  border-radius: 3px;

  .chart-wrapper {
    max-width: 700px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px;
    box-sizing: border-box;
  }

  .chart-segments-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }

  ${S_TileTitle} {
    position: absolute;
    top: 15px;
    margin-left: 15px;
    left: ${controlsOffset}px;
  }

  .segments-chart-wrapper {
    width: calc(100% - ${controlsOffset + controlsWidth}px);
  }

  .recharts-responsive-container {
    max-width: 280px;
    margin-left: auto;
  }

  .segments-controls {
    max-width: ${controlsWidth}px;
    width: 100%;
    margin-left: ${controlsOffset}px;
    ${ButtonComponent} {
      display: block;
      width: 100%;
      margin-top: 5px;
    }
  }
`
