import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Divider, Group, useMediaQuery } from 'xen-ui';
import { useGetNavigation } from 'xen/api/use-navigation';
import { useAppShellNavBarContext, useXenTheme } from 'xen/providers';
import { BROKERS_PATHS, CLIENTS_PATHS, DEBTORS_PATHS, FUNDING_REQUESTS_PATHS, INVOICE_SCHEDULES_PATHS, INVOICE_SETTLEMENT_PATHS, INVOICES_PATHS, LINKED_EXTERNAL_ACCOUNTS_PATHS, OVERVIEW_PATHS, PROSPECTS_PATHS, UNAPPLIED_CASH_PATHS, } from 'xen/routes';
import { NavItem } from '../../link';
export const NavBarBody = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { toggleNavBarState } = useAppShellNavBarContext();
    const toggleNavForMobile = () => {
        if (isMobileXS) {
            toggleNavBarState();
        }
    };
    if (isLoading)
        return null;
    if (!isSuccess) {
        return _jsx("span", { children: 'Could not load navigation data' });
    }
    const { overview, originate, brokers, businesses, factoring, fundingRequests, invoices, invoiceSettlements, invoiceSchedules, debtors, accounts, linkedExternalAccounts, unappliedCash, } = navigation;
    return (_jsxs(_Fragment, { children: [overview && (_jsx(NavItem.Link, { icon: "SmartHome", label: overview, onClick: toggleNavForMobile, to: OVERVIEW_PATHS.root })), originate && (_jsx(NavItem.Link, { icon: "UsersPlus", label: originate, onClick: toggleNavForMobile, to: PROSPECTS_PATHS.root })), businesses && (_jsx(NavItem.Link, { icon: "Users", label: businesses, onClick: toggleNavForMobile, to: CLIENTS_PATHS.root })), brokers && (_jsx(NavItem.Link, { icon: "UserDollar", label: brokers, onClick: toggleNavForMobile, to: BROKERS_PATHS.root })), factoring && (_jsx(NavItem.Button, { icon: "ClipboardList", label: factoring, children: _jsxs(Group, { wrap: "nowrap", children: [_jsx(Divider, { color: "gray.5", h: "inherit", orientation: "vertical" }), _jsxs(Box, { flex: '1 1 0', children: [invoices && _jsx(NavItem.Link, { label: invoices, onClick: toggleNavForMobile, to: INVOICES_PATHS.root }), invoiceSchedules && (_jsx(NavItem.Link, { label: invoiceSchedules, onClick: toggleNavForMobile, to: INVOICE_SCHEDULES_PATHS.root })), invoiceSettlements && (_jsx(NavItem.Link, { label: invoiceSettlements, onClick: toggleNavForMobile, to: INVOICE_SETTLEMENT_PATHS.root })), debtors && _jsx(NavItem.Link, { label: debtors, onClick: toggleNavForMobile, to: DEBTORS_PATHS.root }), fundingRequests && (_jsx(NavItem.Link, { label: fundingRequests, onClick: toggleNavForMobile, to: FUNDING_REQUESTS_PATHS.root }))] })] }) })), accounts && (_jsx(NavItem.Button, { icon: "BuildingBank", label: accounts, children: _jsxs(Group, { wrap: "nowrap", children: [_jsx(Divider, { color: "gray.5", h: "inherit", orientation: "vertical" }), _jsxs(Box, { flex: '1 1 0', children: [linkedExternalAccounts && (_jsx(NavItem.Link, { label: linkedExternalAccounts, onClick: toggleNavForMobile, to: LINKED_EXTERNAL_ACCOUNTS_PATHS.root })), unappliedCash && (_jsx(NavItem.Link, { label: unappliedCash, onClick: toggleNavForMobile, to: UNAPPLIED_CASH_PATHS.root }))] })] }) }))] }));
};
