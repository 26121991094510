import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformCreateLinkTokenResponse } from 'xen/_modules/linked-external-accounts/api/transform';
import { PLAID_API_BASE_URL, PLAID_ENTITY_TYPE } from 'xen/_modules/linked-external-accounts/constants';
async function createLinkToken() {
    try {
        const response = await request({
            method: 'post',
            url: `${PLAID_API_BASE_URL}/application_accesses/create_link_token`,
            data: {
                entity_type: PLAID_ENTITY_TYPE,
            },
        });
        return transformCreateLinkTokenResponse(response);
    }
    catch (_error) {
        throw Error('Error creating link token');
    }
}
export const useCreateLinkToken = () => {
    return useMutation({
        mutationFn: () => createLinkToken(),
    });
};
