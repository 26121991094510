/** Returns date string formatted like '1/31/2024' */
const formatDateAsLocaleDateString = (date) => {
    return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' });
};
/** Returns date string formatted like '2024-01-31' */
const formatDateAsISODateString = (date) => {
    return new Date(date).toISOString().split('T')[0] ?? '';
};
export const formatDate = {
    localeDateString: formatDateAsLocaleDateString,
    isoDateString: formatDateAsISODateString,
};
