import { useQuery } from '@tanstack/react-query';
import { generateCancelToken } from 'legacy/_services/axios_service';
import { request } from 'xen/api';
import { getTenantFromUrl } from 'xen/helpers/url';
import { transformTenantCustomizationResponse } from './transform';
async function getTenantCustomization({ tenantToken }) {
    try {
        // We will eventually replace this with a new axios instance.
        const response = await request({
            url: `/v2/tenants/${tenantToken}/customization`,
            method: 'GET',
            cancelToken: generateCancelToken().token,
        });
        return transformTenantCustomizationResponse(response);
    }
    catch (error) {
        throw Error('Error getting tenant customization');
    }
}
export const useGetTenantCustomization = () => {
    const tenantToken = getTenantFromUrl();
    return useQuery({
        queryKey: ['tenants', tenantToken, 'customization'],
        queryFn: () => getTenantCustomization({ tenantToken }),
        enabled: tenantToken !== undefined,
    });
};
