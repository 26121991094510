import styled from 'styled-components'
import { S_AlertMessage } from '../../../AlertMessage'
import { S_AutoCompleteInput } from '../../../AutoCompleteInput'

export const S_ReportCustomizer = styled.div`
  width: 450px;
  max-width: 100%;

  ${S_AlertMessage} {
    margin-top: 10px;
    text-align: left;

    a {
      text-decoration: underline;
      color: #721c24;
    }
  }

  ${S_AutoCompleteInput} {
    display: flex;
    align-items: center;
  }

  h2 {
    border-bottom: 1px solid #00000014;
  }

  .subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .label {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-right: 16px;
    min-width: 100px;
  }

  .toggle-error-details {
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-weight: 600;
  }

  .error-details {
    font-size: 12px;
    font-family: monospace;
    background-color: #e6e6e6;
    padding: 2px 4px;
    border-radius: 3px;
  }
  .btn-row {
    display: flex;
    justify-content: space-around;
  }
`
