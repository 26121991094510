import React from 'react'
import { formatCurrency, formatPercentage } from '../../../_helpers'
import { S_ChartDataPanel } from './ChartDataPanel.styles'

import ToolTip from '../../../_components/Tooltip/Tooltip'

export const ChartDataPanel = ({ dataSource = {}, dataPanelItems = [], spacingType = 'space-between' }) => {
  const getItemValue = (item) => {
    const value = dataSource[item['key']]

    if (!item.type) {
      return value || '-'
    }

    switch (item.type) {
      case 'currency':
        return formatCurrency(value || 0)
      case 'percent':
        return formatPercentage(value || 0)
    }
  }

  return (
    <S_ChartDataPanel spacingType={spacingType}>
      {dataPanelItems.map((item, i) => {
        return (
          <div className="chart-data-item" key={i}>
            <div className="chart-data-item-wrapper">
              <ToolTip content={item.tooltip}>
                <span>
                  <p className="chart-data-item__title">{item['label'] ? item['label'] : ''}</p>
                  <p className="chart-data-item__value">{getItemValue(item)}</p>
                </span>
              </ToolTip>
            </div>
          </div>
        )
      })}
    </S_ChartDataPanel>
  )
}
