import { esigningConstants } from '../_constants'
import { alertActions, modalActions, notificationsActions } from './'

// Components
import { EsigningDocumentsList } from '../_components/modals/esigning/EsigningDocumentsList'

//Helpers
import HelloSign from 'hellosign-embedded'
import { getSignatureRequestUrl, getESigningRequests } from '../_services/v2/documents.service'

export const esigningActions = {
  getEsigningRequests,
  openEsigningDocument,
  signEsigningDocument,
}

function getEsigningRequests(userId, loggingIn = false) {
  return (dispatch) => {
    dispatch(request(userId))
    return getESigningRequests(userId)
      .then((resp) => {
        dispatch(success(resp.count, resp.signature_requests))

        if (loggingIn && resp.count > 0) {
          dispatch(
            alertActions.showAlert({
              title: `You have ${resp.count} document(s) to sign`,
              type: `info`,
              confirmButtonText: `Sign Documents Now`,
              cancelButtonText: `Later`,
              onConfirm: () => {
                dispatch(modalActions.openModal(EsigningDocumentsList))
              },
            })
          )
        }
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(userId) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_REQUEST, userId }
  }
  function success(requestsCount, requests) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_SUCCESS, requestsCount, requests }
  }
  function failure(error) {
    return { type: esigningConstants.GET_ESIGNING_REQUESTS_FAILURE, error }
  }
}

function openEsigningDocument(doc, userId, env, lastDoc) {
  return (dispatch) => {
    dispatch(request(doc.signature_request_id, userId))

    try {
      const signatureRequest = {
        document_title: doc.template.label,
        document_created_at: doc.request_date,
        client_id: doc.clientId,
      }
      dispatch(success(signatureRequest))

      const lenderSigner = doc.signers.find((signer) => signer.role === 'Tenant')

      getSignatureRequestUrl(lenderSigner.s_gid).then((response) => {
        const { sign_url, client_id } = response

        const client = new HelloSign({
          clientId: client_id,
        })

        client.open(sign_url, {
          allowCancel: false,
          skipDomainVerification: env === 'staging' ? true : false,
          container: document.getElementById(`this-container`),
        })

        client.on('sign', () => {
          dispatch(signEsigningDocument(userId, lastDoc))
        })
      })
    } catch (error) {
      dispatch(failure(error.message))
      dispatch(
        notificationsActions.showNotification({
          type: `error`,
          message: error.message,
        })
      )
    }
  }

  function request(requestId, userId) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_REQUEST, requestId, userId }
  }
  function success(signatureRequest) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_SUCCESS, signatureRequest }
  }
  function failure(error) {
    return { type: esigningConstants.OPEN_ESIGNING_DOCUMENT_FAILURE, error }
  }
}

function signEsigningDocument(userId, lastDoc) {
  return (dispatch) => {
    dispatch(success())

    dispatch(
      notificationsActions.showNotification({
        type: `success`,
        message: `Document successfully signed!`,
      })
    )

    if (lastDoc) {
      dispatch(
        notificationsActions.showNotification({
          type: `info`,
          message: `All documents have been successfully signed!`,
        })
      )
    }

    setTimeout(() => {
      if (lastDoc) {
        dispatch(modalActions.closeModal())
      } else {
        dispatch(modalActions.switchModal(EsigningDocumentsList))
      }
      dispatch(getEsigningRequests(userId))
    }, 3000)
  }

  function success() {
    return { type: esigningConstants.SIGN_ESIGNING_DOCUMENT }
  }
}
