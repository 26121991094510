import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Group } from '@mantine/core';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Image, Stack } from 'xen-ui';
import { useDeleteConfiguration, useUpdateConfiguration } from 'xen/api/use-configuration';
import { ErrorText } from 'xen/components';
import { Fieldset, FormColorInput, FormFileInput, FormTextInput } from 'xen/components/form';
import { formatCase } from 'xen/helpers';
const zHexColor = FormColorInput.schema;
const zImageFile = FormFileInput.schema({ acceptedTypes: ['ico', 'jpeg', 'png'] });
const brandingConfigurationUpdateFormSchema = z.object({
    colorsBackground: zHexColor,
    colorsPrimary: zHexColor,
    colorsSecondary: zHexColor,
    imagesFavicon: zImageFile,
    imagesLogo: zImageFile,
    urlsHomepage: z.string().url().or(z.literal('')),
});
export const BrandingConfigurationUpdateForm = ({ configuration }) => {
    const defaultValues = {
        colorsBackground: configuration.tenant.branding.colors.background,
        colorsPrimary: configuration.tenant.branding.colors.primary,
        colorsSecondary: configuration.tenant.branding.colors.secondary,
        imagesFavicon: new File([], configuration.tenant.branding.images.favicon, { type: 'image/png' }),
        imagesLogo: new File([], configuration.tenant.branding.images.logo, { type: 'image/png' }),
        urlsHomepage: configuration.tenant.branding.urls.homepage,
    };
    const { control, handleSubmit, resetField, watch } = useForm({
        defaultValues: defaultValues,
        values: defaultValues,
        resetOptions: {
            keepDirtyValues: false,
            keepErrors: false,
        },
        resolver: zodResolver(brandingConfigurationUpdateFormSchema),
        mode: 'onChange',
    });
    const { mutate: updateConfiguration, isError: updateIsError, isPending: isUpdatePending } = useUpdateConfiguration();
    const onSubmit = async (data) => {
        const configurationPayload = {
            tenant: {
                branding: {
                    colors: {
                        background: data.colorsBackground,
                        primary: data.colorsPrimary,
                        secondary: data.colorsSecondary,
                    },
                    images: {
                        favicon: data.imagesFavicon.name,
                        logo: data.imagesLogo.name, // TODO: replace with actual logo File when API supports it
                    },
                    urls: {
                        homepage: data.urlsHomepage,
                    },
                },
            },
        };
        updateConfiguration({ payload: configurationPayload });
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    const faviconFile = watch('imagesFavicon');
    const logoFile = watch('imagesLogo');
    return (_jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsxs(Stack, { gap: "xl", children: [updateIsError && _jsx(ErrorText, { message: "Error updating configuration" }), _jsxs(Fieldset, { legend: "Branding", children: [_jsx(FormFileInput, { control: control, inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/images/favicon", resetField: resetField, children: children })), label: "Favicon", name: "imagesFavicon", required: true }), _jsx(Image, { alt: "favicon", fit: "contain", mah: 48 * 2, 
                            // TODO: Update when we have real API file value
                            src: faviconFile.name.startsWith('https') ? faviconFile.name : URL.createObjectURL(faviconFile), style: { alignSelf: 'flex-start' }, w: "auto" }), _jsx(FormFileInput, { control: control, inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/images/logo", resetField: resetField, children: children })), label: "Logo", name: "imagesLogo", required: true }), _jsx(Image, { alt: "logo", fit: "contain", mah: 70 * 2, 
                            // TODO: Update when we have real API file value
                            src: logoFile.name.startsWith('https') ? logoFile.name : URL.createObjectURL(logoFile), style: { alignSelf: 'flex-start' }, w: "auto" })] }), _jsxs(Fieldset, { legend: "Colors", children: [_jsx(FormColorInput, { control: control, inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/colors/primary", resetField: resetField, children: children })), label: "Primary", name: "colorsPrimary", required: true }), _jsx(FormColorInput, { control: control, inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/colors/background", resetField: resetField, children: children })), label: "Secondary", name: "colorsSecondary", required: true }), _jsx(FormColorInput, { control: control, inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/colors/secondary", resetField: resetField, children: children })), label: "Background", name: "colorsBackground", required: true })] }), _jsx(Fieldset, { legend: "Links", children: _jsx(FormTextInput, { control: control, iconLeft: "Link", inputContainer: (children) => (_jsx(ContainerWithResetButton, { deletePath: "branding/urls/homepage", resetField: resetField, children: children })), label: "Homepage", name: "urlsHomepage" }) }), _jsx(Button, { loading: isUpdatePending, size: "md", style: { alignSelf: 'flex-start' }, type: "submit", children: "Save" })] }) }));
};
const ContainerWithResetButton = ({ children, deletePath, resetField = () => { }, }) => {
    const { mutateAsync: deleteConfiguration, isPending } = useDeleteConfiguration();
    return (_jsxs(Group, { children: [_jsx("div", { style: { flexGrow: 1 }, children: children }), _jsx(Button, { color: "red", loading: isPending, onClick: async () => {
                    await deleteConfiguration({ urlParams: { configPath: deletePath } });
                    // Convert 'branding/colors/primary' to 'colorsPrimary'
                    const fieldKey = formatCase.camel(deletePath.split('branding/')[1].replace('/', ' '));
                    resetField(fieldKey);
                }, variant: "outline", children: "Reset to Default" })] }));
};
