import { useQuery } from '@tanstack/react-query';
import { generateCancelToken } from 'legacy/_services/axios_service';
import { request } from 'xen/api';
import { formatCase } from 'xen/helpers';
import { getTenantFromUrl } from 'xen/helpers/url';
async function getConfiguration() {
    try {
        const response = await request({
            url: `/v2/configuration`,
            method: 'GET',
            cancelToken: generateCancelToken().token,
        });
        return formatCase.objectKeys('camel', response);
    }
    catch (error) {
        throw Error('Could not fetch configuration');
    }
}
export const useGetConfiguration = () => {
    const tenantToken = getTenantFromUrl();
    return useQuery({
        queryKey: ['tenants', tenantToken, 'configuration'],
        queryFn: () => getConfiguration(),
    });
};
