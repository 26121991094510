import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AppShell, Stack } from 'xen-ui';
import { useAuthentication } from 'xen/hooks';
import { useAppShellNavBarContext } from 'xen/providers';
import { Footer } from './footer';
import { NavBar } from './nav-bar';
export const AppLayout = ({ children }) => {
    const { isLoggedIn } = useAuthentication();
    const { navBarOpened } = useAppShellNavBarContext();
    return (_jsxs(AppShell, { navbar: {
            width: '18rem',
            collapsed: { mobile: !isLoggedIn || !navBarOpened, desktop: !isLoggedIn || !navBarOpened },
            breakpoint: 'xs',
        }, children: [isLoggedIn && _jsx(NavBar, {}), _jsx(AppShell.Main, { mih: "100vh", children: _jsxs(Stack, { justify: "space-between", mih: "inherit", children: [children, _jsx(Footer, {})] }) })] }));
};
