import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// We want to replace this when we handle login work for Xen 1.0
import { useSignOut } from 'legacy/_reduxHooks/authentication.hook';
import { Avatar, Divider, Group, ScrollArea, Stack, Text, useMediaQuery } from 'xen-ui';
import { useGetNavigation } from 'xen/api/use-navigation';
import { useAuthentication } from 'xen/hooks';
import { useUserContext } from 'xen/providers';
import { useAppShellNavBarContext, useXenTheme } from 'xen/providers';
import { CONFIGURATIONS_PATHS, NOTIFICATIONS_PATHS } from 'xen/routes';
import { NavItem } from '../../link';
export const NavBarFooter = () => {
    const user = useUserContext();
    const { signOut } = useSignOut();
    const { sessionToken } = useAuthentication();
    const { data: navigation, isLoading: isNavigationLoading, isSuccess: isNavigationSuccess } = useGetNavigation();
    const theme = useXenTheme();
    const isMobileXS = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
    const { toggleNavBarState } = useAppShellNavBarContext();
    const toggleNavForMobile = () => {
        if (isMobileXS) {
            toggleNavBarState();
        }
    };
    if (!isNavigationSuccess) {
        return _jsx("span", { children: 'Could not load navigation data' });
    }
    const { configs, notifications } = navigation;
    const { emailAddress, name } = user;
    if (isNavigationLoading)
        return null;
    const handleSignOut = () => {
        signOut(sessionToken);
    };
    return (_jsxs(_Fragment, { children: [_jsx(Divider, { color: "gray.5", mb: "0", my: "0" }), configs && (_jsx(NavItem.Link, { icon: "Gear", label: configs, onClick: toggleNavForMobile, to: CONFIGURATIONS_PATHS.root })), notifications && (_jsx(NavItem.Link, { icon: "Bell", label: notifications, onClick: toggleNavForMobile, to: NOTIFICATIONS_PATHS.root })), _jsx(NavItem.Button, { icon: "Logout", label: "Sign Out", onClick: handleSignOut }), _jsxs(Group, { px: "md", py: "xs", wrap: "nowrap", children: [_jsx(Avatar, { allowedInitialsColors: ['gray'], alt: name, color: "initials", name: name }), _jsxs(Stack, { flex: "1 0 0", gap: "0", justify: "center", style: { overflow: 'hidden' }, children: [_jsx(ScrollArea, { "aria-label": "current user name", scrollbarSize: 2, type: "auto", children: _jsx(Text, { "word-break": "nowrap", children: name }) }), _jsx(ScrollArea, { "aria-label": "current user email", scrollbarSize: 2, type: "auto", children: _jsx(Text, { size: "xs", children: emailAddress }) })] })] })] }));
};
