import { paramsSerializer } from 'xen/api';
/**
 * Dynamically construct a valid internal link path (based on optional
 * `AllowedPath` type passed to funtion) where dynamic URL params (e.g.
 * `:someId`) are replaced and search params are appended.
 *
 * ```ts
 * createInternalLinkUrl({
 *  to: '/debtors/:debtorId/invoices/:invoiceId/users/:userId',
 *  urlParams: ['123', '456', '789'],
 *  searchParams: { sort: 'date', state: ['closed', 'purchased'] },
 * })
 * // => '/debtors/123/invoices/456/users/789?sort=date&state%5B%5D=closed&state%5B%5D=purchased'
 * ```
 *
 * To constrain the valid internal link paths that can be passed to the function,
 * you can pass a custom type to the function like so:
 * ```ts
 * type AllowedPath = '/foo' | '/bar'
 * createInternalLinkUrl<AllowedPath>({
 *   to: '/foo', // Only '/foo' or '/bar' are allowed
 * })
 * ```
 *
 * NOTE: This only supports a maximum of 3 dynamic URL params. If you need more,
 * update the `InternalLinkUrlProps` type in `xen/types/link.ts` or manually
 * construct your URL.
 */
export const createInternalLinkUrl = ({ to, urlParams, searchParams = {}, }) => {
    const dynamicParts = to.split('/').filter((part) => part.startsWith(':'));
    if (dynamicParts.length > 3 || (urlParams && urlParams.length > 3)) {
        throw new Error(`Maximum of 3 dynamic URL params allowed`);
    }
    let dynamicIndex = 0;
    const replacedURL = to.replace(/:[^/]+/g, () => {
        const param = urlParams ? String(urlParams[dynamicIndex]) : '';
        dynamicIndex++;
        return param || '';
    });
    const urlSearchParams = paramsSerializer(searchParams);
    const urlWithSearchParams = [replacedURL, urlSearchParams].filter(Boolean).join('?');
    return urlWithSearchParams;
};
export const getTenantFromUrl = () => {
    const url = window.location.origin;
    const regex = /^https?:\/\/([^/.]+)/;
    const match = url.match(regex) ?? [];
    const tenant = match[1];
    if (tenant === undefined || tenant.startsWith('localhost'))
        return '';
    return tenant;
};
export const searchParam = {
    get: (key, fallback = '') => new URLSearchParams(window.location.search).get(key) || fallback,
    set: (key, value) => {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        const newUrl = url.toString();
        // https://github.com/vercel/next.js/discussions/18072#discussioncomment-109059
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, '', newUrl);
    },
};
