import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const defaultTenant = {
    addressCity: '',
    addressCountry: '',
    addressState: 'OH',
    addressStreet: null,
    addressUnit: null,
    addressZipCode: null,
    agingType: '',
    aliasOfId: null,
    appEndpoint: '',
    attachmentsEmailAddress: '',
    bccUserEmail: false,
    contactEmailAddress: '',
    contactFirstName: '',
    contactLastName: '',
    contactPhone: '',
    contractLengthMonths: null,
    contractRenewDate: null,
    contractSignDate: null,
    createdAt: new Date(),
    customization: {
        colorBackground: '',
        colorPrimary: '',
        colorSecondary: '',
        images: {
            logo: '',
            favicon: '',
        },
        menuLinks: [
            {
                link: '',
                label: '',
            },
            {
                link: '',
                label: '',
            },
        ],
        urlFacebook: null,
        urlLinkedin: '',
        urlXTwitter: null,
        urls: {
            tenantUrl: '',
        },
    },
    disabled: false,
    fromEmailAddress: '',
    fundingRequestNotificationEmail: '',
    hellosignEnabled: true,
    hideEmailLogo: false,
    id: 7,
    newLeadsEmailAddress: '',
    notificationsEmailAddress: '',
    numLicenses: null,
    operateEnabled: false,
    operationsAttachmentsEmailAddress: '',
    operationsNotificationsEmailAddress: '',
    organizationName: '',
    organizationType: '',
    organizationWebsite: '',
    originationType: '',
    preheaderEmailColor: '',
    qbOauthVersion: 2,
    referenceNum: 7,
    returnUserAttachmentsEmailAddress: '',
    returnUserNotificationsEmailAddress: '',
    scheduleBatchStart: 1,
    showCopyright: true,
    showTrackingData: false,
    tenantTimezone: '',
    tenantType: 'tenant',
    token: '',
    updatedAt: new Date(),
};
const TenantContext = createContext(defaultTenant);
export const TenantProvider = ({ children, tenant }) => {
    return _jsx(TenantContext.Provider, { value: tenant, children: children });
};
export const useTenantContext = () => {
    const context = useContext(TenantContext);
    return context;
};
