import { getDateFromDatetime, UTCDateToSystemString } from './dates'
import snakeCase from 'lodash/snakeCase'

const getReportDate = (date = null) => {
  const dateObject = date ? new Date(date) : new Date()
  let currentDate = UTCDateToSystemString(dateObject.toISOString())
  return getDateFromDatetime(currentDate)
}

const getFormattedReportName = (reportName = 'report') => snakeCase(reportName)

export const getReportFileName = (date = null, reportName = null, mime_type = 'text/csv') => {
  const reportDate = getReportDate(date)
  const name = getFormattedReportName(reportName)
  const extension = mime_type.split('/')[1]
  return `${name}_${reportDate}.${extension}`
}
