import styled from 'styled-components'
import { colors } from '../../../_constants/colors'

export const S_ChartDataPanel = styled.div`
  padding: 15px;
  border-top: 1px solid ${colors.BORDER_GREY};
  display: flex;
  justify-content: ${(props) => props.spacingType};
  text-align: left;

  .chart-data-item {
    padding: 5px;

    &__title {
      color: ${colors.TEXT_LIGHT};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    &__value {
      font-size: 16px;
      margin-top: 5px;
      color: ${colors.TEXT_DARK};
    }
  }
`
