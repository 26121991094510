import { hotjar } from 'react-hotjar'
import { TENANT_HOTJAR_IDS, TENANT_TOKEN_LOOKUP } from '../../_constants/tenants'

export const getCurrentEnv = () => {
  const domain_part = '.finvoice.co'
  const host = window.location.host

  const sub_host = host.replace(domain_part, '')
  const sub_host_parts = sub_host.split('.')

  let tenantToken = 'finvoice'
  let env = 'staging'

  if (sub_host_parts.length > 1) {
    // it is stg or localhost
    if (
      sub_host_parts[1] === 'adminstaging' ||
      sub_host_parts[1] === 'staging' ||
      sub_host_parts[1] === 'stg' ||
      sub_host_parts[1].includes('localhost')
    ) {
      // just to be sure we are receiving correct subdomain
      tenantToken = sub_host_parts[0]
      env = 'staging'
    } else if (sub_host_parts[1] === 'admin' || sub_host_parts[1] === 'xenplatforms') {
      // mapping production underwrite url
      tenantToken = sub_host_parts[0]
      env = 'prod'
    } else if (sub_host_parts[1] === 'development') {
      tenantToken = sub_host_parts[0]
      env = 'development'
    } else {
      tenantToken = 'unknown'
      env = 'unknown'
    }
  } else {
    let potential_tenant_token = sub_host_parts[0]

    if (potential_tenant_token.includes('localhost')) {
      env = 'development'
      tenantToken = 'finvoice'
    } else if (potential_tenant_token.includes('staging')) {
      // keeping backward compatibility
      env = 'staging'
      tenantToken = potential_tenant_token.substring(0, potential_tenant_token.indexOf('staging'))
    } else {
      env = 'prod'
      tenantToken = potential_tenant_token
    }
  }

  if (tenantToken === 'primaryfundingdemo' && env === 'prod') {
    hotjar.initialize(TENANT_HOTJAR_IDS.primaryfundingdemo)
  }

  tenantToken = TENANT_TOKEN_LOOKUP[tenantToken] || tenantToken

  return {
    tenantToken: tenantToken,
    env: env,
  }
}
