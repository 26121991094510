import requestLegacy from 'legacy/_services/axios_service';
import { formatCase } from 'xen/helpers';
export const paramsSerializer = (params) => {
    const searchParams = new URLSearchParams();
    // Merge nested `pagination` and `sorting` params with the top level params
    const { pagination = {}, sorting = {}, ...rest } = params;
    const mergedParams = { ...rest, ...pagination, ...sorting };
    // Remove all params with null or undefined values
    const nonNullParams = Object.entries(mergedParams).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_key, value]) => value !== null && value !== undefined);
    nonNullParams.forEach(([key, value]) => {
        // Convert param key to snake_case
        const paramKey = formatCase.snake(key);
        if (Array.isArray(value)) {
            // Add array values as multiple params with `[]` appended to the key
            value.forEach((item) => searchParams.append(`${paramKey}[]`, String(item)));
        }
        else {
            // Add single values as a single param
            searchParams.append(paramKey, String(value));
        }
    });
    return searchParams.toString();
};
// This function is a wrapper around the legacy axios service.
// We will eventually replace this with a new axios instance.
function request(options) {
    // Use the default paramsSerializer if one is not already set
    if (!options.paramsSerializer)
        options.paramsSerializer = paramsSerializer;
    return requestLegacy(options);
}
export { request };
// Shared Transform Functions
export const transformPaginationKeys = (paginationKeys) => {
    const requestParams = {};
    if ('count' in paginationKeys && paginationKeys.count !== undefined)
        requestParams.count = paginationKeys.count;
    if ('items' in paginationKeys && paginationKeys.items !== undefined)
        requestParams.items = paginationKeys.items;
    if ('outset' in paginationKeys && paginationKeys.outset !== undefined)
        requestParams.outset = paginationKeys.outset;
    if ('page' in paginationKeys && paginationKeys.page !== undefined)
        requestParams.page = paginationKeys.page;
    if ('pageParam' in paginationKeys && paginationKeys.pageParam !== undefined)
        requestParams.page_param = paginationKeys.pageParam;
    if ('perPage' in paginationKeys && paginationKeys.perPage !== undefined)
        requestParams.per_page = paginationKeys.perPage;
    if ('size' in paginationKeys && paginationKeys.size !== undefined)
        requestParams.size = paginationKeys.size;
    if ('startIndex' in paginationKeys && paginationKeys.startIndex !== undefined)
        requestParams.start_index = paginationKeys.startIndex;
    return requestParams;
};
export const transformSortingKeys = (sortingKeys) => {
    const requestParams = {};
    if ('sortAsc' in sortingKeys && sortingKeys.sortAsc !== undefined)
        requestParams.sort_asc = sortingKeys.sortAsc;
    if ('sortBy' in sortingKeys && sortingKeys.sortBy !== undefined)
        requestParams.sort_by = sortingKeys.sortBy;
    return requestParams;
};
