import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformUserResponse, transformUserUpdatePayload } from './transform';
async function updateUser({ urlParams, payload }) {
    const { user } = payload;
    const { userId } = urlParams;
    try {
        const transformedPayload = {
            user: transformUserUpdatePayload(user),
        };
        const response = await request({
            method: 'PUT',
            url: `/users/${userId}`,
            data: transformedPayload,
        });
        const data = transformUserResponse(response);
        return data;
    }
    catch (error) {
        throw new Error('Could not update user');
    }
}
export const useUpdateUser = ({ urlParams: { userId } }) => {
    return useMutation({
        mutationKey: [`update-user`, userId],
        mutationFn: ({ payload }) => updateUser({ urlParams: { userId }, payload }),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (user) => {
            // user.name is not returned in this instance so we construct it with the available fields
            const { firstName, middleName, lastName } = user;
            notifications.success(`Updated user ${[firstName, middleName, lastName].join(' ').trim()}`);
            queryClient.invalidateQueries({ queryKey: ['users-search'] });
        },
    });
};
