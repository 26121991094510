import { FINANCING_TYPE_ABL } from '../../../_constants/financingTypes'
import { getMonthlySnapshots } from '../../../_services/abl/borrower/abl-borrower.service'
import { AverageInvoiceAmountReport } from './AverageInvoiceAmountReport'
import { getABLBusiness } from '../../../_services/abl/abl-business.service'

const AverageInvoiceAmountReportMeta = {
  clientSide: true,
  getReport: async (params, modalFunctions) => {
    let businessId = params.get('business_id')
    let ablBusiness = await getABLBusiness(businessId)
    let reportData = await getMonthlySnapshots(businessId, params)
    modalFunctions.closeModal()
    modalFunctions.openModal(AverageInvoiceAmountReport, { reportData, ablBusiness, params })
  },
  enabled: true,
  name: 'Average Invoice Amount',
  path: '',
  params: [
    {
      name: 'business_id',
      required: true,
      type: 'business',
      display_name: 'Client',
      options: [],
    },
    {
      name: 'start_date',
      required: false,
      type: 'date',
      display_name: 'Start Date',
      options: [],
    },
    {
      name: 'end_date',
      required: false,
      type: 'date',
      display_name: 'End Date',
      options: [],
    },
  ],
  scopes: ['business'],
  requiresProduct: FINANCING_TYPE_ABL,
}

export { AverageInvoiceAmountReportMeta as default }
