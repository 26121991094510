import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
import { transformInvoiceSettlementResponse } from 'xen/_modules/invoice-settlement/api/use-invoice-settlement/transform';
async function createInvoiceSettlement() {
    try {
        const response = await request({
            method: 'POST',
            url: `/operate/payment_batches`,
        });
        const data = transformInvoiceSettlementResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Error creating invoice settlement');
    }
}
export const useCreateInvoiceSettlement = () => {
    return useMutation({
        mutationFn: () => createInvoiceSettlement(),
        onSuccess: (invoiceSettlement) => {
            queryClient.setQueryData(['invoice-settlements', invoiceSettlement.id], invoiceSettlement);
        },
    });
};
