import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Anchor, Text } from 'xen-ui';
import { useXenHistory } from 'xen/hooks';
import { useUserContext } from 'xen/providers';
import { Page } from '../page';
import { PageTitle } from '../page-title';
const DefaultFallback = () => {
    return (_jsxs(Page, { "data-testid": "access-denied", children: [_jsx(PageTitle, { title: "403: Access denied" }), _jsxs(Text, { children: ["This page cannot be accessed. Contact", ' ', _jsx(Anchor, { href: "mailto:support@xenplatforms.com", underline: "always", children: "support@xenplatforms.com" }), ' ', "for more information."] })] }));
};
export const RestrictedAccess = ({ children, condition, fallback, userTypes = ['client', 'lender', 'prospect', 'pursuit'], }) => {
    const user = useUserContext();
    const history = useXenHistory();
    const conditionIsMet = typeof condition === 'function' ? condition({ history, user }) : true;
    const userTypesCanAccess = userTypes.includes(user.userType);
    if (conditionIsMet && userTypesCanAccess) {
        return children;
    }
    return fallback ?? _jsx(DefaultFallback, {});
};
RestrictedAccess.Fallback = DefaultFallback;
