import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { FeatureNotEnabled } from 'xen/components';
import { useFeatureFlags } from 'xen/hooks';
import { useUserContext } from 'xen/providers';
const TenantAdmin = lazy(() => import('legacy/_views/LenderViews/TenantAdmin'));
const General = lazy(() => import('../pages/general'));
const Factoring = lazy(() => import('../pages/factoring'));
const MainGlAccounts = lazy(() => import('../pages/main-gl-accounts'));
const DocumentTemplates = lazy(() => import('../pages/document-templates'));
const Notifications = lazy(() => import('../pages/notifications'));
const Users = lazy(() => import('../pages/users'));
const VerificationTypes = lazy(() => import('../pages/verification-types'));
const FeatureNotEnabledPage = () => _jsx(FeatureNotEnabled, { feature: "New Configurations and Settings UI" });
export const CONFIGURATIONS_PATHS = {
    root: '/configurations',
    users: '/configurations/users',
    factoring: '/configurations/factoring',
    mainGlAccounts: '/configurations/main-gl-accounts',
    documentTemplates: '/configurations/document-templates',
    notifications: '/configurations/notifications',
    verificationTypes: '/configurations/verification-types',
};
export const ConfigurationsRoutes = () => {
    const { isAdmin } = useUserContext();
    const { newConfigurationsAndSettingsUi } = useFeatureFlags();
    const { documentTemplates, factoring, mainGlAccounts, notifications, root, users, verificationTypes } = CONFIGURATIONS_PATHS;
    if (!isAdmin)
        return [];
    const routes = [
        { path: root, Page: General, Fallback: TenantAdmin },
        { path: users, Page: Users, Fallback: FeatureNotEnabledPage },
        { path: factoring, Page: Factoring, Fallback: FeatureNotEnabledPage },
        { path: mainGlAccounts, Page: MainGlAccounts, Fallback: FeatureNotEnabledPage },
        { path: documentTemplates, Page: DocumentTemplates, Fallback: FeatureNotEnabledPage },
        { path: notifications, Page: Notifications, Fallback: FeatureNotEnabledPage },
        { path: verificationTypes, Page: VerificationTypes, Fallback: FeatureNotEnabledPage },
    ];
    return routes.map(({ path, Page, Fallback }) => (_jsx(Route, { exact: true, path: path, children: newConfigurationsAndSettingsUi ? _jsx(Page, {}) : _jsx(Fallback, {}) }, path)));
};
