import React, { useEffect, useState } from 'react'
import { PieChartShadow } from './PieChartShadow'
import { Legend, Pie, Tooltip } from 'recharts'
import { PieChartActiveShape } from './PieChartActiveShape'
import startCase from 'lodash/startCase'
import { labelChartStyles } from '../Chart.styles'

import { S_PieChart, S_PieChartLegend } from './PieChart.styles'
import { ResponsiveContainer } from 'recharts'
import PropTypes from 'prop-types'
import { Skeleton } from '../../Skeleton/Skeleton'
import { S_NoData } from '../LineChart/LineChart.styles'

const colorPalette = [
  '#003366', // Midnight Blue
  '#004080', // Dark Cerulean
  '#0059B3', // Sapphire Blue
  '#0073E6', // Blue Ribbon
  '#3399FF', // Vivid Sky Blue
  '#66B2FF', // Sky Blue
  '#99CCFF', // Baby Blue
  '#CCE6FF', // Pale Blue
  '#E6F7FF', // Alice Blue
  '#F2FBFF', // Azure Mist
]

const renderLegend = (props) => {
  const { payload, dataKey, pieActiveIndex } = props

  return (
    <S_PieChartLegend>
      {payload.map((entry, index) => {
        const isActive = index === pieActiveIndex
        return (
          <li className={isActive ? 'active' : ''} key={`item-${index}`}>
            <span>{`${entry.value} (${entry.payload[dataKey]})`}</span>
          </li>
        )
      })}
    </S_PieChartLegend>
  )
}

export const PieChart = ({
  data,
  dataKey,
  nameKey,
  height,
  loading,
  onClick,
  onMouseOver,
  showLegend,
  showToolTip,
  toolTipFormatter,
  pieActiveIndexCb,
  animationDisabled,
  legendDataKey,
  skeletonAlign,
  showStaticActiveIndex = false,
  staticActiveIndex = null,
}) => {
  const sortedData = data.sort((a, b) => (+a[dataKey] > +b[dataKey] ? -1 : 1))
  legendDataKey = legendDataKey ? legendDataKey : dataKey
  const shadowId = 'PieChartShadow'

  const [pieActiveIndex, setPieActiveIndex] = useState(null)
  const [pieActiveStaticIndex, setPieActiveStaticIndex] = useState(staticActiveIndex)
  useEffect(() => {
    if (!pieActiveIndexCb) {
      return
    }
    pieActiveIndexCb(pieActiveIndex)
  }, [pieActiveIndex])

  useEffect(() => {
    if (!showStaticActiveIndex) {
      return
    }
    setPieActiveStaticIndex(staticActiveIndex)
  }, [staticActiveIndex])

  if (loading && !data.length) {
    return (
      <Skeleton
        align={skeletonAlign}
        height={`${height}px`}
        isLoaded={!loading}
        variant={'circle'}
        width={`${height}px`}
      />
    )
  }

  if (!data || !data.length) {
    return <S_NoData height={height} rounded={true} />
  }

  const showStaticIndex = pieActiveStaticIndex !== null && pieActiveIndex === null
  return (
    <ResponsiveContainer height={height}>
      <S_PieChart
        activeShapeMode={showStaticIndex || pieActiveIndex !== null}
        animationDisabled={animationDisabled}
        height={height}
        width={height}
      >
        <defs>
          <PieChartShadow id={shadowId} />
        </defs>

        {showLegend ? (
          <Legend
            align={'left'}
            content={renderLegend}
            dataKey={legendDataKey}
            layout={'vertical'}
            nameKey={nameKey}
            pieActiveIndex={showStaticIndex ? pieActiveStaticIndex : pieActiveIndex}
            verticalAlign={'middle'}
          />
        ) : null}

        <Pie
          activeIndex={showStaticIndex ? pieActiveStaticIndex : pieActiveIndex}
          activeShape={PieChartActiveShape}
          cx="50%"
          cy="50%"
          data={sortedData.map((key, i) => ({
            ...key,
            strokeWidth: 1,
            filter: `url(#${shadowId})`,
            fill: colorPalette[i % colorPalette.length], // Use colors from the palette
          }))}
          dataKey={dataKey}
          isAnimationActive={false}
          nameKey={nameKey}
          onClick={(data, index) => {
            onClick(data, index)
            setPieActiveStaticIndex(index)
            setPieActiveIndex(index)
          }}
          onMouseLeave={() => {
            setPieActiveIndex(null)
          }}
          onMouseOver={(data, index) => {
            setPieActiveIndex(index)
            onMouseOver(data, index)
          }}
          outerRadius={(height - 10) / 2}
        />

        {showToolTip ? (
          <Tooltip
            active={true}
            contentStyle={labelChartStyles}
            cursor={{ cursor: 'pointer' }}
            formatter={toolTipFormatter}
            labelFormatter={(name) => `${name}`}
          />
        ) : null}
      </S_PieChart>
    </ResponsiveContainer>
  )
}

PieChart.propTypes = {
  /**
   * The source data which each element is an object.
   */
  data: PropTypes.array,
  /**
   * Prevents render
   */
  loading: PropTypes.bool,
  /**
   * Height for ResponsiveChart and PieChart components
   */
  height: PropTypes.number,
  /**
   * Event handler of click on the sectors
   */
  onClick: PropTypes.func,
  /**
   * Shows the names and values of each series of data
   *
   * [Recharts doc](https://recharts.org/en-US/api/Legend)
   */
  showLegend: PropTypes.bool,
  /**
   * The key of each sector's value.
   */
  dataKey: PropTypes.string,
  /**
   * The key of each sector's name.
   */
  nameKey: PropTypes.string,
  /**
   * Formatter handler for label on hover
   */
  toolTipFormatter: PropTypes.func,
  /**
   * Shows Tooltip on hover
   */
  showToolTip: PropTypes.bool,
  /**
   * Callback function that recieves active index of PieChart on hover
   */
  pieActiveIndexCb: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * Alignment for loading state of Skeleton
   */
  skeletonAlign: PropTypes.string,
}

PieChart.defaultProps = {
  data: [],
  loading: false,
  height: 250,
  onClick: () => {},
  showLegend: false,
  toolTipFormatter: (value, name) => [`${value} ${startCase(name)}`],
  showToolTip: true,
  pieActiveIndexCb: false,
  legendDataKey: false,
  onMouseOver: () => {},
  skeletonAlign: '',
}
