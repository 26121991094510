import { jsx as _jsx } from "react/jsx-runtime";
import { NavLink as RouterNavLink } from 'react-router-dom';
import { UnstyledButton, XenIcon, NavLink as XenNavLink } from 'xen-ui';
import { createInternalLinkUrl } from 'xen/helpers';
/**
 * A wrapper around the `NavLink` component that handles internal
 * nav links.
 *  The component will render a `Link` component from `react-router-dom`
 * and only allow valid internal link paths based on the `XenPath`
 * type and require the correct number of dynamic
 * URL params (0-3).
 *
 * NOTE: `createInternalLinkUrl` only supports a maximum of 3 dynamic URL
 * params. If you need more, update the `LinkProps` type in `xen/types/link.ts`
 * or manually construct your own link component and linkUrl.
 */
const NavLink = ({ searchParams = {}, icon, to, urlParams, ...props }) => {
    const Icon = icon ? XenIcon[icon] : undefined;
    // @ts-expect-error TODO: fix type errors
    const linkUrl = createInternalLinkUrl({ to, searchParams, urlParams });
    // Active prop triggered for root route and nested routes
    const active = location.pathname.startsWith(to);
    return (_jsx(XenNavLink, { ...props, active: active, component: RouterNavLink, ...(Icon ? { leftSection: _jsx(Icon, { fontSize: "lg" }) } : {}), to: linkUrl }));
};
/**
 * A wrapper around the `NavLink` component that creates a a button for usage
 * in creating navigation categories or handling internal functionality that is
 * part of the navigation (example: Signout).
 */
const NavButton = ({ icon, ...props }) => {
    const Icon = icon ? XenIcon[icon] : undefined;
    return _jsx(XenNavLink, { ...props, component: UnstyledButton, ...(Icon ? { leftSection: _jsx(Icon, { fontSize: "lg" }) } : {}) });
};
export const NavItem = {
    Link: NavLink,
    Button: NavButton,
};
