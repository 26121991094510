import styled, { css } from 'styled-components'
import { addMediaStyles } from '../../../_constants/stylesMediaBreakpoints'

export const S_EsigningDocument = styled.div`
  overflow: scroll;
  height: 100%;

  #esigning-container {
    width: 100%;
    height: calc(100% - 70px);

    @media screen and (min-width: 768px) {
      width: 800px;
      height: auto;
    }
  }

  .e-sign-title {
    ${addMediaStyles(
      'xs',
      css`
        font-size: 20px;
      `
    )}
    ${addMediaStyles(
      'md',
      css`
        font-size: 24px;
      `
    )}
  }
`
