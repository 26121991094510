import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useGetNavigation } from 'xen/api/use-navigation';
import { FeatureNotEnabled } from 'xen/components';
const LinkedExternalAccounts = lazy(() => import('../pages/linked-external-accounts'));
export const LINKED_EXTERNAL_ACCOUNTS_PATHS = {
    root: '/linked-external-accounts',
};
export const LinkedExternalAccountsRoutes = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    if (isLoading)
        return [];
    const featureEnabled = isSuccess && !!navigation?.linkedExternalAccounts;
    return [
        _jsx(Route, { path: LINKED_EXTERNAL_ACCOUNTS_PATHS.root, children: featureEnabled ? _jsx(LinkedExternalAccounts, {}) : _jsx(FeatureNotEnabled, { feature: "Linked External Accounts page" }) }, LINKED_EXTERNAL_ACCOUNTS_PATHS.root),
    ];
};
