const easilyFormattedNumber = (num) => {
  if (num == 0) {
    return 0
  }

  let expToSubtract = Number(num.toExponential().split('+')[1]) % 3
  let exponential = Number(num.toExponential().split('+')[1]) - expToSubtract
  let tickNumber = Math.floor(num / 10 ** exponential)
  while (tickNumber % 5 != 0) {
    tickNumber++
  }
  tickNumber *= 10 ** exponential

  return tickNumber
}

export const generateNiceTicks = (data, dataKey, numTicks = 5) => {
  let numbers = data.map((d) => Number(d[dataKey]))

  let max = Math.max(...numbers)
  let expToSubtract = Number(max.toExponential().split('+')[1]) % 3
  let exponential = Number(max.toExponential().split('+')[1]) - expToSubtract
  let maxTick = Math.ceil(max / 10 ** exponential)

  if (!maxTick) {
    return []
  }
  if (maxTick >= 10) {
    while (maxTick % 5 != 0) {
      maxTick++
    }
    maxTick *= 10 ** exponential

    let tickSize = maxTick / numTicks
    if (tickSize / 10 ** exponential < 10) {
      tickSize = 5 * 10 ** exponential
    }

    const niceTicks = [0]
    while (niceTicks[niceTicks.length - 1] < maxTick) {
      niceTicks.push(niceTicks.length * tickSize)
    }

    let formatted = niceTicks.map(easilyFormattedNumber).reduce((prev, current) => {
      if (!prev.includes(current) && current <= maxTick) {
        prev.push(current)
      }
      return prev
    }, [])
    if (formatted.length > 3) {
      let secondToLastDistance = formatted[formatted.length - 2] - formatted[formatted.length - 3]
      let lastDistance = formatted[formatted.length - 1] - formatted[formatted.length - 2]
      if (secondToLastDistance != lastDistance) {
        formatted[formatted.length - 1] = formatted[formatted.length - 2] + secondToLastDistance
      }
      return formatted
    } else {
      return formatted
    }
  } else {
    const niceTicks = [0]
    while (niceTicks.length <= maxTick) {
      niceTicks.push(niceTicks.length)
    }
    return niceTicks.map((tick) => {
      return tick * 10 ** exponential
    })
  }
}
