import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { queryClient } from 'xen/providers';
import { transformPaymentParamsToPaymentParamsPayload, transformPaymentResponseToPayment, } from 'xen/_modules/invoice-settlement/api/use-payment/transform';
async function createPayment(createData = {}) {
    try {
        // Set date to current date if not provided
        createData.date = createData.date ?? new Date();
        const payloadData = transformPaymentParamsToPaymentParamsPayload(createData);
        const response = await request({
            method: 'POST',
            url: `/operate/checks`,
            data: {
                collections_check: { ...payloadData },
            },
        });
        const data = transformPaymentResponseToPayment(response);
        return data;
    }
    catch (_error) {
        throw Error('Error creating payment');
    }
}
export const useCreatePayment = () => {
    return useMutation({
        mutationFn: (data = {}) => createPayment(data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['payments'] });
        },
    });
};
