import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Link } from 'react-router-dom'
import { FaPhone, FaCog, FaRegBell } from 'react-icons/fa'
import get from 'lodash/get'

// CONSTANTS
import urls from '../../_constants/urls'
import colors, { tenantColors } from '../../_constants/colors'
import { headerConfig, metadataConfig } from '../../_configs'
import ROUTES, { LENDER_ROUTES } from '../../_constants/routes'

// Utilities
import { isLender } from '../../_helpers/user-utils'
import { isFeatureEnabled } from '../../_helpers/flags'

// Components
import { EsigningPill } from './EsigningPill'
import { MenuToggle } from './MenuToggle'
import ImgLink from '../ImgLink'

import { TopNavBar } from '../TopNavBar/TopNavBar'
import { TopNavBarSub } from '../TopNavBar/TopNavBarSub'

import { S_HeaderBar } from './Header.styles'
import { PRODUCT_FLAGS } from '../../_helpers/featureFlags/flags.constants'

// Hooks
import { useFlags } from '../../_reduxHooks/flags.hook'

export const Header = () => {
  let env = useSelector((state) => state.env)
  let authentication = useSelector((state) => state.authentication)
  let user = useSelector((state) => state.user)
  let esigning = useSelector((state) => state.esigning)
  const { featureFlags } = useFlags()

  const environment = env.env
  const tenant = env?.tenant || 'finvoice'
  const loggedIn = authentication.loggedIn

  const customColors = tenantColors[tenant]
  const config = headerConfig[tenant]
  const metadata = metadataConfig[tenant]
  const headerRef = useRef()

  const isUserLender = isLender(loggedIn, user?.user_type)

  const isESigningPillEnabled = isFeatureEnabled('esigning_pill', featureFlags)
  const isABLEnabled = isFeatureEnabled(PRODUCT_FLAGS.ABL, featureFlags)
  const isUserNotificationsEnabled = isFeatureEnabled('user-notifications', featureFlags)
  const productSuffix = isABLEnabled ? '_abl' : ''

  const [subNav, setSubNav] = useState([])

  const scrollToTop = get(location, 'state.scrollToHeader', false)
  useEffect(() => {
    if (scrollToTop) {
      headerRef.current.scrollIntoView()
    }
  }, [scrollToTop])

  const CustomHeaderLogo = () => {
    return (
      <img
        alt={`${metadata.tenant_name}`}
        className="header-logo"
        src={`${urls.IMAGES}/${environment}/${tenant}/header_logo${productSuffix}.png`}
      />
    )
  }

  const shouldDisplayTenantSettings = user?.user_type == 'lender' && user.is_admin
  const shouldDisplayUserNotifications = loggedIn && isUserNotificationsEnabled

  return (
    <div>
      <S_HeaderBar customColors={customColors} isUserLender={isUserLender} ref={headerRef}>
        <div className="header-wrapper">
          <div className="header-left">
            <a className="brand-link" data-testid="brand-link" href={metadata.link_landing_page}>
              <CustomHeaderLogo />
            </a>
          </div>

          {isUserLender && (
            <div className="header-nav">
              <TopNavBar customColors={customColors} isUserLender={isUserLender} setSubNav={setSubNav} user={user} />
            </div>
          )}

          <div className="header-right">
            {loggedIn
              ? [
                  isESigningPillEnabled && esigning.requests && esigning.requests.length > 0 && (
                    <EsigningPill key={0} />
                  ),
                  <span className={`logged-in-email link`} key={1}>
                    {user.email_address}
                  </span>,
                ]
              : [
                  <NavLink activeClassName={'active'} className="link sign-up-link" key={0} to={ROUTES.sign_up}>
                    Sign Up
                  </NavLink>,
                  <NavLink activeClassName={'active'} className="link sign-in-link" key={1} to={ROUTES.sign_in}>
                    Sign In
                  </NavLink>,
                ]}

            {config
              .filter((item) => item.type === 'imglink')
              .map((item, i) => (
                <ImgLink
                  alt={item.label}
                  className={'link img-link'}
                  href={item.link}
                  key={i}
                  src={item.image}
                  target={`_blank`}
                  title={item.label}
                  width={`75px`}
                />
              ))}
            {!isUserLender &&
              config
                .filter((item) => item.type !== 'imglink')
                .map((item, i) => (
                  <a className={`link tel-link`} href={item.link} key={i}>
                    {item.type === `phone` && <FaPhone className={`icon`} />}
                    {item.label}
                  </a>
                ))}

            <div className="menu-wrapper">
              <MenuToggle />
            </div>
            {shouldDisplayUserNotifications && (
              <div className="notification-wrapper">
                <Link to={ROUTES.user_alerts}>
                  <FaRegBell color={colors.GREY_LIGHT_FILLS} size={20} />
                </Link>
              </div>
            )}
            {loggedIn && shouldDisplayTenantSettings && (
              <Link to={LENDER_ROUTES.admin}>
                <FaCog color={colors.GREY_LIGHT_FILLS} size={20} />
              </Link>
            )}
            {loggedIn && !shouldDisplayTenantSettings && (
              <Link to={LENDER_ROUTES.settings}>
                <FaCog color={colors.GREY_LIGHT_FILLS} size={20} />
              </Link>
            )}
          </div>
        </div>
      </S_HeaderBar>

      {isUserLender && <TopNavBarSub subNav={subNav} />}
    </div>
  )
}
