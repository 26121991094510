import React, { lazy } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Constants
import ROUTES, { LENDER_ROUTES } from '../_constants/routes'

// Helpers
import { isLender } from '../_helpers/user-utils'
import { isFeatureEnabled, isProductEnabled } from '../_helpers/flags'

export const FactoringLenderRoutes = ({ loggedIn, user, featureFlags }) => {
  // Only for factoring lenders
  if (!isLender(loggedIn, user.user_type) || !isProductEnabled('Factoring', featureFlags)) {
    return null
  }
  let loadedModules = {}

  const isNewInvoiceRedesign = isFeatureEnabled('new_invoice_redesign', featureFlags)
  const createInvoicesModule = isNewInvoiceRedesign ? 'CreateInvoicesRedesign' : 'CreateInvoices'
  const pageComponents = {
    FactoringLenderDashboard: import(`../_views/FactoringLender/FactoringLenderDashboard/index.js`).then((m) => {
      loadedModules['FactoringLenderDashboard'] = m
      return m
    }),
    NewCollateralPage: import('../_views/LenderViews/NewCollateralPage/index.js').then((m) => {
      loadedModules['NewCollateralPage'] = m
      return m
    }),
    InvoiceSchedule: import('../_views/factoring/lender/InvoiceSchedule/index.js').then((m) => {
      loadedModules['InvoiceSchedule'] = m
      return m
    }),
    InvoiceUpload: import('../_views/factoring/common/CreateInvoicesRedesign/InvoiceUpload').then((m) => {
      loadedModules['InvoiceUpload'] = m
      return m
    }),
    DebtorPaymentsOverview: import('../_views/FactoringLender/DebtorPaymentsOverview/index.js').then((m) => {
      loadedModules['DebtorPaymentsOverview'] = m
      return m
    }),
    DebtorPaymentsUpload: import('../_views/FactoringLender/DebtorPaymentsUpload/index.js').then((m) => {
      loadedModules['DebtorPaymentsUpload'] = m
      return m
    }),
    DebtorPaymentsCreate: import('../_views/FactoringLender/DebtorPaymentsCreate/index.js').then((m) => {
      loadedModules['DebtorPaymentsCreate'] = m
      return m
    }),
    DebtorPaymentDetails: import('../_views/FactoringLender/DebtorPaymentDetails/index.js').then((m) => {
      loadedModules['DebtorPaymentDetails'] = m
      return m
    }),
    PaymentBatchDetails: import('../_views/FactoringLender/PaymentBatchDetails/index.js').then((m) => {
      loadedModules['PaymentBatchDetails'] = m
      return m
    }),
    PaymentBatchList: import('../_views/FactoringLender/PaymentBatchList/index.js').then((m) => {
      loadedModules['PaymentBatchList'] = m
      return m
    }),
    ApplyPayment: import('../_views/FactoringLender/DebtorPaymentDetails/index_applypayment').then((m) => {
      loadedModules['ApplyPayment'] = m
      return m
    }),
    CreateInvoices: import('../_views/factoring/common/CreateInvoices').then((m) => {
      loadedModules['CreateInvoices'] = m
      return m
    }),
    CreateInvoicesRedesign: import('../_views/factoring/common/CreateInvoicesRedesign').then((m) => {
      loadedModules['CreateInvoicesRedesign'] = m
      return m
    }),
    FundingRequestPage: import('../_views/LenderViews/FundingRequestPage/FundingRequestPageFactoring/index.js').then(
      (m) => {
        loadedModules['FundingRequestPage'] = m
        return m
      }
    ),
    FundingRequestOverview: import('../_views/LenderViews/FundingRequestOverview').then((m) => {
      loadedModules['FundingRequestOverview'] = m
      return m
    }),
    FundingRequestCreate: import('../_views/LenderViews/FundingRequestCreate/index.js').then((m) => {
      loadedModules['FundingRequestCreate'] = m
      return m
    }),
    GeneratedReports: import('../_views/LenderViews/GeneratedReports/index').then((m) => {
      loadedModules['GeneratedReports'] = m
      return m
    }),
    Brokers: import('../_views/LenderViews/Brokers/index').then((m) => {
      loadedModules['Brokers'] = m
      return m
    }),
    BrokersEdit: import('../_views/LenderViews/BrokersEdit').then((m) => {
      loadedModules['BrokersEdit'] = m
      return m
    }),
  }
  const factoringLenderRoutes = [
    // Factoring Lender Dashboard
    {
      path: ROUTES.lender_home,
      Component: lazy(() => pageComponents['FactoringLenderDashboard']),
      module: 'FactoringLenderDashboard',
    },
    {
      path: LENDER_ROUTES.brokers + '/:id',
      Component: lazy(() => pageComponents['BrokersEdit']),
      exact: true,
      module: 'BrokersEdit',
    },
    {
      path: LENDER_ROUTES.brokers,
      Component: lazy(() => pageComponents['Brokers']),
      module: 'Brokers',
      exact: true,
    },
    // Invoice Schedules
    {
      path: LENDER_ROUTES.schedules_incomplete,
      Component: lazy(() => pageComponents['NewCollateralPage']),
      exact: true,
      module: 'NewCollateralPage',
    },
    {
      path: LENDER_ROUTES.schedules,
      Component: () => <Redirect to={LENDER_ROUTES.schedules_pending} />,
      exact: true,
    },
    {
      path: LENDER_ROUTES.schedules_pending,
      Component: lazy(() => pageComponents['NewCollateralPage']),
      exact: true,
      module: 'NewCollateralPage',
    },
    {
      path: LENDER_ROUTES.schedules_approved,
      Component: lazy(() => pageComponents['NewCollateralPage']),
      exact: true,
      module: 'NewCollateralPage',
    },
    {
      path: LENDER_ROUTES.schedules_all,
      Component: lazy(() => pageComponents['NewCollateralPage']),
      exact: true,
      module: 'NewCollateralPage',
    },
    {
      path: LENDER_ROUTES.schedules + '/:id',
      Component: lazy(() => pageComponents['InvoiceSchedule']),
      exact: true,
      module: 'InvoiceSchedule',
    },
    {
      path: LENDER_ROUTES.schedules + '/:id/upload',
      Component: lazy(() => pageComponents['InvoiceUpload']),
      exact: true,
      module: 'InvoiceUpload',
    },
    {
      path: LENDER_ROUTES.schedules + '/:id/edit',
      Component: lazy(() => pageComponents[createInvoicesModule]),
      exact: true,
      module: createInvoicesModule,
    },
    // Debtor Payments
    {
      path: LENDER_ROUTES.debtor_payments_overview,
      Component: lazy(() => pageComponents['DebtorPaymentsOverview']),
      exact: true,
      module: 'DebtorPaymentsOverview',
    },
    {
      path: LENDER_ROUTES.debtor_payments_batches_list,
      Component: lazy(() => pageComponents['PaymentBatchList']),
      exact: true,
      module: 'PaymentBatchList',
    },
    {
      path: LENDER_ROUTES.debtor_payments_batches + '/:status',
      Component: lazy(() => pageComponents['DebtorPaymentsOverview']),
      module: 'DebtorPaymentsOverview',
    },
    {
      path: LENDER_ROUTES.debtor_payments_upload,
      Component: lazy(() => pageComponents['DebtorPaymentsUpload']),
      exact: true,
      module: 'DebtorPaymentsUpload',
    },
    {
      path: LENDER_ROUTES.debtor_payments_create,
      Component: lazy(() => pageComponents['DebtorPaymentsCreate']),
      exact: true,
      module: 'DebtorPaymentsCreate',
    },
    {
      path: LENDER_ROUTES.debtor_payments_batch + '/:id',
      Component: lazy(() => pageComponents['PaymentBatchDetails']),
      exact: true,
      module: 'PaymentBatchDetails',
    },
    {
      path: LENDER_ROUTES.debtor_payments_batch + '/:id/payment/:checkid',
      Component: lazy(() => pageComponents['DebtorPaymentsCreate']),
      exact: true,
      module: 'DebtorPaymentsCreate',
    },
    {
      path: LENDER_ROUTES.debtor_payments_overview + '/:id',
      Component: lazy(() => pageComponents['DebtorPaymentDetails']),
      exact: true,
      module: 'DebtorPaymentDetails',
    },
    {
      path: LENDER_ROUTES.debtor_payments_overview + '/:id/:transactionType',
      Component: lazy(() => pageComponents['ApplyPayment']),
      module: 'ApplyPayment',
    },
    // Funding Requests
    {
      path: LENDER_ROUTES.funding_requests,
      Component: () => <Redirect to={LENDER_ROUTES.funding_requests_pending} />,
      exact: true,
    },
    {
      path: LENDER_ROUTES.funding_requests_pending,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_approved,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_funded,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_rejected,
      Component: lazy(() => pageComponents['FundingRequestOverview']),
      exact: true,
      module: 'FundingRequestOverview',
    },
    {
      path: LENDER_ROUTES.funding_requests_create,
      Component: lazy(() => pageComponents['FundingRequestCreate']),
      module: 'FundingRequestCreate',
    },
    {
      path: LENDER_ROUTES.funding_requests + '/:fundingRequestId',
      Component: lazy(() => pageComponents['FundingRequestPage']),
      module: 'FundingRequestPage',
    },
    {
      path: LENDER_ROUTES.generated_reports,
      Component: lazy(() => pageComponents['GeneratedReports']),
      module: 'GeneratedReports',
    },
  ]

  return factoringLenderRoutes.map(({ path, Component, exact = false, module }) => (
    // Butt ugly, but this prevents the suspense fallback component from displaying unnecessicarily
    <Route
      exact={exact}
      key={path}
      path={path}
      render={(props) => {
        if (module && loadedModules[module]) {
          //Set status to resolved and result to default export of downloaded module to prevent briefly loading suspense component. See https://github.com/facebook/react/blob/master/packages/react/src/ReactLazy.js
          Component._status = 1
          Component._result = loadedModules[module].default
        }
        return <Component {...props} />
      }}
    />
  ))
}
