export const transformCreateLinkTokenResponse = (response) => {
    return { linkToken: response.link_token, expiration: new Date(response.expiration), requestId: response.request_id };
};
export const transformCreateApplicationAccessResponse = (response) => {
    return {
        id: response.id,
        entityId: response.entity_id,
        entityType: response.entity_type,
        institutionId: response.institution_id,
        institutionName: response.institution_name,
        createdAt: new Date(response.created_at),
        updatedAt: new Date(response.updated_at),
    };
};
export const transformAccountsResponse = (response) => {
    return response.data.map((account) => {
        return {
            id: account.id,
            name: account.name,
            mask: account.mask,
            officialName: account.official_name,
            accountType: account.account_type,
            accountSubtype: account.account_subtype,
            integrationApplicationAccessId: account.integration_application_access_id,
            institution: account.institution,
            createdAt: new Date(account.created_at),
            updatedAt: new Date(account.updated_at),
        };
    });
};
export const transformTransactionsResponse = (response) => {
    return response.map((transaction) => {
        return {
            id: transaction.id,
            accountOwner: transaction.account_owner,
            allCategories: transaction.all_categories,
            amount: transaction.amount,
            category: transaction.category,
            checkNumber: transaction.check_number,
            createdAt: new Date(transaction.created_at),
            date: new Date(transaction.date),
            merchantName: transaction.merchant_name,
            name: transaction.name,
            paymentChannel: transaction.payment_channel,
            pending: transaction.pending,
            transactionCode: transaction.transaction_code,
            transactionDate: new Date(transaction.transaction_date),
            transactionType: transaction.transaction_type,
            transferMethod: transaction.transfer_method,
            transferPayer: transaction.transfer_payer,
            transferReason: transaction.transfer_reason,
            transferReferenceNumber: transaction.transfer_reference_number,
            updatedAt: new Date(transaction.updated_at),
        };
    });
};
