import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button, Group, Stack } from 'xen-ui';
import { ErrorText } from 'xen/components';
import { FormDateInput } from 'xen/components/form';
import { useCreateUnprocessState, useGetPayments, useUpdateProcessState, } from '../../api/';
const settlementProcessFormSchema = z.object({
    date: z.date(),
});
const FORM_BUTTON_TYPES = ['process', 'unprocess'];
export const SettlementProcessForm = ({ formButtons = FORM_BUTTON_TYPES, settlement, expectedError, }) => {
    const { data: payments = [], isLoading: isPaymentsLoading } = useGetPayments({
        paymentBatchId: String(settlement.id),
    });
    const defaultValues = {
        date: new Date(),
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(settlementProcessFormSchema),
        mode: 'onBlur',
        values: defaultValues,
    });
    const { mutate: processSettlement, isError: isProcessError, isPending: isProcessPending } = useUpdateProcessState();
    const { mutate: unprocessSettlement, isError: isUnprocessError, isPending: isUnprocessPending, } = useCreateUnprocessState();
    const onProcessSubmit = async ({ date }) => {
        const payload = {
            batchNumber: settlement.batchNumber,
            id: settlement.id,
            date,
        };
        processSettlement(payload);
    };
    const onUnprocessSubmit = async ({ date }) => {
        const payload = {
            batchNumber: settlement.batchNumber,
            id: settlement.id,
            date,
        };
        unprocessSettlement(payload);
    };
    const handleProcess = () => {
        handleSubmit(onProcessSubmit)();
    };
    const handleUnprocess = () => {
        handleSubmit(onUnprocessSubmit)();
    };
    const handleError = (e) => {
        console.error('Form error:', e);
    };
    // Filter payments with error keys that have non-null values
    const hasPaymentErrors = payments
        .map((p) => Object.values(p.errors))
        .flat()
        .filter(Boolean).length > 0;
    // Would prefer to handle all error messages client-side, but the server
    // sometimes returns multiple error messages on the same key (e.g.
    // collectionsChecks). In the case of amountPosted, we need to want to display
    // a different message string (using 'payment amount' instead of 'value') and
    // the blank amount error can be handled client-side.
    const errors = [];
    if (isProcessError)
        errors.push('Error processing invoice settlement');
    if (isUnprocessError)
        errors.push('Error unprocessing invoice settlement');
    if (hasPaymentErrors)
        errors.push('All payment errors must be fixed before processing');
    if (settlement.errors.amountPosted)
        errors.push('Amount posted is greater than payment amount');
    if (expectedError)
        errors.push(expectedError);
    const hasErrors = errors.length > 0 || !!expectedError;
    const isPending = isProcessPending || isUnprocessPending || isPaymentsLoading;
    // We should be able to include a date everytime we process or unprocess
    const isDateDisabled = hasErrors || isPending;
    // We should be able to process a settlement in any state except for completed
    const isProcessDisabled = hasErrors || isPending || settlement.state === 'completed';
    // We should be able to unprocess a settlement only when the state is completed
    const isUnprocessDisabled = isPending || settlement.state !== 'completed';
    return (_jsx(Form, { control: control, onError: handleError, children: _jsxs(Stack, { align: "flex-end", gap: "xs", children: [_jsxs(Group, { align: "flex-end", children: [_jsx(FormDateInput, { control: control, disabled: isDateDisabled, label: "Date Posted", maxDate: new Date(), name: "date" }), formButtons.includes('unprocess') && (_jsx(Button, { color: "red", disabled: isUnprocessDisabled, loading: isUnprocessPending, onClick: handleUnprocess, children: "Unprocess" })), formButtons.includes('process') && (_jsx(Button, { disabled: isProcessDisabled, loading: isProcessPending, onClick: handleProcess, children: "Process" }))] }), hasErrors && _jsx(ErrorText, { message: `${errors.filter(Boolean).join('. ')}.` })] }) }));
};
