import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
const InvoicesOverview = lazy(() => import('legacy/_views/common/lender/InvoicesOverview'));
const Invoices = () => {
    {
        /*
          Should eventually be replaced by new Xen 1.0 Invoices page
          Setting this to all invoices for now, the filter allows to filter
          to the invoice state "Purchased" but that's all that's available
        */
    }
    return _jsx(InvoicesOverview, { match: { path: 'invoices/all' } });
};
export default Invoices;
