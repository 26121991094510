import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BasicTable } from 'xen-ui';
import { formatDate } from 'xen/helpers/format';
import { useGetTransactions } from 'xen/_modules/linked-external-accounts/api';
export const PlaidTransactions = ({ accountId }) => {
    const { data: transactions = [], isLoading, isError } = useGetTransactions(accountId);
    if (isError)
        return _jsx("div", { children: "Error getting transactions. Please try again." });
    if (isLoading)
        return _jsx("div", { children: "Loading..." });
    if (transactions?.length === 0)
        return _jsx("div", { children: "No transactions found" });
    return (_jsxs(BasicTable, { style: { textAlign: 'left' }, children: [_jsx(BasicTable.Thead, { children: _jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Th, { children: "Date" }), _jsx(BasicTable.Th, { children: "Type" }), _jsx(BasicTable.Th, { children: "Name" }), _jsx(BasicTable.Th, { children: "Category" }), _jsx(BasicTable.Th, { children: "Check number" }), _jsx(BasicTable.Th, { children: "Payer" }), _jsx(BasicTable.Th, { children: "Amount" })] }) }), _jsx(BasicTable.Tbody, { children: transactions?.map((transaction) => (_jsxs(BasicTable.Tr, { children: [_jsx(BasicTable.Td, { children: formatDate.localeDateString(transaction.date) }), _jsx(BasicTable.Td, { children: transaction.transactionType }), _jsx(BasicTable.Td, { children: transaction.name }), _jsx(BasicTable.Td, { children: transaction.category }), _jsx(BasicTable.Td, { children: transaction.checkNumber }), _jsx(BasicTable.Td, { children: transaction.transferPayer }), _jsx(BasicTable.Td, { children: transaction.amount })] }, transaction.id))) })] }));
};
