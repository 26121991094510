import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformPaymentAssignmentParams, transformPaymentAssignmentResponse, } from 'xen/_modules/invoice-settlement/api/use-invoice-settlement/transform';
async function updatePaymentAssignment(params) {
    try {
        const payloadData = transformPaymentAssignmentParams(params.data);
        const response = await request({
            method: 'PUT',
            url: `/operate/payment_assignments/${params.id}`,
            data: payloadData,
        });
        const data = transformPaymentAssignmentResponse(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not update assigned invoice');
    }
}
export const useUpdatePaymentAssignment = (paymentId) => {
    return useMutation({
        mutationKey: ['update-payment-assignment', paymentId],
        mutationFn: (params) => updatePaymentAssignment(params),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (assignment) => {
            notifications.success(`Updated assigned invoice ${assignment.invoice.invoiceNumber}`);
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
export const useIsPaymentAssignmentUpdating = (paymentId) => {
    return useIsMutating({ mutationKey: ['update-payment-assignment', paymentId] });
};
