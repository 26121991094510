import { useIsMutating, useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers';
import { transformPaymentParamsToPaymentParamsPayload, transformPaymentResponseToPayment, } from 'xen/_modules/invoice-settlement/api/use-payment/transform';
async function updatePayment(id, updateData) {
    try {
        const payloadData = transformPaymentParamsToPaymentParamsPayload(updateData);
        const response = await request({
            method: 'PUT',
            url: `/operate/checks`,
            data: {
                id,
                collections_check: { ...payloadData },
            },
        });
        const data = transformPaymentResponseToPayment(response);
        return data;
    }
    catch (_error) {
        throw Error('Could not update payment');
    }
}
export const useUpdatePayment = (id) => {
    return useMutation({
        mutationKey: ['update-payment', id],
        mutationFn: ({ id, data }) => updatePayment(id, data),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (payment) => {
            notifications.success(`Updated payment ${payment.checkNumber}`.trim());
            queryClient.invalidateQueries({ queryKey: ['payments'] });
            queryClient.invalidateQueries({ queryKey: ['payment-assignments'] });
            queryClient.invalidateQueries({ queryKey: ['invoice-settlements'] });
        },
    });
};
export const useIsPaymentUpdating = (id) => {
    return useIsMutating({ mutationKey: ['update-payment', id] });
};
