import { jsx as _jsx } from "react/jsx-runtime";
import { createPolymorphicComponent, AppShell as MantineAppShell, } from '@mantine/core';
import styles from './app-shell.module.css';
const AppShell = (props) => {
    return _jsx(MantineAppShell, { ...props });
};
const Footer = (props) => {
    return _jsx(MantineAppShell.Footer, { ...props });
};
AppShell.Footer = Footer;
const Aside = (props) => {
    return _jsx(MantineAppShell.Aside, { ...props });
};
AppShell.Aside = Aside;
const Main = (props) => {
    return _jsx(MantineAppShell.Main, { ...props });
};
AppShell.Main = Main;
const NavBar = (props) => {
    return _jsx(MantineAppShell.Navbar, { className: styles['xen-navbar'], ...props });
};
AppShell.Navbar = NavBar;
const _Section = (props) => {
    return _jsx(MantineAppShell.Section, { ...props });
};
export const Section = createPolymorphicComponent(_Section);
AppShell.Section = Section;
export { AppShell };
