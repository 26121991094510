import React from 'react'
import styled from 'styled-components'
import colors from '../../_constants/colors'

export const S_ApprovalStatusIndicator = styled.div`
  display: flex;
  align-items: center;
  vertical-align: middle;

  select {
    min-width: 100px;
  }
  .icon {
    &._test-icon {
      background-color: ${colors.MATERIAL_RED};
    }
  }

  .indicator {
    min-width: 10px;
    width: 10px;
    min-height: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 5px;
    background-color: ${colors.MATERIAL_YELLOW};

    &.Rejected {
      background-color: ${colors.MATERIAL_RED};
    }

    &.Approved {
      background-color: ${colors.MATERIAL_GREEN};
    }
    &.Posted {
      background-color: ${colors.MATERIAL_DARK_GREEN};
    }
    &.Funded {
      background-color: ${colors.MATERIAL_DARK_GREEN};
    }
  }
`

export const S_Rejected = styled.span`
  color: #ff0000;
  padding: 10px;
  display: flex;
`

export const S_Approved = styled.span`
  color: #5ed59e;
  padding: 10px;
  display: flex;
`

export const S_Pending = styled.span`
  color: #ffce00;
  padding: 10px;
  display: flex;
`

export const S_Missing = styled.span`
  color: #d0d2d3;
  padding: 10px;
  display: flex;
`

export const ApprovalStatus = ({ status, mapping }) => {
  return (
    <S_ApprovalStatusIndicator>
      <div className={`indicator ${mapping ? mapping[status] : status}`} />
      <div className="text">{status}</div>
    </S_ApprovalStatusIndicator>
  )
}
