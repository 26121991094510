import request from '../../axios_service'
import { fundingRequestDatesToUTC } from '../../../_helpers/abl/funding_requests'

export const createFundingRequest = (funding_request) => {
  return request({
    url: `/abl/client/businesses/${funding_request.business_id}/funding_requests`,
    method: 'POST',
    data: fundingRequestDatesToUTC(funding_request),
  })
}

export const getFundingRequestOverview = (businessId, requestedAmount) => {
  return request({
    url: `/abl/client/businesses/${businessId}/funding_request_overview?requested_amount=${requestedAmount}`,
    method: 'GET',
  })
}

export const getFundingRequest = (fundingRequestId) => {
  return request({
    url: `/abl/client/funding_requests/${fundingRequestId}`,
    method: 'GET',
  })
}

export const updateFundingRequestAmount = (fundingRequestId, amount) => {
  return request({
    url: `/abl/client/funding_requests/${fundingRequestId}`,
    method: 'PUT',
    data: { amount },
  })
}

export const getMonthlySnapshots = (business_id, params) => {
  return request({
    url: `/abl/client/monthly_snapshots?business_id=${business_id}&${params.toString()}`,
    method: 'GET',
    data: null,
  })
}
