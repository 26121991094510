import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useGetNavigation } from 'xen/api/use-navigation';
import { FeatureNotEnabled } from 'xen/components';
const Debtors = lazy(() => import('../pages/debtors'));
export const DEBTORS_PATHS = {
    root: '/debtors',
    /** This is an old Xen route that should be updated when we address debtors in Xen 1.0
     * we are using it here to use legacy logic for contacts page. This will also help
     * keep track of changing this where it is used through typing when it's updated
     */
    contacts: '/debtors/:debtorId/contacts',
};
export const DebtorsRoutes = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    if (isLoading)
        return [];
    const featureEnabled = isSuccess && !!navigation?.debtors;
    return [
        _jsx(Route, { exact: true, path: DEBTORS_PATHS.root, children: featureEnabled ? _jsx(Debtors, {}) : _jsx(FeatureNotEnabled, { feature: "Debtors page" }) }, DEBTORS_PATHS.root),
        _jsx(Route, { exact: true, path: DEBTORS_PATHS.contacts, render: ({ match }) => _jsx(Redirect, { to: `/debtor_details/${match.params.debtorId}/contacts` }) }, DEBTORS_PATHS.contacts),
    ];
};
