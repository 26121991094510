import React from 'react'
import PropTypes from 'prop-types'

import { StatItem } from './StatItem'
import { S_StatList } from './StatList.styles'

/**
 *
 * List of stats for general use. If an item is null it will not be rendered
 */
export const StatList = ({
  HeaderContent,
  children,
  className,
  items,
  layout,
  title,
  isEditing = false,
  childrenInsideList = false,
}) => {
  return (
    <S_StatList className={className + ' ' + layout}>
      {(title || HeaderContent) && (
        <header>
          {title && <h2 className="title">{title}</h2>}
          {HeaderContent && <HeaderContent />}
        </header>
      )}

      {/* Render each item */}
      <div className="statlist-content">
        <div className="list">
          <div className="list-wrapper">
            {items
              .filter((i) => i != null)
              .map((item, index) => {
                if (!item) {
                  return null
                }
                return <StatItem isEditing={isEditing} item={item} key={index} />
              })}
            {childrenInsideList && children}
          </div>
          {!childrenInsideList && children}
        </div>
      </div>
    </S_StatList>
  )
}

StatList.propTypes = {
  HeaderContent: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  items: PropTypes.array,
  layout: PropTypes.string,
  title: PropTypes.any,
}

StatList.defaultProps = {
  HeaderContent: null,
  className: '',
  items: [],
  layout: 'summary',
  title: null,
}
