import styled, { css } from 'styled-components'
import colors from '../../_constants/colors'
import { cssBreakpoints } from '../../_constants/styles'

const ListStyles = css`
  display: flex;
  flex-wrap: wrap;
`
const stackedStyles = css`
  display: grid;
  font-size: 16px;
  grid-template-columns: auto 30%;
  margin-bottom: 10px;
  color: ${colors.TEXT_DARK};
`
export const SmoothPaddings = css`
  padding: 30px 60px;
`
export const S_StatList = styled.div`
  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: 600;
  }

  .ineligible.value {
    font-weight: 600;
    color: ${colors.TEXT_DARK};
  }

  &.outlined {
    .list {
      border: 1px solid ${colors.BORDER_GREY};
      border-radius: 3px;
      padding: 15px 20px 15px 20px;
    }
  }

  &.summary {
    .list-wrapper {
      ${ListStyles}
      margin-bottom: -10px;
    }

    &.spaced {
      .list-wrapper {
        width: 100%;
        justify-content: space-between;
        padding-right: 15px;
      }
    }

    &.with-padding {
      padding: 20px;
      padding-bottom: 30px;
    }

    .stat {
      margin-right: 35px;
      margin-bottom: 10px;

      &:last-child {
        margin-right: 0;
      }
    }

    .label {
      font-size: 16px;
      margin-bottom: 10px;
      color: ${colors.TEXT_LIGHT};
      display: flex;
      justify-content: space-between;
    }

    .value {
      font-size: 16px;
      min-height: calc(100% - 17px);
      color: ${colors.TEXT_DARK};
      font-weight: 400;

      .no-input-styles {
        padding-left: 0;
      }
    }

    .warning {
      margin-left: 8px;
    }
  }

  .full-width {
    & * {
      width: 100%;
    }
  }

  .half-width {
    width: 50vw;
  }

  &.stacked {
    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .stat {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: 17px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.smooth-stacked {
    display: flex;
    flex-direction: column;

    header {
      align-items: center;
      background-color: ${colors.GREY_LIGHT_FILLS};
      display: flex;
      justify-content: flex-start;
      padding: 18px;
      padding-left: 36px;
      border-radius: 6px 6px 0px 0px;

      .title {
        color: ${colors.TEXT_DARK};
        font-weight: 400;
        font-size: 26px;
        margin: 0;
        position: relative;

        &:hover {
          cursor: pointer;
        }

        &:after {
          border-right: 1.5px solid ${colors.TEXT_DARK};
          border-top: 1.5px solid ${colors.TEXT_DARK};
          content: '';
          display: inline-block;
          height: 8px;
          margin-right: 0.5em;
          position: absolute;
          left: calc(100% + 18px);
          top: 12px;
          transform: rotate(45deg);
          width: 8px;
        }
      }
    }

    .stat {
      display: grid;
      font-size: 16px;
      grid-template-columns: auto 30%;
      margin-top: 40px;
      color: ${colors.TEXT_DARK};
    }

    .statlist-content {
      overflow: hidden;
      max-height: 0;
      opacity: 0;
      transform-origin: top;
      transition:
        max-height 0.6s ease-in-out,
        padding 0.2s ease-in-out,
        opacity 0.8s ease-out;
    }

    .list {
      padding: 30px;
      padding-left: 90px;
      min-width: 480px;
      width: 80%;
    }

    .list-wrapper {
      padding: 30px 0;
    }

    &.low-padding {
      .list {
        padding: 0px 10px 0px 50px;
      }

      .list-wrapper {
        padding: 40px 0 0px;
      }

      .stat {
        margin-top: 0px;
        margin-bottom: 40px;
      }
    }

    &.medium-font {
      .stat {
        font-size: 18px;
      }
    }

    &.show-details {
      header {
        .title {
          &:after {
            transform: rotate(135deg);
          }
        }
      }

      .statlist-content {
        opacity: 1;
        max-height: 1500px;
      }
    }
  }

  &.tiles {
    .list-wrapper {
      ${ListStyles}
    }

    .stat {
      width: 25%;
      margin-bottom: 20px;
    }

    .label {
      font-size: 14px;
      margin-bottom: 5px;
      color: #777;
      display: flex;
      justify-content: space-between;
    }

    .value {
      font-size: 18px;
      display: inline-block;
    }

    .warning {
      margin-left: 8px;
    }
  }

  &.smooth-tiles,
  &.smooth-subtiles {
    ${SmoothPaddings}
    &.no-side-padding {
      padding-left: 0;
      padding-right: 0;
    }

    .list-wrapper {
      ${ListStyles}
      justify-content: space-between;
      @media screen and (max-width: ${cssBreakpoints.mobileMd}) {
        justify-content: flex-start;
      }
    }

    .stat {
      color: ${colors.TEXT_LIGHT};
      display: flex;
      flex-direction: column;
      font-size: 14px;
      margin-right: 20px;

      &:last-of-type {
        margin-right: 0;
      }

      @media screen and (max-width: ${cssBreakpoints.mobileMd}) {
        margin-bottom: 10px;
      }

      .label {
        margin-bottom: 10px;
      }

      .value {
        color: ${colors.TEXT_DARK};
        font-size: 24px;
      }

      &.stat-md {
        .label {
          font-size: 16px;
        }

        .value {
          font-size: 20px;
        }
      }
    }
  }

  &.smooth-subtiles {
    padding-top: 0;

    .list {
      justify-content: flex-start;
    }

    .stat {
      margin-right: 10px;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &.smooth-grid {
    ${SmoothPaddings}
    .stat {
      ${stackedStyles}
    }
  }

  &.smooth-grid-compact {
    padding: 10px;

    .stat {
      font-size: 14px;
      display: flex;
      margin-bottom: 5px;
    }

    .value {
      padding-left: 3px;
    }
  }

  .stat {
    &.break-line {
      padding-top: 20px;
      width: 100%;
    }
    &.last {
      order: 100;
    }
  }
`

export const S_StatLists_Wrapper = styled.section`
  border-radius: 6px;
  border: ${colors.OUTLINE_GREY_BOX} 1px solid;
  margin-bottom: 16px;

  &.grid {
    display: grid;
    grid-column-gap: 25px;
    grid-template-columns: auto auto;
    grid-template-rows: auto;

    .stat {
      ${stackedStyles}
      border-bottom: 1px solid;
      color: ${colors.TEXT_LIGHT};
      grid-template-columns: auto 15%;
      margin: 0;
      padding: 15px;
      padding-right: 30px;
    }
  }
`
