import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Flex } from 'xen-ui';
import { Page, PageTitle } from 'xen/components';
import { PlaidAccounts, PlaidDisconnect, PlaidLink, PlaidTransactions } from '../components';
const LinkedExternalAccounts = () => {
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [refetchAccountInterval, setRefetchAccountInterval] = useState(0);
    useEffect(() => {
        // TODO: Review state after connect new accounts https://finvoice-inc.atlassian.net/browse/FP-6799
        if (!selectedAccount) {
            // Connecting new accounts is an asynchronous process in the backend, so we need to keep checking if the accounts are connected.
            const refetchInteval = 1000;
            setRefetchAccountInterval(refetchInteval);
        }
        else {
            // Stop refetching when there is connected accounts
            setRefetchAccountInterval(0);
        }
    }, [selectedAccount]);
    return (_jsxs(Page, { "data-testid": "linked-external-accounts", children: [_jsx(PageTitle, { style: { width: '100%', textAlign: 'center' }, title: 'External Linked Accounts' }), _jsxs(Flex, { align: "flex-end", direction: "row", gap: "sm", justify: "center", style: {
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                }, wrap: "wrap", children: [_jsx("div", { style: { marginRight: '10px' }, children: _jsx(PlaidAccounts, { onAccountSelected: setSelectedAccount, refetchInterval: refetchAccountInterval }) }), _jsx("div", { style: { marginTop: '30px' }, children: _jsx(PlaidLink, {}) }), selectedAccount && (_jsx("div", { style: { marginTop: '30px', marginLeft: '10px' }, children: _jsx(PlaidDisconnect, { account: selectedAccount }) }))] }), _jsx("div", { style: {
                    padding: '30px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    textAlign: 'center',
                }, children: _jsx("div", { style: { minWidth: '500px', width: '800px' }, children: selectedAccount ? (_jsx(PlaidTransactions, { accountId: selectedAccount.id })) : (_jsx("div", { "data-testid": "no-account-id", children: _jsx("p", { children: "Link your external accounts to view its transactions. Accounts connected recently may take a few seconds to load." }) })) }) })] }));
};
export default LinkedExternalAccounts;
