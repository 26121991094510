import styled from 'styled-components'

import { colors } from '../../../_constants/colors'
import { S_StatList } from '../../../_components/StatList'

export const S_FactoringLenderDashboard = styled.div`
  ${S_StatList} {
    .label {
      color: #666;
      font-weight: bold;
    }

    .value {
      font-size: 25px; /* Match other tiles on page */
      font-weight: 300;
    }
  }

  .section {
    margin-bottom: 30px;
    h2 {
      margin-bottom: 10px;
    }
  }

  .section-title {
    color: ${colors.TEXT_DARK};
    padding: 10px 0;
    border-bottom: 1px solid ${colors.BORDER_GREY};
    margin-bottom: 20px;
  }

  .date {
    max-width: fit-content;
  }
  .page-header {
    display: flex;
    justify-content: space-between;
  }
`
