import React from 'react'
import { S_ClientSummaryReport } from './ClientSummaryReport.styles'
import { dateToStringSlashes } from '../../../_helpers/dates'
import { formatCurrency } from '../../../_helpers/formatting'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ClientSummaryReportPdf } from './ClientSummaryReport.pdf'
import ToolTip from '../../Tooltip/Tooltip'

export const ClientSummaryReport = ({ summary }) => {
  return (
    <S_ClientSummaryReport>
      <header>
        <h2>{summary.params.business.business_name}</h2>
        <h5>Summary Report</h5>
        <h5>
          Date&nbsp;
          {dateToStringSlashes(summary.params.start_date)}
          &nbsp;-&nbsp;
          {dateToStringSlashes(summary.params.end_date)}
        </h5>
        <PDFDownloadLink
          className={'download-link'}
          document={<ClientSummaryReportPdf summary={summary} />}
          fileName={`${summary.params.business.business_name} Summary Report.pdf`}
        >
          Export
        </PDFDownloadLink>
      </header>
      <div className={'data-container'}>
        <div className={'data-column'}>
          <div className="data-section">
            <span className={'vertical-title'}>Outstanding</span>
            <div className={'data-row'}>
              <div className={'data-title'}>Beginning Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.beginning_balance)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total AR purchased.">
                <div className={'data-title'}>Total Purchases</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.total_purchases)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total amount of completed payments that were applied to purchased invoices.">
                <div className={'data-title'}>Total Payments</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.total_payments)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Change in AR value as a result of payments or lack thereof.">
                <div className={'data-title'}>Adjustments</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.adjustments)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Ending Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.ending_balance)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Sum of invoice face values for invoices that have earned fees.">
                <div className={'data-title'}>Invoices With Fees</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.invoices_with_fees)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount of AR marked ineligible.">
                <div className={'data-title'}>Ineligible</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.ineligible)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Balance of non-funded invoices received.">
                <div className={'data-title'}>Non Funded Invoices</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.outstanding.non_funded_invoices)}</div>
            </div>
          </div>
          <div className="data-section">
            <span className={'vertical-title'}>Cash Received</span>
            <div className={'data-row'}>
              <ToolTip content="Total collections that paid an invoice in full.">
                <div className={'data-title'}>Invoice Payment</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.payments_posted)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total collections that paid a previously closed invoice.">
                <div className={'data-title'}>Paid Closed</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.paid_closed)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total collections that short paid an invoice.">
                <div className={'data-title'}>Short Pay</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.paid_short)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total collections that partially paid an invoice, but did not close the invoice.">
                <div className={'data-title'}>Partial Pay</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.partial_pay)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total collections that over paid an invoice.">
                <div className={'data-title'}>Over Pay</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.over_pay)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total collections received for non-factored invoices.">
                <div className={'data-title'}>New Non-Factored</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.new_non_factored)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total checks returned.">
                <div className={'data-title'}>Returned Payments</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.cash.returned_payments)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Total</div>
              <div className={'data-value'}>{formatCurrency(summary.cash.total)}</div>
            </div>
          </div>
        </div>
        <div className={'data-column'}>
          <div className="data-section">
            <span className={'vertical-title'}>Net Funds Employed</span>
            <div className={'data-row'}>
              <div className={'data-title'}>Beginning Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.net_funds_employed.beginning_nfe)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total funding requests processed.">
                <div className={'data-title'}>Funds Advanced</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.net_funds_employed.funds_advanced)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total payments received.">
                <div className={'data-title'}>Payments</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.net_funds_employed.payments)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Ending Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.net_funds_employed.ending_nfe)}</div>
            </div>
          </div>
          <div className="data-section">
            <span className={'vertical-title'}>Fees</span>
            <div className={'data-row'}>
              <ToolTip content="Total fees earned.">
                <div className={'data-title'}>Earned For Period</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.earned_for_period)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Fees earned from closed invoices or funding requests.">
                <div className={'data-title'}>Earned MTD</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.earned_mtd)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total accrued fees.">
                <div className={'data-title'}>Accrued Fees</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.accrued_fees)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Earned MTD + Accrued Fees">
                <div className={'data-title'}>Total Fees MTD</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.total_fees_mtd)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total accrued fees - Fees accrued prior to report timeframe.">
                <div className={'data-title'}>Less Accrued BOM</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.less_accrued_beginning)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Total Fees MTD - Accrued Fees Beginning of Timeframe.">
                <div className={'data-title'}>Net Fees MTD</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.fees.net_fees_mtd)}</div>
            </div>
          </div>
          <div className="data-section">
            <span className={'vertical-title'}>Unapplied Cash</span>
            <div className={'data-row'}>
              <ToolTip content="Sum of all unapplied cash amounts for the client posted in the selected period">
                <div className={'data-title'}>Balance</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.unapplied_cash.balance)}</div>
            </div>
          </div>
        </div>
        <div className={'data-column'}>
          <div className="data-section">
            <span className={'vertical-title'}>Reserve</span>
            <div className={'data-row'}>
              <div className={'data-title'}>Beginning Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.reserve.client_beginning)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount non-factored payments added to client reserve.">
                <div className={'data-title'}>Plus Non-Factored</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.plus_non_factored)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount collections applied to previously closed invoices added to client reserve.">
                <div className={'data-title'}>Plus Paid Closed</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.plus_paid_closed)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount collections applied as overpayments added to client reserve.">
                <div className={'data-title'}>Plus Over Pay</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.plus_over_pay)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount collections applied as short pays removed from client reserve.">
                <div className={'data-title'}>Less Short Pay</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_short_pay)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount removed from client reserve as a result of charging back invoices.">
                <div className={'data-title'}>Less Chargebacks</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_chargeback)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="">
                <div className={'data-title'}>Less Reserve Releases</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_reserve_releases)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount removed from client reserve as a result of processing funding requests.">
                <div className={'data-title'}>Less Funding Requests</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_funding_requests)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount removed from client reserve to cover fees.">
                <div className={'data-title'}>Less Fees</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_fees)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Ending Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.reserve.client_ending)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Escrow Beginning Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.reserve.escrow_beginning)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount added to escrow reserve from invoice purchases. (Purchased AR x Advance Rate)">
                <div className={'data-title'}>Plus New Invoices</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.plus_new_invoices)}</div>
            </div>
            <div className={'data-row'}>
              <ToolTip content="Amount removed from escrow reserve when invoices were closed.">
                <div className={'data-title'}>Less Closed Invoices</div>
              </ToolTip>
              <div className={'data-value'}>{formatCurrency(summary.reserve.less_closed_invoices)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Escrow Ending Balance</div>
              <div className={'data-value'}>{formatCurrency(summary.reserve.escrow_ending)}</div>
            </div>
            <div className={'data-row'}>
              <div className={'data-title'}>Total</div>
              <div className={'data-value'}>{formatCurrency(summary.reserve.total)}</div>
            </div>
          </div>
        </div>
      </div>
    </S_ClientSummaryReport>
  )
}
