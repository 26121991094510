import React from 'react'
import { PlaidLink } from './PlaidLink'
import { PlaidDisconnect } from './PlaidDisconnect'
import { isFeatureEnabled } from '../../../_helpers/flags'
import { useFlags } from '../../../_reduxHooks/flags.hook'
import { useBusiness } from '../../../_reduxHooks/business.hook'

export const PlaidConnector = ({ isConnected, successCb, disconnectCb }) => {
  const { featureFlags } = useFlags()
  const business = useBusiness().business
  const disconnectEnabled = isFeatureEnabled('banking_integration_client_disconnect', featureFlags)
  if (isConnected && disconnectEnabled) {
    return <PlaidDisconnect disconnectCb={disconnectCb} entityId={business.id} entityType="business" />
  } else {
    return <PlaidLink isConnected={isConnected} successCb={successCb} />
  }
}
