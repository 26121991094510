const TENANTS = [
  'finvoice',
  'newcenturyfinancial',
  'sallyportcf',
  'capitalnow',
  'republicbc',
  'sevenoaks',
  'hitachibf',
  'cfgroup',
  'fswfunding',
  'tradegate',
  'summitfr',
  'hedayacapital',
  'zenithinsured',
  'tbscapitalfunding',
  'twelvefive',
  'ccbg',
  'scfactoringsolution',
  'invoicefactoringsolutions',
  'boldbusinesscapital',
  'portercapital',
  'chartercapitalusa',
  'acsfactors',
  'hedayacapital',
  'republicbc',
  'newbridgeglobal',
  'axosbank',
  'factoringdemo',
  'prodemo',
  'abldemo',
  'marcocapital',
  'marcofi',
  'marcofisandbox',
  'boldbusinesscapitalold',
  'boldbusinesscapitalnew',
  'dat',
  'ctrlpay',
  'primedemo',
  'breakoutfinance',
  'primaryfundingdemo',
  'demo',
  'sandbox',
  'fastarfunding',
  'qa',
  'capitalplus',
  'fundient',
  'velocityfi',
  'interportcapital',
  'capitalplusfinancial',
  'changecapital',
  'cfpartners',
  'unicorncapital',
  'rmcapital',
  'canntella',
  'commercialfinancepartners',
]

export const TENANT_TOKEN_LOOKUP = {
  appfinvoice: 'finvoice',
  app: 'finvoice',
  10: 'finvoice',
  '12five': 'twelvefive',
  axosbank: 'axosbank',
}

export const TENANT_HOTJAR_IDS = {
  primaryfundingdemo: 2007704,
}

export default TENANTS
