import React from 'react'
import PropTypes from 'prop-types'
import { matchPath, NavLink } from 'react-router-dom'

import { TopNavDropdown } from './TopNavDropdown'

export const TopNavBarSubItem = ({ route, label, exact, subMenu, show, singleRoute = null }) => {
  return (
    show && (
      <li className="top-nav-sub-nav__item">
        <NavLink
          activeClassName="_active"
          className="top-nav-link-sub"
          exact={exact}
          isActive={(match, location) => {
            if (!singleRoute || match) {
              return match
            }

            const matchSingle = matchPath(location.pathname, { path: singleRoute })

            return matchSingle && matchSingle.isExact
          }}
          to={route}
        >
          {label}
        </NavLink>

        {subMenu && <TopNavDropdown subMenu={subMenu} />}
      </li>
    )
  )
}

TopNavBarSubItem.propTypes = {
  route: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  exact: PropTypes.bool,
  subMenu: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  show: PropTypes.bool,
}

TopNavBarSubItem.defaultProps = {
  exact: false,
  subMenu: false,
  show: true,
}
