import React from 'react'
import PropTypes from 'prop-types'
import { useId } from 'react-id-generator'

// Components
import ToolTip from '../Tooltip/Tooltip'
import { FaExclamationTriangle } from 'react-icons/fa'
import { FormField } from '../Forms/FormField'

// Helpers
import { formatValueByType } from '../../_helpers'

//Styles
import { S_FieldSet } from './StatItem.styles'

export const StatItem = ({ item, isEditing = false }) => {
  const {
    customLabel = null,
    editExtraProps = {},
    editable = false,
    label,
    onChange = () => {},
    options = null,
    renderOnEdit,
    statClassName = '',
    textStyle = '',
    tooltip = null,
    type = 'text',
    value,
    warning,
    formatter,
    preventRender = false,
  } = item
  const showEditComponent = isEditing && editable
  const [htmlId] = useId(1, 'stat-item-')

  if (preventRender) {
    return null
  }

  return (
    <div className={`stat ${statClassName}`} key={label}>
      {showEditComponent ? (
        <EditComponent
          customLabel={customLabel}
          editExtraProps={editExtraProps}
          htmlId={htmlId}
          label={label}
          onChange={onChange}
          renderOnEdit={renderOnEdit}
          textStyle={textStyle}
          type={type}
          value={value}
          warning={warning}
        />
      ) : (
        <StatComponent
          customLabel={customLabel}
          formatter={formatter}
          label={label}
          options={options}
          textStyle={textStyle}
          tooltip={tooltip}
          type={type}
          value={value}
          warning={warning}
        />
      )}
    </div>
  )
}

StatItem.propTypes = {
  isEditing: PropTypes.bool,
  item: PropTypes.shape({
    customLabel: PropTypes.any,
    editExtraProps: PropTypes.object,
    editable: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.object,
    statClassName: PropTypes.string,
    textStyle: PropTypes.string,
    tooltip: PropTypes.any,
    type: PropTypes.string,
    value: PropTypes.any,
    warning: PropTypes.string,
  }),
}

const StatComponent = ({
  customLabel,
  textStyle = '',
  warning = '',
  label,
  value,
  type = 'text',
  options = {},
  tooltip,
  formatter,
}) => {
  return (
    <>
      <StatHeader LabelTag={'div'} customLabel={customLabel} label={label} textStyle={textStyle} warning={warning} />
      <div className={`value ${textStyle}`}>
        <ToolTip content={tooltip}>
          <div>{formatter ? formatter(value) : formatValueByType(value, type, options)}</div>
        </ToolTip>
      </div>
    </>
  )
}

StatComponent.propTypes = {
  customLabel: PropTypes.any,
  label: PropTypes.any,
  options: PropTypes.any,
  textStyle: PropTypes.string,
  tooltip: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
  warning: PropTypes.string,
}

const EditComponent = (props) => {
  const {
    renderOnEdit,
    onChange = () => {},
    value,
    type = 'text',
    htmlId = '',
    editExtraProps = {},
    ...headerOptions
  } = props

  return renderOnEdit ? (
    <S_FieldSet>
      <StatHeader {...headerOptions} LabelTag={'legend'} />
      {typeof renderOnEdit === 'function' ? renderOnEdit() : renderOnEdit}
    </S_FieldSet>
  ) : (
    <S_FieldSet>
      <StatHeader {...headerOptions} LabelTag={'label'} htmlFor={htmlId} />
      <FormField id={htmlId} onChange={onChange} type={type} value={value} {...editExtraProps} />
    </S_FieldSet>
  )
}

EditComponent.propTypes = {
  editExtraProps: PropTypes.object,
  htmlId: PropTypes.string,
  onChange: PropTypes.func,
  renderOnEdit: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
}

const StatHeader = ({ customLabel, textStyle = '', warning = '', label, LabelTag = 'div', htmlFor = '' }) => {
  return customLabel ? (
    customLabel
  ) : (
    <LabelTag className={`label ${textStyle}`} htmlFor={htmlFor}>
      {label}
      {warning && <FaExclamationTriangle className={`warning`} title={warning} />}
    </LabelTag>
  )
}

StatHeader.propTypes = {
  LabelTag: PropTypes.string,
  customLabel: PropTypes.any,
  htmlFor: PropTypes.string,
  label: PropTypes.any,
  textStyle: PropTypes.string,
  warning: PropTypes.string,
}
