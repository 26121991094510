import React from 'react'
import startCase from 'lodash/startCase'
import { ClipLoader } from 'react-spinners'

// Components
import { CartesianGrid, Legend, Tooltip, XAxis, YAxis, LineChart, Line, ResponsiveContainer } from 'recharts'

// Helpers
import { getCompactCurrencyValue } from '../../../_helpers'
import { colors } from '../../../_constants/colors'
import { generateNiceTicks } from '../../../_helpers/chart.util'

// Styles
import { axisTickStyles } from '../../../components/Chart/Chart.styles'
import { S_FeesArChart } from './FeesArChart.styles'

// Hooks
import { useFeesArChart } from '../../../_entityHooks/feesArChart.hook'
import { ChartDataPanel } from '../../../components/Chart/ChartDataPanel/ChartDataPanel'

export const FeesArChart = ({ enablePeriodsToggler = false }) => {
  const xAxisLabel = 'month_year'

  const { feesAr, lastMonthData, activeData, setActiveData, setPeriod, periodsOptions, period, loading } =
    useFeesArChart()

  const dataPanelItems = [
    {
      key: 'outstanding_ar',
      label: 'Total Outstanding',
      type: 'currency',
      tooltip: 'Balance as of last day of selected month.',
    },
    {
      key: 'fees',
      label: 'Fees Earned',
      type: 'currency',
    },
    {
      key: 'month_return',
      label: 'Monthly Yield',
      type: 'percent',
      tooltip: 'Fees Earned / Total Outstanding.',
    },
  ]

  if (loading) {
    return (
      <S_FeesArChart>
        <div className="chart-wrapper">
          <div className={'no-data'}>
            <div className={'no-data-text'}>
              <ClipLoader />
            </div>
          </div>
        </div>
      </S_FeesArChart>
    )
  }

  if (!feesAr.length) {
    return (
      <S_FeesArChart>
        <div className="chart-wrapper">
          <div className={'no-data'}>
            <div className={'no-data-text'}>No Data.</div>
          </div>
        </div>
      </S_FeesArChart>
    )
  }

  return (
    <S_FeesArChart>
      <div className="chart-wrapper">
        {enablePeriodsToggler && (
          <div className={`prev-current`}>
            {periodsOptions.map((option, index) => (
              <div
                className={option.value === period ? 'active' : ''}
                key={index}
                onClick={() => {
                  if (option.value === period) {
                    return null
                  }
                  setPeriod(option.value)
                }}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}

        <ResponsiveContainer height={280} width="100%">
          <LineChart
            data={feesAr}
            height={280}
            onMouseMove={({ isTooltipActive, activeTooltipIndex }) => {
              if (!isTooltipActive) {
                setActiveData(lastMonthData)
                return
              }

              const activePayload = feesAr[activeTooltipIndex]
              setActiveData(activePayload)
            }}
          >
            <CartesianGrid horizontal={true} stroke={colors.GREY_LIGHT_FILLS} vertical={true} />
            <XAxis
              axisLine={{ stroke: colors.TEXT_LIGHT }}
              dataKey={xAxisLabel}
              tick={axisTickStyles}
              tickLine={false}
            />

            <YAxis
              axisLine={{ stroke: colors.TEXT_LIGHT }}
              tick={axisTickStyles}
              tickFormatter={(dollarAmt) => getCompactCurrencyValue(dollarAmt)}
              tickLine={false}
              ticks={generateNiceTicks(feesAr, 'outstanding_ar')}
              yAxisId="outstandingAxis"
            />

            <YAxis
              axisLine={{ stroke: colors.TEXT_LIGHT }}
              orientation="right"
              tick={axisTickStyles}
              tickFormatter={(dollarAmt) => getCompactCurrencyValue(dollarAmt)}
              tickLine={false}
              ticks={generateNiceTicks(feesAr, 'fees')}
              yAxisId="feesAxis"
            />

            <Line // Colors for outstanding AR
              dataKey="outstanding_ar"
              stroke={colors.SAPPHIRE_BLUE}
              strokeWidth={3}
              yAxisId="outstandingAxis"
            />
            <Line // Colors for fees
              dataKey="fees"
              stroke={colors.SKY_BLUE}
              strokeWidth={3}
              yAxisId="feesAxis"
            />
            <Legend
              align={'center'}
              formatter={(key) => {
                if (key === 'outstanding_ar') {
                  return <span style={{ fontWeight: 'bold' }}>Outstanding AR</span>
                }
                return <span style={{ fontWeight: 'bold' }}>{startCase(key)}</span>
              }}
              height={30}
              iconSize={10}
              iconType={'square'}
              verticalAlign={'top'}
              wrapperStyle={{ left: '0px', fontSize: '12px', color: colors.TEXT_DARK }}
            />
            <Tooltip content={() => ''} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <ChartDataPanel dataPanelItems={dataPanelItems} dataSource={activeData} />
    </S_FeesArChart>
  )
}
