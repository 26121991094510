import { jsx as _jsx } from "react/jsx-runtime";
import { Flex as MantineFlex } from '@mantine/core';
export const Flex = ({ alignSelf, justifySelf, style = {}, ...props }) => {
    // Add justifySelf and alignSelf prop, since they are not available by default on <Flex>.
    const styleProp = {
        style: {
            ...style,
            ...(alignSelf ? { alignSelf } : {}),
            ...(justifySelf ? { justifySelf } : {}),
        },
    };
    return _jsx(MantineFlex, { ...props, ...styleProp });
};
