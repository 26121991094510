import { BROKERS_PATHS, BrokersRoutes } from 'xen/_modules/brokers';
import { CLIENTS_PATHS, ClientsRoutes } from 'xen/_modules/clients';
import { CONFIGURATIONS_PATHS, ConfigurationsRoutes } from 'xen/_modules/configurations';
import { DEBTORS_PATHS, DebtorsRoutes } from 'xen/_modules/debtors';
import { FUNDING_REQUESTS_PATHS, FundingRequestsRoutes } from 'xen/_modules/funding-requests';
import { INVOICE_SCHEDULES_PATHS, InvoiceSchedulesRoutes } from 'xen/_modules/invoice-schedules';
import { INVOICE_SETTLEMENT_PATHS, InvoiceSettlementRoutes } from 'xen/_modules/invoice-settlement';
import { INVOICES_PATHS, InvoicesRoutes } from 'xen/_modules/invoices';
import { LINKED_EXTERNAL_ACCOUNTS_PATHS, LinkedExternalAccountsRoutes } from 'xen/_modules/linked-external-accounts';
import { NOTIFICATIONS_PATHS, NotificationsRoutes } from 'xen/_modules/notifications';
import { OVERVIEW_PATHS, OverviewRoutes } from 'xen/_modules/overview';
import { PROSPECTS_PATHS, ProspectsRoutes } from 'xen/_modules/prospects';
import { UNAPPLIED_CASH_PATHS, UnappliedCashRoutes } from 'xen/_modules/unapplied-cash';
export { BROKERS_PATHS, CLIENTS_PATHS, CONFIGURATIONS_PATHS, DEBTORS_PATHS, FUNDING_REQUESTS_PATHS, INVOICE_SCHEDULES_PATHS, INVOICE_SETTLEMENT_PATHS, INVOICES_PATHS, LINKED_EXTERNAL_ACCOUNTS_PATHS, NOTIFICATIONS_PATHS, OVERVIEW_PATHS, PROSPECTS_PATHS, UNAPPLIED_CASH_PATHS, };
export function AllXenRoutes() {
    return [
        BrokersRoutes(),
        ClientsRoutes(),
        ConfigurationsRoutes(),
        DebtorsRoutes(),
        FundingRequestsRoutes(),
        InvoicesRoutes(),
        InvoiceSchedulesRoutes(),
        InvoiceSettlementRoutes(),
        LinkedExternalAccountsRoutes(),
        NotificationsRoutes(),
        OverviewRoutes(),
        ProspectsRoutes(),
        UnappliedCashRoutes(),
    ];
}
export function XenRoutes() {
    return AllXenRoutes();
}
