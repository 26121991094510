import { getAblPortfolioConcentration } from '../../_services/abl/abl_portfolio.service'
import { useEffect, useState } from 'react'

export const useAblConcentrationChart = () => {
  const [data, setData] = useState({})
  const [currentSegmentData, setCurrentSegmentData] = useState([])
  const [currentSegment, setCurrentSegment] = useState('clients')
  const [loading, setLoading] = useState(true)

  const segmentsOptions = [
    { name: 'Clients', value: 'clients' },
    { name: 'Debtors', value: 'debtors' },
  ]

  const loadData = () => {
    getAblPortfolioConcentration().then((response) => {
      setData(response)
      setCurrentSegmentData(response[currentSegment])
      setLoading(true)
    })
  }

  useEffect(() => {
    const isFirstRender = !Object.keys(data).length
    if (isFirstRender) {
      loadData()
    } else {
      setCurrentSegmentData(data[currentSegment])
    }
  }, [currentSegment])

  return {
    currentSegment: currentSegment,
    setCurrentSegment: setCurrentSegment,
    currentSegmentData: currentSegmentData,
    segmentsOptions: segmentsOptions,
    loading: loading,
  }
}
