import { useHistory } from 'react-router-dom';
import { createInternalLinkUrl } from 'xen/helpers';
export const useXenHistory = () => {
    const history = useHistory();
    const push = ({ to, searchParams, urlParams }) => {
        // @ts-expect-error TODO: fix type errors
        const linkUrl = createInternalLinkUrl({ to, urlParams, searchParams });
        history.push(linkUrl);
    };
    const replace = ({ to, searchParams, urlParams }) => {
        // @ts-expect-error TODO: fix type errors
        const linkUrl = createInternalLinkUrl({ to, urlParams, searchParams });
        history.replace(linkUrl);
    };
    return { ...history, push, replace };
};
