import React, { useRef, useState, useEffect } from 'react'
import { S_AverageInvoiceAmountReport } from './AverageInvoiceAmountReport.styles'
import { getCompactCurrencyValue, formatCurrency } from '../../../_helpers/formatting'
import { colors } from '../../../_constants/colors'
import { longMonth, shortMonth } from '../../../_helpers'
import Table from '../../../_components/Table'
import { createColumn } from '../../../_components/TableColumn'
import { Downloader } from '../../PaginationControls'
import { ClipLoader } from 'react-spinners'
import { S_Title, S_Subtitle } from '../../Typography/Titles.styles'

import { LineChart, XAxis, YAxis, CartesianGrid, Line, ResponsiveContainer, Tooltip } from 'recharts'
import { exportTableData } from '../../../_helpers/export-table/exportTable.helper'
import { FormRow } from '../../Forms/FormRow'
import { getMonthlySnapshots } from '../../../_services/abl/borrower/abl-borrower.service'
import { axisTickStyles } from '../../../components/Chart/Chart.styles'
import startCase from 'lodash/startCase'

export const AverageInvoiceAmountReport = ({ reportData, ablBusiness, params }) => {
  const [startDate, setStartDate] = useState(params.get('start_date')?.split('T')[0])
  const [endDate, setEndDate] = useState(params.get('end_date')?.split('T')[0])
  const [data, setData] = useState(reportData)
  const [loading, setLoading] = useState(false)
  const renderedOnce = useRef(false)

  useEffect(() => {
    const urlParams = new URLSearchParams()
    // Reference to rendered once to allow smooth animation of recharts chart
    if (startDate && endDate && renderedOnce.current) {
      urlParams.set('start_date', startDate)
      urlParams.set('end_date', endDate)
      setLoading(true)
      getMonthlySnapshots(ablBusiness.id, urlParams).then((snapshotData) => {
        setData(snapshotData)
        setLoading(false)
      })
    } else if (!renderedOnce.current) {
      renderedOnce.current = true
    }
  }, [startDate, endDate])

  const monthFormatter = data.length > 4 ? shortMonth : longMonth
  const formattedReportData = data.map((d) => {
    return {
      ...d,
      formatted_month: `${monthFormatter({ monthNum: d.month })} ${d.year}`,
    }
  })
  const tableColumns = formattedReportData.map((d) => {
    return createColumn({
      type: 'currency',
      id: d.formatted_month,
      headingText: d.formatted_month,
      disableSortBy: true,
      textAlignment: 'left',
    })
  })
  const tableData = [
    formattedReportData.reduce((prev, current) => {
      prev[current.formatted_month] = current.average_invoice_amount
      return prev
    }, {}),
  ]

  return (
    <S_AverageInvoiceAmountReport>
      <header>
        <div className="title-container">
          <S_Title marginBottom="5" size="sm">
            {ablBusiness.name}
          </S_Title>
          <h5>Average Invoice Amount</h5>
          <h5>Based On Month End Values</h5>
        </div>
      </header>
      <div className={'data-container'}>
        <div className="date-range-selection">
          <FormRow
            defaultValue={startDate}
            id={'start_date'}
            label={'From'}
            onChange={(e) => setStartDate(e)}
            type="date"
          />
          <FormRow defaultValue={endDate} id={'end_date'} label={'To'} onChange={(e) => setEndDate(e)} type="date" />
          {loading && (
            <div className="loading-indicator">
              <ClipLoader />
            </div>
          )}
        </div>
        <ResponsiveContainer height={300} width={'100%'}>
          <LineChart data={formattedReportData}>
            <XAxis
              axisLine={false}
              dataKey="formatted_month"
              padding={{ left: 60, right: 60 }}
              tick={axisTickStyles}
              tickLine={{ stroke: colors.GREY }}
            />
            <YAxis
              axisLine={{ stroke: colors.GREY }}
              tick={axisTickStyles}
              tickFormatter={getCompactCurrencyValue}
              tickLine={{ stroke: colors.GREY }}
              width={85}
            />
            <CartesianGrid stroke={colors.GREY} strokeDasharray="0" vertical={false} />
            <Line
              activeDot={{ stroke: colors.FINVOICE_BLUE, fill: colors.FINVOICE_BLUE }}
              dataKey="average_invoice_amount"
              dot={false}
              stroke={colors.FINVOICE_BLUE}
              type="linear"
            />
            <Tooltip formatter={(value, name, props) => [formatCurrency(value), startCase(name)]} />
          </LineChart>
        </ResponsiveContainer>

        <div className="download-container">
          <Downloader
            onDownloadClick={(type) => {
              exportTableData(type, tableData, tableColumns)
            }}
            showDownload={true}
          />
        </div>
      </div>
      <div className="data-table">
        <S_Subtitle marginBottom={'0'} size="base" weight={'700'}>
          Overview
        </S_Subtitle>
        <Table columns={tableColumns} data={tableData} />
      </div>
    </S_AverageInvoiceAmountReport>
  )
}
