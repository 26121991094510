import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { darken } from 'polished'

// Components
import { Button } from '../Button'
import { FormField } from '../Forms/FormField'

// Constants

export const S_DualInput = styled.div`
  display: flex;
  .left {
    border-radius: 5px 0px 0px 5px;
    ${(props) =>
      props.primary === 'left' &&
      css`
                background: ${(props) => props.theme.colors.secondary}
                color: white;
            `};
    ${(props) =>
      props.primary === 'right' &&
      css`
                background-color: white;
                color: ${(props) => props.theme.colors.secondary}
                border: solid;
                border-color: ${(props) => props.theme.colors.secondary}
                border-width: 1.5px;
            `};
  }
  .right {
    border-radius: 0px 5px 5px 0px;
    ${(props) =>
      props.primary === 'left' &&
      css`
                background-color: white;
                color: ${(props) => props.theme.colors.secondary}
                border: solid;
                border-color: ${(props) => props.theme.colors.secondary}
                border-width: 1.5px;
            `};
    ${(props) =>
      props.primary === 'right' &&
      css`
                background: ${(props) => props.theme.colors.secondary}
                border-color: ${(props) => props.theme.colors.secondary}
                color: white;
                &:hover {
                    border-color: ${darken(0.15, props.theme.colors.secondary)};
                    background-color: ${darken(0.15, props.theme.colors.secondary)};
                }
            `};
  }
  ${(props) => props.customCss}
`

const ALLOWED_INPUTS = { button: { Component: Button }, input: { Component: FormField } }

const leftDefault = { type: 'button', props: { text: 'button' } }
const rightDefault = { type: 'input', props: { type: 'text' } }

export const DualInput = ({ left = leftDefault, right = rightDefault, customCss = '', primary = '', ...rest }) => {
  const { className: leftClassName, ...leftProps } = left.props
  const { className: rightClassName, ...rightProps } = right.props

  const LeftComponent = ALLOWED_INPUTS[left.type].Component
  const RightComponent = ALLOWED_INPUTS[right.type].Component

  return (
    <S_DualInput customCss={customCss} primary={primary} {...rest}>
      <LeftComponent className={`left ${leftClassName}`} {...leftProps} />
      <RightComponent className={`right ${rightClassName}`} {...rightProps} />
    </S_DualInput>
  )
}

DualInput.propTypes = {
  customCss: PropTypes.array,
  left: PropTypes.object,
  primary: PropTypes.string,
  right: PropTypes.object,
}
