import styled from 'styled-components'
import colors from '../../../_constants/colors'
import { S_FormRow } from '../../Forms/FormRow'
import { S_Table, S_TR } from '../../Table'
import { addMediaQuery } from '../../../_constants/stylesMediaBreakpoints'

export const S_AverageInvoiceAmountReport = styled.div`
  width: 50vw;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;

  ${addMediaQuery.md`
     width: 720px;
  `}

  header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    text-align: center;
    h5 {
      color: ${colors.TEXT_LIGHT};
      font-weight: normal;
    }
  }
  .data-table {
    margin-top: 50px;
    overflow-x: scroll;
    max-width: 100%;

    ${S_TR} {
      .td:first-child {
        padding-left: 0;
      }
    }
    ${S_Table} {
      .th:first-child {
        padding-left: 0;
      }
    }
  }
  .data-container {
    margin-top: 75px;
    width: calc(100% + 85px);
    margin-left: -85px;
    position: relative;
    .date-range-selection {
      display: flex;
      position: absolute;
      right: 0;
      top: -50px;
      ${S_FormRow} {
        .form-row-label {
          color: ${colors.TEXT_LIGHT};
          font-size: 12px;
        }
        margin-right: 10px;
        input {
          padding: 2px;
          width: fit-content;
          color: ${colors.TEXT_LIGHT};
          border-color: ${colors.TEXT_LIGHT};
          font-size: 12px;
        }
      }

      .loading-indicator {
        position: absolute;
        right: -100px;
        top: 15px;
      }
    }
  }
  .download-container {
    display: flex;
    position: absolute;
    right: 0;
    .download {
      display: flex;
    }
  }
`
