import { jsx as _jsx } from "react/jsx-runtime";
import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { useGetNavigation } from 'xen/api/use-navigation';
import { FeatureNotEnabled } from 'xen/components';
const Notifications = lazy(() => import('../pages/notifications'));
export const NOTIFICATIONS_PATHS = {
    root: '/notifications',
};
export const NotificationsRoutes = () => {
    const { data: navigation, isLoading, isSuccess } = useGetNavigation();
    if (isLoading)
        return [];
    const featureEnabled = isSuccess && !!navigation?.notifications;
    return [
        _jsx(Route, { exact: true, path: NOTIFICATIONS_PATHS.root, children: featureEnabled ? _jsx(Notifications, {}) : _jsx(FeatureNotEnabled, { feature: "Notifications page" }) }, NOTIFICATIONS_PATHS.root),
    ];
};
