import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { esigningActions } from '../_actions'

export function useSigning() {
  const dispatch = useDispatch()
  return {
    getEsigningRequests: useCallback((userId) => dispatch(esigningActions.getEsigningRequests(userId)), [dispatch]),
    openEsigningDocument: useCallback(
      (doc, userId, env, lastDoc = false) => dispatch(esigningActions.openEsigningDocument(doc, userId, env, lastDoc)),
      [dispatch]
    ),
    loading: useSelector((state) => {
      return state.esigning.loading
    }, shallowEqual),
    error: useSelector((state) => {
      return state.esigning.error
    }, shallowEqual),
    signature_request: useSelector((state) => {
      return state.esigning.signature_request
    }, shallowEqual),
  }
}
