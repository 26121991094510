import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { getCurrentEnv, store } from '../_helpers'
import { useDrag } from '../_reduxHooks/drag.hook'
import { useSelector } from 'react-redux'
import { DEFAULT_COLORS, tenantColors } from '../_constants/colors'
import { useXenTheme } from 'xen-ui'
import { useFeatureFlags } from 'xen/hooks'

//set height to 100vh because IE requires this for sticky footer
const S_PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media screen and (min-width: 700px) {
    height: 100vh;
  }
`

export const PageWrapper = ({ children }) => {
  const { newConfigurationsAndSettingsUi: settingsUIEnabled } = useFeatureFlags()
  const { colors } = useXenTheme()
  const modal = useSelector((state) => state.modal)
  let { tenantToken } = getCurrentEnv()
  const legacyColors = tenantToken && tenantColors[tenantToken] ? tenantColors[tenantToken] : DEFAULT_COLORS
  const { setDragOverTarget } = useDrag()
  return (
    <ThemeProvider
      theme={{
        colors: settingsUIEnabled ? { ...legacyColors, secondary: colors.primary[6] } : legacyColors,
      }}
    >
      <S_PageWrapper
        className={`page-wrapper ${modal.is_open ? 'open_modal' : ''}`}
        onDragOver={(event) => {
          event.preventDefault()
          event.stopPropagation()
          const { target } = event
          const { drag } = store.getState()
          const targetClass = target.className
          // Using store helper to prevent App component realoading
          // Also checking if new state is different since onDragOver callback triggers every 350ms
          if (typeof targetClass === 'string' && drag && drag.dragOverTarget !== targetClass)
            setDragOverTarget(targetClass)
        }}
      >
        {children}
      </S_PageWrapper>
    </ThemeProvider>
  )
}
