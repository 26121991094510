import { S_TopNavDropDown } from './styles'
import { NavLink } from 'react-router-dom'
import React from 'react'

export const TopNavDropdown = ({ subMenu, customColors }) => {
  return (
    <S_TopNavDropDown customColors={customColors}>
      {subMenu.map(({ route, label, exact }, i) => {
        return (
          <li key={`navitem-${i}`}>
            <NavLink activeClassName="_active" className="top-nav-link-sub" exact={exact} to={route}>
              {label}
            </NavLink>
          </li>
        )
      })}
    </S_TopNavDropDown>
  )
}
