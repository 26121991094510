import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { MantineProvider } from '@mantine/core';
import { useGetConfiguration } from 'xen/api/use-configuration';
import { AppLoader, Error } from 'xen/components';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_FAVICON, DEFAULT_PRIMARY_COLOR, DEFAULT_SECONDARY_COLOR, DEFAULT_TENANT_LOGO, } from 'xen/constants';
import { useFeatureFlags } from 'xen/hooks';
const defaultConfiguration = {
    tenant: {
        branding: {
            colors: {
                primary: DEFAULT_PRIMARY_COLOR,
                secondary: DEFAULT_SECONDARY_COLOR,
                background: DEFAULT_BACKGROUND_COLOR,
            },
            images: {
                logo: DEFAULT_TENANT_LOGO,
                favicon: DEFAULT_FAVICON,
            },
            labels: {
                accounts: 'Accounts',
                brokers: 'Brokers',
                businesses: 'Clients',
                configuration: 'Configuration',
                debtors: 'Debtors',
                factoring: 'Factoring',
                fundingRequests: 'Funding Requests',
                glAccounts: 'Accounts',
                invoiceSchedules: 'Invoice Schedules',
                invoiceSettlements: 'Invoice Settlements',
                invoices: 'Invoices',
                linkedExternalAccounts: 'Linked External Accounts',
                notifications: 'Notifications',
                originate: 'Prospects',
                overview: 'Overview',
                unappliedCash: 'Unapplied Cash',
            },
            urls: {
                facebook: '',
                linkedin: '',
                xTwitter: '',
                homepage: '',
            },
            menu: {
                links: [],
            },
        },
        originate: {
            enabled: false,
            brokerEnabled: false,
        },
        factoring: {
            enabled: false,
        },
        businesses: {
            enabled: false,
        },
    },
};
export const ConfigurationContext = createContext(defaultConfiguration);
export const ConfigurationProvider = ({ children }) => {
    const { newConfigurationsAndSettingsUi: settingsUIEnabled } = useFeatureFlags();
    const { data: configuration = defaultConfiguration, isLoading, isError } = useGetConfiguration();
    if (isLoading)
        return (_jsx(MantineProvider, { children: _jsx(AppLoader, {}) }));
    /** We are only rendering error if the settings UI is enabled
     * If the settings UI is not enabled, we will render with the default
     * configuration so that consumers can still retrieve the value
     * even if not utilized for simplicity of component structure
     */
    if (isError && settingsUIEnabled)
        return (_jsx(MantineProvider, { children: _jsx(Error, { message: "Could not fetch configuration" }) }));
    return _jsx(ConfigurationContext.Provider, { value: configuration, children: children });
};
export const useConfigurationContext = () => {
    const context = useContext(ConfigurationContext);
    return context;
};
