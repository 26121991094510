import React, { useState, useEffect, useMemo } from 'react'
import { unstable_batchedUpdates } from 'react-dom'

// Components
import { S_FactoringLenderDashboard } from './styles'
import { StandardPage } from '../../../_components/layout/StandardPage'
import { LenderOverviewTiles } from '../../../_components/LenderOverviewTiles'
import { ReportSelector } from '../../LenderViews/ClientDetails/views/ClientDetailsFactoringOverview/ReportSelector'
import { FeesArChart } from '../../../_components/ChartComponents/FeesArChart/FeesArChart'
import { ArAgingTable } from '../ArAgingTable/ArAgingTable'
import { ConcentrationChart } from '../../../_components/ChartComponents/ConcentrationChart/ConcentrationChart'

// Services
import { getPortfolio } from '../../../_services/lender.service'

// Util
import { logError } from '../../../_helpers/errors'
import { LENDER_ROUTES } from '../../../_constants/routes'
import { isFeatureEnabled } from '../../../_helpers/flags'
import { formatCurrency } from '../../../_helpers'

// Hooks
import { useFlags } from '../../../_reduxHooks/flags.hook'

const initialTotals = {
  net_funds_employed: 0,
  outstanding_funded_invoices: 0,
  total_ineligible_invoices: 0,
  total_outstanding_invoices: 0,
}

export const FactoringLenderDashboard = () => {
  const [totals, setTotals] = useState(initialTotals)
  const { featureFlags } = useFlags()

  // Currently only for Marco Finance
  const useNonStandardNFE = isFeatureEnabled('use_non_standard_nfe', featureFlags)
  const totalsTiles = useMemo(
    () => [
      {
        type: 'multiple',
        value: formatCurrency(useNonStandardNFE ? totals.funds_employed : totals.net_funds_employed),
        href: LENDER_ROUTES.clients_overview,
        linkText: null,
        content: [
          {
            // These checks are currently only used by Marco Finance
            label: useNonStandardNFE ? 'Total Funds Employed' : 'Total Net Funds Employed',
            type: 'currency',
            value: formatCurrency(useNonStandardNFE ? totals.funds_employed : totals.net_funds_employed),
          },
          {
            label: 'Total MTD Earnings',
            type: 'currency',
            value: formatCurrency(totals.mtd_earned_fees),
          },
        ],
      },
      {
        type: 'multiple',
        disableHover: true,
        content: [
          {
            label: 'Funds Due',
            type: 'currency',
            value: formatCurrency(totals.funds_due),
            tooltip: 'Remaining balance of all purchased invoices',
          },
          {
            label: 'Total Collateral',
            type: 'currency',
            value: formatCurrency(totals.total_outstanding_invoices),
            tooltip: 'Balance as of time of last processed transaction',
          },
        ],
      },
      {
        type: 'multiple',
        disableHover: true,
        content: [
          {
            label: 'MTD Chargebacks',
            type: 'currency',
            value: formatCurrency(totals.mtd_chargebacks),
          },
          {
            label: 'Ineligibles',
            type: 'currency',
            value: formatCurrency(totals.total_ineligible_invoices),
          },
        ],
      },
    ],
    [totals]
  )

  const chartTiles = useMemo(
    () => [
      {
        type: 'custom',
        tileClass: 'lg-60 grid-only',
        disableHover: true,
        component: () => <FeesArChart />,
      },
      {
        type: 'custom',
        tileClass: 'lg-40 grid-only',
        disableHover: true,
        component: () => <ConcentrationChart />,
      },
    ],
    []
  )

  useEffect(() => {
    getPortfolio().then((response) => {
      unstable_batchedUpdates(() => {
        setTotals(response.totals)
      })
    }, logError)
  }, [])

  return (
    <S_FactoringLenderDashboard>
      <StandardPage title="Overview">
        {/* Page Header */}
        <header className="page-header">
          <h1>Overview</h1>
          <ReportSelector />
        </header>

        <div className="dashboard-section">
          <LenderOverviewTiles largeTiles={true} overviewTiles={totalsTiles} />
        </div>

        <div className="dashboard-section">
          <LenderOverviewTiles largeTiles={true} overviewTiles={chartTiles} />
        </div>

        <ArAgingTable />
      </StandardPage>
    </S_FactoringLenderDashboard>
  )
}
