import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { FaFileContract } from 'react-icons/fa'
import { useTenant } from '../../_reduxHooks/tenant.hook'

// Components
import { EsigningDocumentsList } from '../modals/esigning/EsigningDocumentsList'

// Constants
import colors from '../../_constants/colors'

// Actions
import { modalActions } from '../../_actions'

import { getContrastColor } from '../../utils/theme'

const EsigningNotification = styled.div`
  color: ${(props) => props.customColors.color};
  position: relative;
  cursor: pointer;

  &:hover {
    .contract-icon {
      opacity: 0.5;
    }
  }

  .contract-icon {
    font-size: 1.3rem;
    transition: all 0.2s ease-in-out;
  }

  .pill {
    color: ${(props) => props.customColors.pillColor};
    border-radius: 100px;
    position: absolute;
    top: -0.75em;
    right: -0.5em;
    width: 1.75em;
    height: 1.75em;
    font-size: 0.5rem;
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.customColors.pillBackground};
    border: 1px solid white;
    font-weight: 900;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  }
`

const EsigningPill = ({ esigning, openModal }) => {
  const { tenantObj } = useTenant()

  let customColors
  const { customization } = tenantObj
  customColors = {
    color: getContrastColor(customization?.color_background, customization?.color_primary) || colors.DEFAULT_PRIMARY,
    pillColor:
      getContrastColor(customization?.color_background, customization?.color_primary) || colors.DEFAULT_SECONDARY,
    pillBackground: customization?.color_background || colors.DEFAULT_PRIMARY,
  }
  return (
    <EsigningNotification
      className={`esigning-notifictions`}
      customColors={customColors}
      onClick={() => openModal(EsigningDocumentsList)}
    >
      <FaFileContract className={`icon contract-icon`} />
      <span className={`pill`}>{esigning.requestsCount}</span>
    </EsigningNotification>
  )
}

const mapStateToProps = (state) => {
  const { env, esigning } = state
  return {
    tenant: env.tenant,
    esigning,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (content) => {
      dispatch(modalActions.openModal(content))
    },
  }
}

const connectedEsigningPill = connect(mapStateToProps, mapDispatchToProps)(EsigningPill)
export { connectedEsigningPill as EsigningPill }
