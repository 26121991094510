import styled from 'styled-components'
import colors from '../../_constants/colors'

// Styles constants

import { cssBreakpoints, cssConstants } from '../../_constants/styles'

const navStyles = {
  navItemOffset: '12px',
  navItemOffsetMobile: '6px',
}

export const S_TopNavBar = styled.nav.attrs((props) => ({
  activeLinkBorder: props.customColors.activeHeaderLink || props.customColors.primary,
}))`
  height: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    height: 100%;
  }

  li {
    font-weight: 700;
    color: #fff;
    letter-spacing: 0.3px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }

  .top-nav-link {
    padding: 0 ${navStyles.navItemOffset};
    color: ${(props) => props.theme.colors.footer_header};
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      padding: 0 ${navStyles.navItemOffsetMobile};
      white-space: nowrap;
    }

    &:before {
      content: '';
      position: absolute;
      height: 6px;
      width: 100%;
      background-color: ${(props) => props.activeLinkBorder};
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover {
      &:before {
        opacity: 0.8;
      }
    }

    &._active {
      &:before {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: ${cssBreakpoints.desktopSm}) {
    overflow-x: unset;
  }
`

export const S_TopNavBarSub = styled.nav.attrs((props) => ({
  linkHoverColor: props.customColors.secondary || colors.DEFAULT_SECONDARY,
}))`
  min-height: ${cssConstants.headerSubNavHeight};

  @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
    min-height: ${cssConstants.headerSubNavHeightMobile};
    overflow: scroll;
  }

  &:not(._empty) {
    background-color: #eff3f1;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  }

  &._empty {
    display: none;
  }

  .top-nav-sub-nav {
    max-width: ${cssConstants.contentWidth};
    width: 100%;
    margin: 0 auto;
    display: flex;
    padding: 0;
    list-style-type: none;

    &__item {
      position: relative;
      margin-right: 3px;

      &:hover {
        ul {
          opacity: 1;
          visibility: visible;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .top-nav-link-sub {
    color: ${colors.LIGHT_GREY};
    font-weight: 400;
    padding: 17px ${navStyles.navItemOffset} 27px;
    display: block;
    transition: color 0.2s ease-in-out;

    @media screen and (max-width: ${cssBreakpoints.mobileLg}) {
      padding: 0 ${navStyles.navItemOffsetMobile};
      padding: 12px ${navStyles.navItemOffsetMobile} 20px;
      white-space: nowrap;
    }

    &._active,
    &:hover {
      color: ${(props) => props.linkHoverColor};
    }
  }
`

export const S_TopNavDropDown = styled.ul`
  box-shadow:
    0px 3px 4px 0px rgba(0, 0, 0, 0.1),
    inset 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  min-width: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: absolute;
  background-color: #e7ebed;
  top: calc(100%);
  z-index: 10;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.2s ease-in-out,
    visible 0.2s ease-in-out;
  padding: 5px 0;

  .top-nav-link-sub {
    padding: 9px 25px;
    display: block;
  }
`
