import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transformNavigation } from './transform';
async function getNavigation() {
    try {
        const response = await request({
            url: `/navigation`,
            method: 'GET',
        });
        return transformNavigation(response);
    }
    catch (error) {
        throw Error('Error getting navigation');
    }
}
export const useGetNavigation = () => {
    return useQuery({
        queryKey: ['navigation'],
        queryFn: () => getNavigation(),
    });
};
