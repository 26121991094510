import { jsx as _jsx } from "react/jsx-runtime";
import { useController } from 'react-hook-form';
import { z } from 'zod';
import { MIME_TYPES, FileInput as XenFileInput, XenIcon, } from 'xen-ui';
import { ErrorText } from '../../error-text';
export function FormFileInput({ name, control, defaultValue, error, iconLeft = 'File', iconRight, rules, shouldUnregister, onChange, ...props }) {
    const { field: { value, onChange: fieldOnChange, ...field }, fieldState, } = useController({
        name,
        control,
        defaultValue,
        rules,
        shouldUnregister,
    });
    const fieldError = error ?? fieldState.error?.message;
    const LeftIcon = iconLeft ? XenIcon[iconLeft] : undefined;
    const RightIcon = iconRight ? XenIcon[iconRight] : undefined;
    return (_jsx(XenFileInput, { error: fieldError ? _jsx(ErrorText, { component: "span", message: fieldError }) : undefined, inputWrapperOrder: ['label', 'description', 'error', 'input'], onChange: (e) => {
            fieldOnChange(e);
            onChange?.(e);
        }, styles: { root: { display: 'flex', flexDirection: 'column', gap: '.25rem' }, ...props.styles }, value: value, ...(LeftIcon && { leftSection: _jsx(LeftIcon, { fontSize: "inherit" }) }), ...(RightIcon && { rightSection: _jsx(RightIcon, { fontSize: "inherit" }) }), ...field, ...props }));
}
const fileInputSchema = ({ acceptedTypes = [] } = {}) => {
    return z
        .custom((val) => val instanceof File, 'File is required')
        .refine((file) => {
        return acceptedTypes.length === 0 || acceptedTypes.some((typeKey) => MIME_TYPES[typeKey] === file.type);
    }, {
        message: `File must be of type: ${acceptedTypes.join(', ')}`,
    });
};
FormFileInput.schema = fileInputSchema;
