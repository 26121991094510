import React from 'react'
import { S_TopNavBarSub } from './styles'
import { TopNavBarSubItem } from './TopNavBarSubItem'
import { useSelector } from 'react-redux'

// Helpers
import { isLender } from '../../_helpers/user-utils'
import { tenantColors } from '../../_constants/colors'

export const TopNavBarSub = ({ subNav }) => {
  const { env, authentication, user } = useSelector((state) => state)
  const loggedIn = authentication.loggedIn
  const isUserLender = isLender(loggedIn, user.user_type)
  const tenant = env.tenant
  const customColors = tenantColors[tenant]

  return (
    <S_TopNavBarSub
      className={subNav && subNav.length ? '' : '_empty'}
      customColors={customColors}
      isUserLender={isUserLender}
    >
      <ul className="top-nav-sub-nav">
        {subNav &&
          subNav.map(({ route, label, exact, subMenu, show, singleRoute }, i) => {
            if (!route && !label) {
              return null
            }

            return (
              <TopNavBarSubItem
                exact={exact}
                key={i}
                label={label}
                route={route}
                show={show}
                singleRoute={singleRoute}
                subMenu={subMenu}
              />
            )
          })}
      </ul>
    </S_TopNavBarSub>
  )
}
