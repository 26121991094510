import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useCreateApplicationAccess } from 'xen/_modules/linked-external-accounts/api/use-create-application-access';
export const PlaidModal = ({ linkToken }) => {
    const { isError: isLinkTokenError, mutate: createApplicationAccess } = useCreateApplicationAccess();
    const onSuccess = (publicToken, metadata) => {
        const { institution } = metadata;
        createApplicationAccess({
            tenantId: 1,
            publicToken,
            institutionName: institution?.name || 'None',
            institutionId: institution?.institution_id || 'None',
        });
    };
    if (isLinkTokenError) {
        return _jsx("div", { children: "Error connecting account. Please try again." });
    }
    const { open, ready,
    // error,
    // exit
     } = usePlaidLink({
        token: linkToken,
        onSuccess,
    });
    useEffect(() => {
        if (ready) {
            open();
        }
    }, [ready, open, linkToken]);
    return null;
};
