import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Card, Divider, Flex, Group, Heading, Loader, Skeleton, Stack, Text } from 'xen-ui';
import { formatCurrency } from 'xen/helpers';
import { DeletePaymentButton, PaymentAssignments, PaymentFileManager, PaymentForm, PaymentStateBadge } from '..';
import { useGetInvoiceSettlement, useIsPaymentAssignmentCreating, useIsPaymentAssignmentDeleting, useIsPaymentAssignmentUpdating, useIsPaymentUpdating, } from '../../api';
export const PaymentCard = ({ payment }) => {
    const { data: settlement } = useGetInvoiceSettlement(String(payment.paymentBatchId));
    const isPartiallyProcessed = settlement?.state === 'partially_processed';
    const isCompleted = settlement?.state === 'completed';
    const isPaymentUpdating = useIsPaymentAssignmentUpdating(payment.id);
    const isAssignmentCreating = useIsPaymentAssignmentCreating();
    const isAssignmentsUpdating = useIsPaymentUpdating(payment.id);
    const isAssignmentDeleting = useIsPaymentAssignmentDeleting(payment.id);
    const isPaymentPending = isPaymentUpdating || isAssignmentCreating || isAssignmentsUpdating || isAssignmentDeleting;
    return (_jsxs(Card, { p: "0", radius: "md", shadow: "sm", withBorder: true, children: [_jsxs(Group, { bg: "gray.0", justify: "space-between", px: "md", py: "xs", children: [_jsxs(Group, { children: [_jsxs(Text, { component: "span", fw: "bold", size: "lg", children: ["Payment ", payment.checkNumber] }), _jsx(PaymentStateBadge, { payment: payment }), isPaymentPending && _jsx(Loader, { size: "sm" })] }), _jsx(DeletePaymentButton, { color: "red", disabled: isPartiallyProcessed || isCompleted, paymentId: payment.id, size: "xs", variant: "outline", children: "Delete Payment" })] }), _jsx(Divider, {}), _jsxs(Flex, { direction: { base: 'column', sm: 'row' }, children: [_jsxs(Stack, { p: "md", w: { base: '100%', sm: '20%' }, children: [_jsx(Heading, { level: 3, size: "h5", children: "Details" }), _jsx(PaymentForm, { payment: payment }), _jsx(PaymentFileManager, { payment: payment })] }), _jsx(Divider, { hiddenFrom: "sm", orientation: "horizontal" }), _jsx(Divider, { orientation: "vertical", visibleFrom: "sm" }), _jsxs(Stack, { p: "md", w: "100%", children: [_jsxs(Group, { gap: "xs", justify: "space-between", children: [_jsxs(Heading, { level: 3, size: "h5", children: ["Assigned Invoices", ' '] }), _jsxs(Text, { size: "sm", children: ["Amount Posted: ", _jsx("b", { children: formatCurrency(payment.amountPosted) })] })] }), _jsx(PaymentAssignments, { payment: payment })] })] })] }));
};
const PaymentCardSkeleton = () => {
    return _jsx(Skeleton, { height: "30rem" });
};
PaymentCard.Skeleton = PaymentCardSkeleton;
