import React, { useState } from 'react'
import { PieChart } from '../../../components/Chart/PieChart/PieChart'

// Styles
import { S_ConcentrationChart } from './ConcentrationChart.styles'
import { S_TileTitle } from '../../TableTile/TileTitle.styles'

// Components
import { ChartDataPanel } from '../../../components/Chart/ChartDataPanel/ChartDataPanel'
import { Button } from '../../Button'

// Hooks
import { useConcentrationChart } from '../../../_entityHooks/concentrationChart.hook'
import { useAblConcentrationChart } from '../../../_entityHooks/chartHooks/AblConcentrationChartHook'

export const ConcentrationChart = ({ service = 'factoring' }) => {
  const [pieActiveIndex, setPieActiveIndex] = useState(-1)
  const [animationDisabled, setAnimationDisabled] = useState(false)

  const hook = service == 'factoring' ? useConcentrationChart : useAblConcentrationChart

  const { currentSegmentData, setCurrentSegment, currentSegment, segmentsOptions, loading } = hook()

  const dataPanelItems = [
    {
      key: 'name',
      label: 'Name',
      size: 'md',
    },
    {
      key: 'balance',
      label: 'Total Amount',
      type: 'currency',
    },
    {
      key: 'concentration',
      label: '% of Total',
      type: 'percent',
    },
  ]

  const getCurretDataPanelSource = () => {
    if (pieActiveIndex === -1 || pieActiveIndex == null) {
      return currentSegmentData[0]
    }
    return currentSegmentData[pieActiveIndex]
  }

  return (
    <S_ConcentrationChart>
      <div className="chart-wrapper">
        <S_TileTitle>Concentration</S_TileTitle>
        <div className={'chart-segments-wrapper'}>
          <div className="segments-controls">
            {segmentsOptions.map((segment) => {
              const isCurrent = segment.value === currentSegment
              return (
                <Button
                  className={`${isCurrent ? '' : 'secondary-accent'}`}
                  key={segment.value}
                  onClick={() => {
                    if (!isCurrent) {
                      setAnimationDisabled(true)
                      setCurrentSegment(segment.value)
                    }
                  }}
                  text={segment.name}
                  type="button"
                />
              )
            })}
          </div>
          <div className="segments-chart-wrapper">
            <PieChart
              animationDisabled={animationDisabled}
              data={currentSegmentData
                .filter((client) => +client.balance > 0)
                .map((client) => ({ ...client, balance: Number(client.balance) }))}
              dataKey={'balance'}
              height={280}
              loading={loading}
              nameKey={'name'}
              onMouseOver={() => {
                if (animationDisabled) {
                  setAnimationDisabled(false)
                }
              }}
              pieActiveIndexCb={(index) => setPieActiveIndex(index)}
              showToolTip={false}
              skeletonAlign={'right'}
              toolTipFormatter={() => ''}
            />
          </div>
        </div>
      </div>

      <ChartDataPanel dataPanelItems={dataPanelItems} dataSource={getCurretDataPanelSource()} />
    </S_ConcentrationChart>
  )
}
