import React from 'react'

// Components
import Table from '../../../_components/Table'
import { SearchableOverview } from '../../../_components/SearchableOverview'

// Helpers
import { createColumn } from '../../../_components/TableColumn'

// Services
import { getArAging } from '../../../_services/lender.service'

export const ArAgingTable = () => {
  return (
    <SearchableOverview
      showSearchBar={false}
      views={[
        {
          title: 'Portfolio A/R Aging',
          endpointCall: async (searchParams) => {
            const alreadySorted = searchParams.has('sort_by')

            if (!alreadySorted) {
              searchParams.set('sort_by', 'total')
              searchParams.set('sort_asc', false)
            }

            const response = await getArAging(searchParams)

            return {
              results: [...response.ar_data, { ...response.totals, business_name: 'Total' }],
              total_results: response.count,
            }
          },
          columns: [
            {
              accessor: 'business_name',
              headingText: 'Client',
              disableSortBy: false,
            },
            createColumn({
              id: '1-30',
              headingText: '1-30',
              type: 'currency',
              disableSortBy: false,
            }),
            createColumn({
              id: '31-60',
              headingText: '31-60',
              type: 'currency',
              disableSortBy: false,
            }),
            createColumn({
              id: '61-90',
              headingText: '61-90',
              type: 'currency',
              disableSortBy: false,
            }),
            createColumn({
              id: '91-120',
              headingText: '91-120',
              type: 'currency',
              disableSortBy: false,
            }),
            createColumn({
              id: '121+',
              headingText: '121+',
              type: 'currency',
              disableSortBy: false,
            }),
            createColumn({
              id: 'total',
              headingText: 'Total',
              type: 'currency',
              disableSortBy: false,
            }),
          ],
          allowDownload: true,
          requestMethod: 'GET',
          Component: ({ results, columns, handleSortChange }) => (
            <Table
              columns={columns}
              data={results}
              getTrProps={(arg1, row) => {
                if (row.index >= results.length - 1) {
                  return {
                    addtional_styles: `.rt-custom-cell { font-weight: bold !important; } .td {border-bottom-style: none !important;}`,
                  }
                } else if (row.index == results.length - 2) {
                  return {
                    addtional_styles: `.td {border-bottom-color: black !important;}`,
                  }
                } else {
                  return {}
                }
              }}
              keyProp={'business_name'}
              onSortedChange={handleSortChange}
            />
          ),
        },
      ]}
    />
  )
}
