import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FaSpinner, FaPhone } from 'react-icons/fa'
import { Button } from '../../_components/Button'
import Clock from 'react-live-clock'

// Actions
import { authenticationActions, menuOpenActions, modalActions } from '../../_actions'

// Utils
import { isFeatureEnabled } from '../../_helpers/flags'

// Components
import { SocialIcons } from '../SocialIcons'
import { InfoModal } from '../modals/info/InfoModal'
import { ChangePassword } from '../modals/changePassword'
import { MenuHelpLinks } from './MenuHelpLinks'

import { MenuPanel, Links } from './Menu.styles'

// Helpers
import { isLender } from '../../_helpers/user-utils'

// CONSTANTS
import { tenantColors } from '../../_constants/colors'
import ROUTES from '../../_constants/routes'
import { menuConfig, headerConfig } from '../../_configs'

// Services
import { getSalesPeople } from '../../_services/lender.service'

const Menu = ({
  featureFlags,
  tenant,
  loggedIn,
  sessionToken,
  loading,
  menuOpen,
  openModal,
  signOut,
  toggleMenu,
  user,
  timezone,
}) => {
  const customColors = tenantColors[tenant]
  const isUserLender = isLender(loggedIn, user.user_type)
  const config = menuConfig[tenant]
  const headerTenantConfig = headerConfig[tenant]
  if (tenant === 'finvoice' && isUserLender) {
    config.unshift({
      label: 'Config',
      link: '/fv_admin_panel',
    })
  }
  return (
    <MenuPanel
      customColors={customColors}
      isUserLender={isUserLender}
      style={{ transform: menuOpen ? 'translateY(0)' : 'translateY(-200%)' }}
    >
      <Links>
        {config.map((item, i) => (
          <a className={`menu-link`} href={item.link} key={i}>
            {item.type === `phone` && <FaPhone className={`icon`} />}
            {item.label}
          </a>
        ))}
        {loggedIn && (
          <div
            className={`menu-link`}
            onClick={() => openModal(ChangePassword, { user_id: user.id, email: user.email_address })}
          >
            Change Password
          </div>
        )}
        {user && user.user_type === 'lender' && isFeatureEnabled('saleslink', featureFlags) && (
          <div>
            <Button
              className="compact signup-link-button"
              onClick={() => {
                getSalesPeople().then((salesPeople) => {
                  const filteredSalesPeople = salesPeople.filter((person) => person.user.id === user.id)
                  let content = 'It seems like you are not assigned as a salesperson.'

                  if (filteredSalesPeople.length) {
                    const host = window.location.host
                    const protocol = host.includes('localhost') ? 'http' : 'https'
                    content = `${protocol}://${host}/signup?sp=${filteredSalesPeople[0].id}`
                  }

                  openModal(InfoModal, {
                    title: 'My Signup Link',
                    content,
                  })
                })
              }}
              text="My Signup Link"
            />
            {loading && <FaSpinner className={`loading-spinner`} />}
          </div>
        )}

        {loggedIn ? (
          <div>
            <Button className="compact sign-out-button" onClick={() => signOut(sessionToken)} text="Sign Out" />
            {loading && <FaSpinner className={`loading-spinner`} />}
          </div>
        ) : (
          [
            <Link className="menu-link" key={0} onClick={() => toggleMenu()} to={ROUTES.sign_up}>
              Sign Up
            </Link>,
            <Link className="menu-link" key={1} onClick={() => toggleMenu()} to={ROUTES.sign_in}>
              Sign In
            </Link>,
          ]
        )}
      </Links>

      {headerTenantConfig.length > 0 && <MenuHelpLinks links={headerTenantConfig} />}

      <SocialIcons />

      {user && user.user_type && user.user_type !== 'pursuit' && timezone && (
        <div className="system-time-container">
          <Clock format={'LLL'} ticking={true} timezone={timezone.zoneName} />
        </div>
      )}
    </MenuPanel>
  )
}

const mapStateToProps = (state) => {
  const { featureFlags, authentication, menuOpen, env, user, timezone } = state
  return {
    featureFlags,
    loggedIn: authentication.loggedIn,
    sessionToken: authentication.sessionToken,
    loading: authentication.loading,
    menuOpen: menuOpen,
    tenant: env.tenant,
    user,
    timezone,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (sessionToken) => {
      dispatch(authenticationActions.signOut(sessionToken))
    },
    toggleMenu: () => {
      dispatch(menuOpenActions.toggleMenu())
    },
    openModal: (content, props) => {
      dispatch(modalActions.openModal(content, props))
    },
  }
}

const connectedMenu = connect(mapStateToProps, mapDispatchToProps)(Menu)
export { connectedMenu as Menu }
