import request from '../axios_service'
export const getAblPortfolioOverview = () => {
  return request({
    url: `/abl/overview`,
    method: 'GET',
  })
}

export const getAblPortfolioCollateralFeesOverview = (months) => {
  return request({
    url: `/abl/collateral_fees_overview?months=${months}`,
    method: 'GET',
  })
}

export const getAblPortfolioConcentration = (months) => {
  return request({
    url: `/abl/concentration`,
    method: 'GET',
  })
}

export const getAblBBCOverview = () => {
  return request({
    url: `/abl/bbc_overview`,
    method: 'GET',
  })
}
