import styled from 'styled-components'
import { colors } from '../../../_constants/colors'
import { tileBorder } from '../../LenderOverviewTile.style'

export const S_FeesArChart = styled.div`
  position: relative;
  width: 100%;
  padding: 15px;
  border: ${tileBorder};
  border-radius: 3px;

  .recharts-default-legend {
    display: flex;
    justify-content: space-between;
  }

  .no-data {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    z-index: 10;
    background-color: #cccccc66;
    vertical-align: middle;
    font-size: 16px;
    color: #999;
    .no-data-text {
      margin-top: 90px;
    }
  }

  .prev-current {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 225px;
    top: 10px;
    right: 0;
    z-index: 100;

    div {
      font-size: 12px;
      color: ${colors.TEXT_LIGHT};
      transition: color 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        color: ${colors.TEXT_DARK};
      }

      &.active {
        color: ${colors.DEFAULT_SECONDARY};
      }
    }
  }
`
