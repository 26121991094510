import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { transactionsQueryKey } from 'xen/_modules/linked-external-accounts/api/query-keys';
import { transformTransactionsResponse } from 'xen/_modules/linked-external-accounts/api/transform';
import { PLAID_API_BASE_URL } from 'xen/_modules/linked-external-accounts/constants';
async function getTransactions(accountId) {
    try {
        const response = await request({
            url: `${PLAID_API_BASE_URL}/accounts/${accountId}/transactions`,
            method: 'GET',
        });
        return transformTransactionsResponse(response);
    }
    catch (_error) {
        throw Error('Error getting accounts');
    }
}
export const useGetTransactions = (accountId) => {
    return useQuery({
        queryKey: transactionsQueryKey(accountId),
        enabled: !!accountId,
        queryFn: () => getTransactions(accountId),
    });
};
