import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Grid } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { Group, Heading, Stack, Text, TextInput } from 'xen-ui';
import { Page, PageTitle } from 'xen/components';
import { FormCurrencyInput } from 'xen/components/form';
import { formatCurrency, formatDecimal } from 'xen/helpers';
import { useFeatureFlags } from 'xen/hooks';
import { useGetInvoiceSettlement, useGetPayments } from '../api';
import { CreatePaymentButton, DeleteInvoiceSettlementButton, PaymentCard, SettlementProcessForm } from '../components';
const InvoiceSettlementEdit = ({ id }) => {
    const { invoiceSettlementExpectedTotal: isExpectedTotalInvoiceEnabled } = useFeatureFlags();
    const { data: invoiceSettlement, isLoading: isInvoiceSettlementLoading, isError: isInvoiceSettlementError, } = useGetInvoiceSettlement(id);
    const { data: payments = [], isLoading: isPaymentsLoading, isError: isPaymentsError, } = useGetPayments({ paymentBatchId: id });
    const [expectedTotal, setExpectedTotal] = useState('');
    const [expectedError, setExpectedError] = useState('');
    const isProcessed = ['partially_processed', 'completed'].includes(invoiceSettlement?.state ?? '');
    const showExpectedTotalField = isExpectedTotalInvoiceEnabled && !isProcessed;
    const handleExpectedTotalErrors = (value, totalValue) => {
        const val = value;
        if (val === '') {
            setExpectedError('Expected amount is required');
            setExpectedTotal(val);
            return;
        }
        const numVal = FormCurrencyInput.formatValue(val);
        if (numVal !== totalValue) {
            setExpectedError('Expected does not match total amount');
        }
        else {
            setExpectedError('');
        }
        setExpectedTotal(val);
    };
    useEffect(() => {
        if (!invoiceSettlement)
            return;
        if (!isExpectedTotalInvoiceEnabled)
            return;
        if (isProcessed)
            return;
        handleExpectedTotalErrors(expectedTotal, invoiceSettlement.totalValue);
    }, [invoiceSettlement, isExpectedTotalInvoiceEnabled, isProcessed, invoiceSettlement?.totalValue]);
    if (isInvoiceSettlementError)
        return _jsx(_Fragment, { children: "Error fetching invoice settlement." });
    if (isPaymentsError)
        return _jsx(_Fragment, { children: "Error fetching payments" });
    if (isInvoiceSettlementLoading)
        return _jsx(_Fragment, { children: "Loading invoice settlement..." });
    if (!invoiceSettlement)
        return _jsxs(_Fragment, { children: ["Invoice ", id, " not found"] });
    if (isPaymentsLoading)
        return _jsx(_Fragment, { children: "Loading payments..." });
    const { amountPosted, totalValue } = invoiceSettlement;
    const isPartiallyProcessed = invoiceSettlement.state === 'partially_processed';
    const isCompleted = invoiceSettlement.state === 'completed';
    const achPaymentsCount = payments.filter((payment) => payment.source === 'ach').length;
    const checkPaymentsCount = payments.filter((payment) => payment.source === 'check').length;
    const wirePaymentsCount = payments.filter((payment) => payment.source === 'wire').length;
    return (_jsxs(Stack, { gap: "xl", children: [_jsxs(Group, { align: "flex-start", justify: "space-between", children: [_jsx(PageTitle, { title: `Invoice Settlement ${invoiceSettlement.batchNumber}` }), _jsx(DeleteInvoiceSettlementButton, { color: "red", invoiceSettlement: invoiceSettlement, variant: "outline" })] }), _jsxs(Group, { align: "flex-start", justify: "space-between", children: [_jsxs(Stack, { flex: 1, gap: "xs", children: [_jsx(Heading, { level: 2, children: "Overview" }), _jsxs(Grid, { gutter: "xs", children: [showExpectedTotalField && (_jsx(_Fragment, { children: _jsx(Grid.Col, { span: { base: 12, sm: 12 }, children: _jsx(TextInput, { description: "Total amount you expect to receive for this invoice settlement.", error: expectedError, id: "expected-total", label: "Expected Amount", leftSection: "$", leftSectionPointerEvents: "none", onBlur: (e) => {
                                                    // Handle errors
                                                    const val = e.target.value;
                                                    handleExpectedTotalErrors(val, totalValue);
                                                    // Format to currency on blur
                                                    const newVal = formatDecimal(FormCurrencyInput.formatValue(val));
                                                    setExpectedTotal(newVal);
                                                }, onChange: (e) => {
                                                    // Handle errors
                                                    const val = e.target.value;
                                                    handleExpectedTotalErrors(val, totalValue);
                                                    setExpectedTotal(val);
                                                }, required: true, value: expectedTotal, w: "15rem" }) }) })), _jsx(Grid.Col, { span: { base: 12, sm: 2 }, children: _jsx("b", { children: "Total amount:" }) }), _jsx(Grid.Col, { span: { base: 12, sm: 10 }, children: formatCurrency(totalValue) }), _jsx(Grid.Col, { span: { base: 12, sm: 2 }, children: _jsx("b", { children: "Posted amount:" }) }), _jsx(Grid.Col, { span: { base: 12, sm: 10 }, children: formatCurrency(amountPosted) }), _jsx(Grid.Col, { span: { base: 12, sm: 2 }, children: _jsx("b", { children: "Current state:" }) }), _jsx(Grid.Col, { span: { base: 12, sm: 10 }, children: _jsx(Text, { span: true, style: { textTransform: 'capitalize' }, children: invoiceSettlement.state.replace(/_/g, ' ') }) })] })] }), isExpectedTotalInvoiceEnabled ? (_jsx(SettlementProcessForm, { expectedError: expectedError, settlement: invoiceSettlement })) : (_jsx(SettlementProcessForm, { settlement: invoiceSettlement }))] }), _jsxs(Stack, { children: [_jsxs(Group, { align: "flex-start", justify: "space-between", children: [_jsxs(Group, { align: "baseline", gap: "xs", children: [_jsx(Heading, { level: 2, children: "Payments" }), _jsxs(Text, { children: ["ACH ", achPaymentsCount, " | Wire ", wirePaymentsCount, " | Check ", checkPaymentsCount] })] }), _jsx(CreatePaymentButton, { disabled: isPartiallyProcessed || isCompleted, invoiceSettlementId: id, children: "Add Payment" })] }), payments.length === 0 && _jsx(Text, { children: "No payments found for this invoice settlement." }), payments.map((payment) => (_jsx(PaymentCard, { payment: payment }, payment.id)))] })] }));
};
const InvoiceSettlementEditPage = () => {
    const { settlementId: id } = useParams();
    // TODO: Add ErrorBoundary
    return (_jsx(Page, { "data-testid": "invoice-settlement-edit", children: _jsx(InvoiceSettlementEdit, { id: id }) }));
};
export default InvoiceSettlementEditPage;
