import { jsx as _jsx } from "react/jsx-runtime";
import { createXenTheme, XenThemeProvider } from 'xen-ui';
import { combinedTenantXCustomization, useGetTenant, useGetTenantCustomization } from 'xen/api/use-tenant';
import { useGetCurrentUser } from 'xen/api/use-user';
import { AppLoader, Error } from 'xen/components';
import { TenantProvider, UserProvider } from 'xen/providers';
const GlobalDataProvider = ({ children }) => {
    const { data: userData, isLoading: isUserLoading, isError: isUserError } = useGetCurrentUser();
    const { data: tenantData, isLoading: isTenantLoading, isError: isTenantError } = useGetTenant();
    const { data: tenantCustomizationData, isLoading: isCustomizationLoading, isError: isCustomizationError, } = useGetTenantCustomization();
    if (isUserLoading || isTenantLoading || isCustomizationLoading) {
        /**
         * Thought about a loader here but we don't have tenant colors yet so it would
         * be off whitelabel unless we make loaders client neutral.
         */
        const defaultTheme = createXenTheme({});
        return (_jsx(XenThemeProvider, { theme: defaultTheme, children: _jsx(AppLoader, {}) }));
    }
    if (!tenantData || isTenantError || !tenantCustomizationData || isCustomizationError || isUserError) {
        /**
         * This should be handled by our error boundary in the future.
         * https://finvoice-inc.atlassian.net/browse/FP-6624
         * Additionally we don't have the theme object yet so for mantine components we need to provide a default theme.
         */
        const defaultTheme = createXenTheme({});
        return (_jsx(XenThemeProvider, { theme: defaultTheme, children: _jsx(Error, { message: "Error Initializing Application" }) }));
    }
    const TenantXCustomization = combinedTenantXCustomization({
        tenant: tenantData,
        tenantCustomization: tenantCustomizationData,
    });
    if (!userData) {
        return _jsx(TenantProvider, { tenant: TenantXCustomization, children: children });
    }
    return (_jsx(TenantProvider, { tenant: TenantXCustomization, children: _jsx(UserProvider, { user: userData, children: children }) }));
};
export { GlobalDataProvider };
