import { useMutation } from '@tanstack/react-query';
import { request } from 'xen/api';
import { notifications } from 'xen/helpers';
import { queryClient } from 'xen/providers/query-provider';
import { queryKeyFactory as paymentQueryKeyFactory } from 'xen/_modules/invoice-settlement/api/use-payment/query-keys-factory';
async function deleteInvoiceSettlement(id) {
    try {
        await request({
            method: 'DELETE',
            url: `/operate/payment_batches/${id}`,
        });
        return id;
    }
    catch (_error) {
        throw Error('Could not delete invoice settlement');
    }
}
export const useDeleteInvoiceSettlement = () => {
    return useMutation({
        mutationFn: ({ id }) => deleteInvoiceSettlement(id),
        onError: (error) => {
            notifications.error(error.message);
        },
        onSuccess: (responseData) => {
            const invoiceSettlementId = String(responseData);
            queryClient.invalidateQueries({
                queryKey: paymentQueryKeyFactory.payments(new URLSearchParams({ invoiceSettlementId }).toString()),
            });
        },
    });
};
