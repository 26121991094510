import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import { useDisclosure } from 'xen-ui';
const AppShellNavBarContext = createContext({
    navBarOpened: false,
    toggleNavBarState: () => { },
});
export const useAppShellNavBarContext = () => {
    const context = useContext(AppShellNavBarContext);
    return context;
};
export const AppShellProvider = ({ children }) => {
    const [navBarOpened, { toggle: toggleNavBarState }] = useDisclosure(true);
    return (_jsx(AppShellNavBarContext.Provider, { value: { navBarOpened: navBarOpened, toggleNavBarState: toggleNavBarState }, children: _jsx(_Fragment, { children: children }) }));
};
