import axios from 'axios'
import { useRef } from 'react'
import config from 'config'
import { store } from '../_helpers/store'
import { authenticationConstants } from '../_constants/authentication.constants'
import { notificationsActions } from '../_actions/notifications.actions'

import { contentJsonHeader, tenantHeader, sessionHeader, cancelTokenHelper, history } from '../_helpers'

axios.defaults.baseURL = config.apiUrl
axios.defaults.headers.common = Object.assign(axios.defaults.headers.common, contentJsonHeader())

/**
 *
 * @param {import("axios").AxiosRequestConfig} options
 * @returns {Promise<{}>}
 */
export default function request(
  options,
  headers = null,
  raw = false,
  stringifyError = true,
  skipUnauthRedirect = false
) {
  const convertArrayBufferToJson = (str) => {
    const enc = new TextDecoder('utf-8')
    const arr = new Uint8Array(str)
    return JSON.parse(enc.decode(arr))
  }

  const baseUrl = options.baseURL || axios.defaults.baseURL
  return axios({
    ...options,
    baseUrl: baseUrl,
    ...(options.uncancelable ? {} : { cancelToken: options.cancelToken || cancelTokenHelper().token }),
    headers: headers || Object.assign(tenantHeader(), sessionHeader(), ...(options.headers || [])),
    validateStatus: (status) => {
      if (store && store.getState().authentication.isIdleTimeout) {
        history.push('/signin')
      } else if (
        status == 401 &&
        document.location.pathname != '/signin' &&
        document.location.pathname != '/reset_password' &&
        document.location.pathname != '/signup' &&
        store &&
        store.dispatch &&
        !skipUnauthRedirect
      ) {
        if (document.location.pathname != '/prospect') {
          store.dispatch(
            notificationsActions.showNotification({
              type: `error`,
              message: `Session Expired: Please sign in again.`,
              id: `sign-in-again`,
            })
          )
        }
        store.dispatch({ type: authenticationConstants.SIGN_OUT_SUCCESS })
        history.push('/signin')
      }
      return status >= 200 && status < 300 // default
    },
  })
    .then((resp) => {
      return raw ? resp : resp.data
    })
    .catch((error) => {
      if (stringifyError && error && error.response && error.response.data) {
        if (error.response.data instanceof ArrayBuffer) {
          throw convertArrayBufferToJson(error.response.data).message
        } else {
          if (error.response.data?.errors instanceof Object) {
            // As a convention, let the caller handle the error if the response body has the
            // 'errors' attribute with structured data
            throw error
          }

          throw `${error.response.data.message || error.message}`
        }
      } else if (error.response) {
        throw error.response
      } else {
        throw error
      }
    })
}

export function generateCancelToken() {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export function useCancelRequest() {
  const cancelToken = useRef(generateCancelToken())

  const cancelRequest = () => {
    cancelToken.current.cancel()
    cancelToken.current = generateCancelToken()
  }

  return [cancelToken, cancelRequest]
}
