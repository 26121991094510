import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Stack } from 'xen-ui';
import { useUpdateInvoice } from 'xen/api/use-invoice';
import { ErrorText } from 'xen/components';
import { FormCheckbox, FormCurrencyInput, FormDateInput, FormDebtorSelect, FormNumberInput, FormTextInput, } from 'xen/components/form';
import { useDebouncedCallback } from 'xen/hooks';
const invoiceFormSchema = z.object({
    debtorId: FormDebtorSelect.schema,
    faceValue: FormCurrencyInput.schema({ label: 'Amount', name: 'faceValue', required: true }),
    invoiceDate: z.date().min(new Date(2021, 0, 1), { message: 'Invoice date is required' }),
    invoiceNumber: z.string().min(1, { message: 'Invoice number is required' }),
    fund: z.boolean(),
    paymentTerms: FormNumberInput.schema({
        integerOnly: true,
        minVal: 1,
        maxVal: 99999,
        label: 'Net due',
        name: 'paymentTerms',
        required: true,
    }),
    poNumber: z.string().optional(),
});
export const InvoiceUpdateForm = ({ invoice }) => {
    const { debtorId, faceValue, id: scheduleId, invoiceDate, invoiceNumber, nonFunded, paymentTerms, poNumber, state, } = invoice;
    const defaultValues = {
        debtorId: String(debtorId ?? ''),
        // Leave faceValue field empty if value is 0
        faceValue: String(Number(faceValue) === 0 ? '' : faceValue),
        invoiceDate: invoiceDate || new Date(),
        invoiceNumber: String(invoiceNumber ?? ''),
        // The attribute is nonFunded (negative), but the form field is fund (positive)
        fund: !nonFunded,
        paymentTerms: String(paymentTerms ?? ''),
        poNumber: String(poNumber ?? ''),
    };
    const { control, handleSubmit } = useForm({
        resolver: zodResolver(invoiceFormSchema),
        mode: 'onChange',
        values: defaultValues,
    });
    const { mutate: updateInvoice, isError } = useUpdateInvoice(scheduleId);
    const onSubmit = async (data) => {
        const { debtorId, faceValue, fund, invoiceDate, invoiceNumber, paymentTerms, poNumber } = data;
        const params = {
            payload: {
                // Fields with default values
                invoiceDate,
                nonFunded: !fund,
                paymentTerms,
                // Fields that can be blank
                ...(debtorId && { debtorId: Number(debtorId) }),
                ...(faceValue && { faceValue: FormCurrencyInput.formatValue(faceValue) }),
                ...(invoiceNumber && { invoiceNumber }),
                ...(poNumber && { poNumber }),
            },
        };
        updateInvoice(params);
    };
    const handleFormSubmit = () => {
        handleSubmit(onSubmit)();
    };
    const handleDebouncedFormSubmit = useDebouncedCallback(handleFormSubmit, 1000);
    const isFormDisabled = state !== 'Incomplete';
    return (_jsx(Form, { control: control, onSubmit: handleFormSubmit, children: _jsxs(Stack, { gap: "sm", children: [isError && _jsx(ErrorText, { message: "Error updating invoice" }), _jsx(FormDebtorSelect, { control: control, disabled: isFormDisabled, name: "debtorId", onChange: handleFormSubmit, required: true }), _jsx(FormTextInput, { control: control, disabled: isFormDisabled, label: "Invoice #", name: "invoiceNumber", onInput: handleDebouncedFormSubmit, required: true }), _jsx(FormTextInput, { control: control, disabled: isFormDisabled, label: "Purchase Order", name: "poNumber", onInput: handleDebouncedFormSubmit }), _jsx(FormDateInput, { control: control, disabled: isFormDisabled, label: "Invoice Date", name: "invoiceDate", onChange: handleFormSubmit, required: true }), _jsx(FormNumberInput, { control: control, disabled: isFormDisabled, label: "Net Due (days)", name: "paymentTerms", onInput: handleDebouncedFormSubmit, required: true }), _jsx(FormCurrencyInput, { control: control, disabled: isFormDisabled, label: "Amount", name: "faceValue", onInput: handleDebouncedFormSubmit, required: true }), _jsx(FormCheckbox, { control: control, disabled: isFormDisabled, label: "Fund", name: "fund", onChange: handleFormSubmit, required: true })] }) }));
};
