import React, { useState, useEffect } from 'react'

// Services
import { businessService } from '../../../../_services/business.service'
import { fetchBrokersSuggestions, getBroker } from '../../../../_services/broker.service'
import { getReportByPath } from '../../../../_services/report.service'
import { fetchFactoringBusinessSuggestions, fetchAblBusinessSuggestions } from '../../../../_helpers/business'
import { getABLBusiness } from '../../../../_services/abl/abl-business.service'

// Components
import { FormRow } from '../../../Forms/FormRow'
import { LoaderButton } from '../../../LoaderButton'
import { AlertMessage } from '../../../AlertMessage'

// Hooks
import { useModal } from '../../../../_reduxHooks/modal.hook'

// Helpers
import {
  currentDateTimeInUTC,
  UTCDateToSystemString,
  absoluteCurrentDateinUTC,
  absoluteCurrentDateEndOfTheDayinUTC,
} from '../../../../_helpers/dates'
import { S_ReportCustomizer } from './ReportCustomizer.styles'

const FormRowTypeMap = {
  business: (props) => (
    <FormRow
      autoCompleteProps={{
        valueIdAttr: 'id',
        valueLabelAttr: props.reportSet == 'factoring' ? 'business_name' : 'name',
        fetchRecord: props.reportSet == 'factoring' ? businessService.getBusiness : getABLBusiness,
        fetchSuggestions:
          props.reportSet == 'factoring' ? fetchFactoringBusinessSuggestions : fetchAblBusinessSuggestions,
        defaultSuggestion: props.defaultSuggestion,
      }}
      type="autocomplete"
      {...props}
      label={null}
    />
  ),
  broker: (props) => (
    <FormRow
      autoCompleteProps={{
        valueIdAttr: 'id',
        valueLabelAttr: 'name',
        fetchRecord: getBroker,
        fetchSuggestions: fetchBrokersSuggestions,
        defaultSuggestion: props.defaultSuggestion,
      }}
      type="autocomplete"
      {...props}
      label={null}
    />
  ),
  date: (props) => <FormRow type="date" {...props} label={null} />,
  select: (props) => <FormRow type="select" {...props} label={null} />,
  boolean: (props) => <FormRow type="checkbox" {...props} label={null} />,
}

export const ReportCustomizer = ({ params, path, report, handleDownloadReport, reportSet }) => {
  let [reportParamsToSend, setReportParamsToSend] = useState({})
  const [loadingReport, setLoadingReport] = useState(false)
  const [reportError, setReportError] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [showErrorDetails, setShowErrorDetails] = useState(false)
  const [formErrors, setFormErrors] = useState({})
  const modalFunctions = useModal()
  const [disabledDates, setDisabledDates] = useState(false)

  useEffect(() => {
    let paramsToSet = {}
    params.map((p) => {
      if (p.value !== undefined) {
        paramsToSet = { ...paramsToSet, [p.name]: p.value }
      }
      let defaultValue = p.value
      if (p.type === 'select') {
        let defaultOption = p.options.find((opt) => opt.default)
        if (defaultOption) {
          defaultValue = defaultOption.value
          paramsToSet = { ...paramsToSet, [p.name]: defaultValue }
        }
      }
    })
    setReportParamsToSend(paramsToSet)
  }, [])

  const handleShowErrorClick = () => {
    setShowErrorDetails(!showErrorDetails)
  }

  return (
    <S_ReportCustomizer>
      <p className="subtitle">Report Options:</p>
      {params.map((p, i) => {
        let minValue

        if (p.type === 'date' && p.allow_past_dates === false) {
          // Explicitly check that the value is 'false' to prevent
          // unexpected behavior with undefineds.
          minValue = UTCDateToSystemString(currentDateTimeInUTC()).split('T')[0]
        }

        if (FormRowTypeMap[p.type]) {
          const C = FormRowTypeMap[p.type]
          return (
            <div className={'row'} key={i}>
              <div className={'label'} data-testid={`param-label-${p.name}`}>
                {p.display_name}
              </div>
              <C
                data-testid={`param-field-${p.name}`}
                defaultSuggestion={`Start typing to find ${p.display_name.toLowerCase()}.`}
                defaultValue={p.default || p.value}
                disabled={p.type === 'date' && disabledDates}
                error={formErrors[p.name]}
                id={p.name}
                label={p.display_name}
                min={minValue}
                onBlur={(e) => {
                  let val = e.target.value
                  setFormErrors((prev) => {
                    let err = null

                    if (p.required && !val) {
                      err = `${p.display_name} is required.`
                    }
                    return {
                      ...prev,
                      [p.name]: err,
                    }
                  })
                }}
                onChange={(val, id) => {
                  let finalValue
                  // To make sure we get all reports in range
                  if (id === 'simulated_date') {
                    finalValue = absoluteCurrentDateEndOfTheDayinUTC(val)
                  }

                  if (id === 'end_date') {
                    finalValue = absoluteCurrentDateEndOfTheDayinUTC(val)
                  }

                  if (id === 'start_date') {
                    finalValue = absoluteCurrentDateinUTC(val)
                  }

                  if (id !== 'start_date' && id !== 'end_date' && id !== 'simulated_date') {
                    finalValue = val
                  }

                  if (id === 'new_transactions') {
                    setDisabledDates(val)
                  }

                  finalValue = finalValue.target ? finalValue.target.value : finalValue
                  let paramsCopy = { ...reportParamsToSend, [p.name]: finalValue }

                  if (!finalValue && p.type === 'business') {
                    delete paramsCopy[p.name]
                  }
                  setReportParamsToSend(paramsCopy)
                }}
                options={p.options}
                reportSet={reportSet}
                required={p.required}
              />
            </div>
          )
        } else {
          return null
        }
      })}
      <div className={'btn-row'}>
        <LoaderButton
          isLoading={loadingReport}
          onClick={(e) => {
            let hasValidationError = false
            let newFormErrorState = { ...formErrors }
            params.map((p, i) => {
              let val = reportParamsToSend[p.name]

              let err = null

              if (p.required && !val) {
                err = `${p.display_name} is required.`
                hasValidationError = true
              }
              newFormErrorState[p.name] = err
            })
            setFormErrors(newFormErrorState)
            if (hasValidationError) {
              return
            }
            let searchParams = new URLSearchParams()
            setLoadingReport(true)
            setReportError(false)
            Object.keys(reportParamsToSend).map((key) => {
              if (reportParamsToSend[key] !== undefined) {
                searchParams.set(key, reportParamsToSend[key])
              }
            })
            if (report?.clientSide) {
              setLoadingReport(true)
              // Client side reports should handle closing/changing modal content
              report.getReport(searchParams, modalFunctions)
            } else {
              handleDownloadReport(
                getReportByPath(path, searchParams, report?.mime_type),
                setLoadingReport,
                setReportError,
                setErrorMessage
              )
            }
          }}
        >
          Get Report
        </LoaderButton>
      </div>
      {reportError && (
        <AlertMessage type="error">
          An unexpected error has occurred. For help, please reach out to{' '}
          <a href="mailto: support@xenplatforms.com">support@xenplatforms.com</a>.
          {errorMessage && (
            <>
              <p className="toggle-error-details" onClick={handleShowErrorClick}>
                {showErrorDetails ? 'Hide error details' : 'Show error details'}
              </p>
              {showErrorDetails && <p className="error-details">{errorMessage}</p>}
            </>
          )}
        </AlertMessage>
      )}
    </S_ReportCustomizer>
  )
}
