import { formatCase } from 'xen/helpers';
/*
TODO: We transform this object into a flat response where a feature is either
 enabled by it's pressence or disabled by omition.
The object values are the Navigation label when invcluded
This should be adjusted when we talk about configurations
*/
export const transformNavigation = (response) => {
    const navigation = {};
    const keys = ['primary', 'secondary'];
    keys.forEach((key) => {
        response[key].forEach((item) => {
            navigation[formatCase.camel(item.key)] = item.label;
            if (item.children) {
                item.children.forEach((child) => {
                    navigation[formatCase.camel(child.key)] = child.label;
                });
            }
        });
    });
    return navigation;
};
