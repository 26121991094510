import { useQuery } from '@tanstack/react-query';
import { request } from 'xen/api';
import { accountsQueryKey } from 'xen/_modules/linked-external-accounts/api/query-keys';
import { transformAccountsResponse } from 'xen/_modules/linked-external-accounts/api/transform';
import { PLAID_API_BASE_URL, PLAID_ENTITY_TYPE } from 'xen/_modules/linked-external-accounts/constants';
async function getAccounts() {
    try {
        const response = await request({
            url: `${PLAID_API_BASE_URL}/accounts`,
            params: { entity_type: PLAID_ENTITY_TYPE },
            method: 'GET',
        });
        return transformAccountsResponse(response);
    }
    catch (_error) {
        throw Error('Error getting accounts');
    }
}
export const useGetAccounts = (refetchInterval = 0) => {
    return useQuery({
        queryKey: accountsQueryKey(),
        queryFn: getAccounts,
        refetchInterval,
    });
};
